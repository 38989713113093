<div class="column-sorter">
  <div
    class="column-sorter__label"
    (click)="labelSortBy()">{{text}}</div>
  <div class="column-sorter__arrows">
    <fa-icon
      [icon]="['fas', 'caret-up']"
      [ngClass]="{'marked': direction === true}"
      (click) = "sortBy()"></fa-icon>
    <fa-icon
      [icon]="['fas', 'caret-down']"
      [ngClass]="{'marked': direction === false}"
      (click) = "sortBy(false)"></fa-icon>
  </div>
</div>
