import {ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {isPlatformBrowser} from '@angular/common';

import { ErrorsService } from '../services/errors_service';
import { NotificationService } from '../services/notification_service';

import * as Sentry from '@sentry/angular-ivy';
import {environment} from '../../../environments/environment';


@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    private injector: Injector) {
    }

  handleError(err: Error | HttpErrorResponse) {
    if (isPlatformBrowser(this.platformId)) {
      this.handleBrowserError(err);
    } else {
      console.error('ERROR occurred:', err);
    }
  }


  handleBrowserError(error: Error | HttpErrorResponse) {


    // for debug purposes, also capture it to console
    if (console && console.error) {
      console.error('handleBrowserError occurred:', error);
    }

    // note don't use optional chaining navigator?.useragent
    // since we are looking for old browsers that don't
    // support optional chaining.
    if (navigator && navigator.userAgent) {
      if (navigator.userAgent.indexOf('splash Version/10.0 Safari/602.1') !== -1) {
        return;
      }
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
// FIXME(karl)
//      Sentry.captureMessage('Loading chunk failed.');
//      Sentry.configureScope((scope) => {
//        scope.setExtra('Error message', error.message);
//      });
      console.error(`Loading chunk file issue, reloading app.`);
      window.location.reload();
    }



    const notificationService = this.injector.get(NotificationService);
    const errorsService = this.injector.get(ErrorsService);

    if (error instanceof HttpErrorResponse) {
      console.log('server error', error);
      // Server error happened
      if (!navigator.onLine) {
        return notificationService.notify({
          message: 'You are not connected!',
          type: 'alert'
        });
      }

      if (errorsService.checkIgnoreList(error)) {
        errorsService.log(error).subscribe();
        return notificationService.notify({
          message: (error && error.status && (error.message || error.error.message)) ?
            `${error.status} - ${error.error.message || error.message}` : 'Something went wrong, please reload the page.',
          type: 'alert'
        });
      }
      if (error.status === 403) {
        window.location.reload();
      }
    } else {
      // Client Error happened

      console.log('client error', error);
      const ignoredMessages =  [
        'Could not load "util".' // google maps
      ];


      if (error.message) {
        console.log('client error.message', error.message);
        for (const filter of ignoredMessages) {

          if (error.message === filter) {
            return;
          }
          if (error.message.match(filter)) {
            return;
          }
        }

      }

      try {
        Sentry.captureException(error);
      } catch (sentryError) {
        console.error('error sending to sentry', sentryError);
      }

      errorsService
        .log(error)
        .subscribe(errorWithContextInfo => {
          console.log('errorWithContextInfo: ', errorWithContextInfo);
        });
    }
  }
}

