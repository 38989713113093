<ng-template #notdPartnersModal class="notd-partners-modal" let-c="close" let-d="dismiss">
    <a (click)="d('Cross click')" data-cy="close-modal" class="notd-partners-modal__close-btn" title="Close Modal">
        <svg enable-background="new 0 0 413.348 413.348" height="512" viewBox="0 0 413.348 413.348" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"/></svg>
    </a>
    <a class="notd-partners-modal__logo" href="https://transact.io" title="Visit transact.io website!">
        <img src="../../assets/transact_logo-tm-horiz.png" alt="Visit transact.io website!"/>
    </a>
    <h2 class="notd-partners-modal__title">
        Notd partners with transact.io for authentication and payments. Click to continue to transact to login or signup. 
    </h2>
    <h3
        class="notd-partners-modal__subtitle"
        *ngIf="inUS">Create a Notd account on Transact and get $3 in free credit! No credit card required</h3>
    <div
        *ngIf="!splitButtons"
        class="notd-partners-modal__button">
        <button
            class="notd__button--v2 button--black"
            data-cy="signup-and-login-button"
            [customReturnUrl]="customReturnUrl && customReturnUrl?.global?.enable && customReturnUrl?.global?.url ? customReturnUrl.global.url : ''"
            transact-auth>{{buttonLabel ? buttonLabel : 'Sign-Up'}}</button>
    </div>
    <div
        *ngIf="splitButtons"
        class="notd-partners-modal__button">
        <button
            class="notd__button--v2 button--black"
            data-cy="sign-up-button"
            transact-auth
            [register]="true"
            [customReturnUrl]="customReturnUrl && customReturnUrl?.signup?.enable && customReturnUrl?.signup?.url ? customReturnUrl.signup.url : ''">Sign-up</button>
        <span class="notd-partners-modal__button__separator">or</span>
        <button
            class="notd__button--v2 button--green"
            data-cy="login-button"
            transact-auth
            [customReturnUrl]="customReturnUrl && customReturnUrl?.login?.enable && customReturnUrl?.login?.url ? customReturnUrl.login.url : ''">Login</button>
    </div>
</ng-template>