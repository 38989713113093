import {
  Directive,
  Attribute,
  Renderer2,
  ElementRef,
  HostListener } from '@angular/core';

@Directive({
  selector: '[imageLoaded]'
})
export class ImageLoadedDirective {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef) { }

  @HostListener('load') onLoad() {
    this.renderer.addClass(this.el.nativeElement, 'img-loaded');
  }
  @HostListener('error') onError() {
    this.renderer.addClass(this.el.nativeElement, 'err-loaded');
  }
}
