/* eslint-disable @typescript-eslint/naming-convention */
export enum SubscriptionStatus {
  // constant members
  Invalid = -1,
  None = 0,
  TokenCreated = 1, // got payment token but did not pay yet
  Active = 2, //
  Expired = 3, //  expired and not renewed yet

}
