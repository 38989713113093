import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {StreamService as StreamService} from '../services/stream_service';
import {Stream as Stream} from '../types/stream';

@Injectable()
export class StreamResolve  {
  constructor(private streamService: StreamService, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Stream[]> {
    return this.streamService.getUserStreams().then((streams) => {
      if (streams) {
        return streams;
      } else {
        return [];
      }
    }, () => []);
  }
}

@Injectable()
export class StreamDetailsResolve  {
  constructor(
    private streamService: StreamService,
    private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Stream> {
    if (route.params['custom-url']) {
      const customUrl = route.params['custom-url'];
      return this.streamService.getStreamIdFromCustomUrl(customUrl)
        .then(result => this.getStreamInfo(result.channel.id))
        .catch(err => {
          console.error('Error while fetching stream custom-url data', err);
          this.router.navigate(['/404']);
          return null;
        });
    } else {
      return this.getStreamInfo(route.params['id']);
    }
  }

  private getStreamInfo(streamId: string): Promise<any> {
    return this.streamService.getStreamInfo(streamId, true, true).then((stream) => {
      if (stream && stream.id) {
        return stream;
      } else {
        return null;
      }
    }, (err) => {
      console.error(err);
      this.router.navigate(['/404']);
    })
    .catch(err => {
      console.error(err);
      this.router.navigate(['/404']);
    });
  }

}
