import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Observable,
  Subject,
  map
} from 'rxjs';
import { SearchQuery } from '../types/search_query';
import { Note } from '../types/note';
import { publishReplay, refCount } from 'rxjs/operators';

export interface SearchParamsType {
    distance: any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    location: {};
}

@Injectable()
export class SearchService {
    public searchParams: SearchQuery;
    searchParamsSubject = new Subject<any>();

    constructor(
        private http: HttpClient) {
            this.searchParams = new SearchQuery();
        }

    getNotes(): Observable<Note[]> {
        const currentTag = this.searchParams.tags;
        const currentMediaTags = this.searchParams.mediaTag;
        const tag = (currentTag && currentMediaTags) ? `${currentTag},${currentMediaTags}` : currentTag || currentMediaTags || '';

        const params: HttpParams = new HttpParams()
            .set( 'q', this.searchParams.query ? this.searchParams.query : '' )
            .set( 'distance', this.searchParams.distance ? this.searchParams.distance + 'mi' : '' )
            .set( 'lat', this.searchParams.location.latitude ? this.searchParams.location.latitude : '' )
            .set( 'lon', this.searchParams.location.longitude ? this.searchParams.location.longitude : '' )
            .set( 'city', this.searchParams.location.city ? this.searchParams.location.city : '' )
            .set( 'region', this.searchParams.location.region ? this.searchParams.location.region : '' )
            .set( 'countryCode', this.searchParams.location.countryCode ? this.searchParams.location.countryCode : '' )
            .set( 'tag', tag ? tag : '' )
            .set( 'fromDate', this.searchParams.fromDate ? this.searchParams.fromDate : '' )
            .set( 'ratingMin', this.searchParams.ratingMin ? this.searchParams.ratingMin : '' )
            .set( 'size', this.searchParams.size ? this.searchParams.size.toString() : '' )
            .set( 'fromOffset', this.searchParams.fromOffset ? this.searchParams.fromOffset.toString() : '' )
            .set( 'getDetails', '1' );

        return this.http.get<any>(`/api/search/posts/simple/query` , { params })
            .pipe(
                publishReplay(1),
                refCount(),
                map((notes: Note[]) => notes)
            );
    }

    getChannels(): Observable<any> {
        const params: HttpParams = new HttpParams()
            .set( 'q', this.searchParams.query ? this.searchParams.query : '' )
            .set( 'distance', this.searchParams.distance ? this.searchParams.distance + 'mi' : '' )
            .set( 'lat', this.searchParams.location.latitude ? this.searchParams.location.latitude : '' )
            .set( 'lon', this.searchParams.location.longitude ? this.searchParams.location.longitude : '' )
            .set( 'city', this.searchParams.location.city ? this.searchParams.location.city : '' )
            .set( 'region', this.searchParams.location.region ? this.searchParams.location.region : '' )
            .set( 'countryCode', this.searchParams.location.countryCode ? this.searchParams.location.countryCode : '' )
            .set( 'tag', this.searchParams.tags ? this.searchParams.tags : '' )
            .set( 'fromDate', this.searchParams.fromDate ? this.searchParams.fromDate : '' )
            .set( 'ratingMin', this.searchParams.ratingMin ? this.searchParams.ratingMin : '' )
            .set( 'size', this.searchParams.size ? this.searchParams.size.toString() : '' )
            .set( 'fromOffset', this.searchParams.fromOffset ? this.searchParams.fromOffset.toString() : '' )
            .set( 'getDetails', '1' );

        return this.http.get<any>(`/api/search/channels/simple/query` , { params });
    }

    getPosts(queryObject: {
        q?: string;
        channelId?: string;
        size?: string;
        tag?: string;
        getDetails?: string;
        fromOffset?: string; }): Observable<any> {
        const params: HttpParams = new HttpParams()
            .set( 'q', (queryObject.q) ? queryObject.q : '' )
            .set( 'channelId', (queryObject.channelId) ? queryObject.channelId : '' )
            .set( 'size', (queryObject.size) ? queryObject.size : '' )
            .set( 'fromOffset', (queryObject.fromOffset) ? queryObject.fromOffset : '' )
            .set( 'tag', (queryObject.tag) ? queryObject.tag : '' )
            .set( 'getDetails', (queryObject.getDetails) ? queryObject.getDetails : '1' );

        return this.http.get<any>(`/api/search/posts/simple/query` , { params });
    }

    getRelatedNotes(noteId: string): Observable<Note[]> {
        return this.http.get<any>(`/api/search/posts/related/${noteId}`);
    }

    setSearchParams(params: any) {
        this.searchParams = Object.assign({}, this.searchParams, params);
        this.searchParamsSubject.next(this.searchParams);
    }

    getSearchParams() {
        return this.searchParams;
    }

    getSearchParamsChange() {
        return this.searchParamsSubject.asObservable();
    }
}
