import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faSync} from '@fortawesome/free-solid-svg-icons';

import {ContactFormModalComponent} from './contact-form-modal.component';

@NgModule({
    declarations: [
      ContactFormModalComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [ ContactFormModalComponent ]
})

export class ContactFormModalModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync);
  }
}
