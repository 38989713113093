import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'img-lazy-loading',
    templateUrl: './image-lazy-loading.component.html',
    styleUrls: ['./image-lazy-loading.component.scss']
})
export class ImgLazyLoadingComponent implements OnInit, OnChanges {
    @Input() url: RouterLink;
    @Input() title = '';
    @Input() mainThumb?: string;
    @Input() customSizes?: string;
    @Input() thumbs: { [width: number]:  string};
    @Input() imgWidth: number | string | null;
    @Input() imgHeight: number | string | null;

    public thumbPreview: string;
    public thumbDefault: string;
    public srcSet: string;
    public sizes: string;

    ngOnInit() {
        if (this.thumbs) {
            this.setThumbs();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.thumbs && changes.thumbs.currentValue) {
            this.setThumbs();
        }
    }

    private setThumbs(): void {
        this.thumbDefault = this.getThumb(this.thumbs, 'min'); // before max
        this.thumbPreview = this.getThumb(this.thumbs, 'min');

        const setsArray = [];

        for (const [key, value] of Object.entries(this.thumbs)) {
            setsArray.push(`${value} ${key}w`);
        }

        this.srcSet = setsArray.join();

        this.sizes = this.customSizes ? this.customSizes :
            `(min-width: 800px) ${this.imgWidth ? this.imgWidth + 'px' : '11vw'}, (min-width: 600px) 25vw, (max-width: 600px) 20vw, 90vw`;

    }

    private getThumb(thumbQualityArray: any, type: string): string {
        const objKeys: any[] = Object.keys(thumbQualityArray);
        const max = Math.max(...objKeys);
        const min = Math.min(...objKeys);

        return type === 'max' ? thumbQualityArray[max] : thumbQualityArray[min];
    }
}
