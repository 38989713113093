import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {A2hsBrowserPromptComponent} from './a2hs-prompt-button.component';

@NgModule({
  declarations: [ A2hsBrowserPromptComponent ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [ A2hsBrowserPromptComponent ]
})

export class A2hsBrowserPromptModule {}
