import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ErrorsModule} from '../../shared/errors-handler/errors.module';

import {NotificationBarComponent} from './notification-bar.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';

@NgModule({
    declarations: [ NotificationBarComponent ],
    imports: [
        CommonModule,
        ErrorsModule,
        FontAwesomeModule
    ],
    exports: [ NotificationBarComponent ]
})

export class NotificationBarModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faTimesCircle);
  }
}
