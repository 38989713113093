import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {SearchContentComponent} from './search.component';
import { ImgLazyLoadingModule } from '../image-lazy-loading/image-lazy-loading.module';

@NgModule({
    declarations: [
        SearchContentComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ImgLazyLoadingModule
    ],
    exports: [ SearchContentComponent ]
})

export class SearchContentModule {}
