import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'notd-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})

export class ConfirmationModalComponent {
  @Input() confirmationModalTitle: string;
  @Input() confirmationModalMsg: string;
  @Input() onlyMessage: boolean;
  @Input() waiting: boolean;
  @Input() buttons: boolean;
  @Input() icon: string | null;

  constructor(
    public activeModal: NgbActiveModal) { }
}
