import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {StreamNavComponent} from './stream-nav.component';

@NgModule({
    declarations: [ StreamNavComponent ],
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [ StreamNavComponent ]
})

export class StreamNavModule {}
