import {enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import 'zone.js';


Sentry.init({
  dsn: 'https://d32581c04bbe481b812f4b5b13e54663@sentry.io/1244426',

  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Sentry.Replay(),
  ],

  // avoid enabling when just running "npm start" to avoid a lot of emails
  enabled: environment.production,
  maxBreadcrumbs: 50,
//  debug: !environment.production,
  release : environment.gitHash,
  // less tracing on production than staging and dev
  tracesSampleRate: environment.production ? 0.5 : 1.0,

  // Capture Replay for 1% of all sessions,
  // plus for 50% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.5,
});




if (environment.production) {
  enableProdMode();
}

declare const require; // use the require method provided by webpack

//const cacheService = new CacheService();
const currentLng = 'en';
const translations = require(`raw-loader!./locale/messages.${currentLng}.xlf`);

platformBrowserDynamic().bootstrapModule(AppModule, {
  // missingTranslation: MissingTranslationStrategy.Error,
  providers: [
    {provide: TRANSLATIONS, useValue: translations},
    {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
  ]
})
.catch(err => console.log(err));
