import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable()
export class Util {
  currentTopOffset = 0;
  sideMenuToggleSub = new BehaviorSubject<any>(null);
  sideMenuToggle$ = this.sideMenuToggleSub.asObservable();
  counter = 0;
  counterSub = new BehaviorSubject<any>(null);

  private userAvatarSub = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  userAvatar$ = this.userAvatarSub.asObservable();

  shareUserAvatar(avatar: 'string') {
    this.userAvatarSub.next(avatar);
  }

  convertMilliSecondsToDate(milliSeconds: string) {
    return new Date(milliSeconds);
  }

  toggleSideMenu(value: string) {
    this.sideMenuToggleSub.next(value);
    setTimeout(() => {
      this.sideMenuToggleSub.next('');
    }, 100);
  }

  uncacheUrl(url: string, param = '?rndmval=') {
    if (url && url.length > 0) {
      const pos: number = url.indexOf(param);
      const t = (new Date()).getTime();

      if (pos < 0) {
        url += param + t;
      } else {
        url = url.substr(0, pos) + param + t;
      }
    }
    return url;
  }

  difDays(timestamp1: number, timestamp2: number): number {
    const timeDiff: any = (timestamp2 - timestamp1) / (1000 * 60 * 60 * 24);
    return parseInt(timeDiff, 10);
  }

  difDaysToday(timestamp: number): number {
    return this.difDays(timestamp, (new Date()).getTime());
  }

  getImageExtension(image: HTMLImageElement): string {

    let ext = '';
    const imgHeader = 'data:image/';

    if (image.src.indexOf(imgHeader) === 0) {
      ext = image.src.substring(imgHeader.length, image.src.indexOf(';', imgHeader.length));
    }

    return ext;
  }

  isFileImage(file: File) {
    return (file.type.split('/')[0] === 'image');
  }

  public scrollToElement(element: any, yOffset?: number): void {
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo(0, y);
  }

  public isFileLoaded(src: string): Promise<{loaded: boolean}> {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.setAttribute('src', src);

      script.onerror = () => {
        console.log('error getting file', src);
        resolve({
          loaded: false
        });
        script.remove();
      };

      script.onload = () => {
        console.log('file loaded', src);
        resolve({
          loaded: true
        });

        script.remove();
      };

      document.body.appendChild(script);
    });
  }

  public checkIfIframeLoaded(iframe: HTMLIFrameElement): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let timesChecked = 0;
      const interval = setInterval(() => {
        try {
          if (!iframe.contentDocument?.body) {
            resolve();
            clearInterval(interval);
          }
        } catch (_) {
          resolve();
          clearInterval(interval);
        }

        if (timesChecked > 50) {
          reject();
          clearInterval(interval);
        }
        timesChecked++;
      }, 100);
    });
  }

  resizeImage(
    image: HTMLImageElement,
    maxWidth: number,
    reductionFactor: number,
    applyByteReduction: boolean): Observable<string> {

    return Observable.create(observer => {

      const ext = this.getImageExtension(image);

      if (applyByteReduction) {

        let scaledImage;

        try {
          scaledImage = this.downScaleImage(image, reductionFactor);
        } catch (err) {
          throw err;
        }

        const canvas = document.createElement('canvas');

        canvas.width = scaledImage.width;
        canvas.height = scaledImage.height;
        canvas.getContext('2d').drawImage(scaledImage, 0, 0, canvas.width, canvas.height);

        image.src = canvas.toDataURL('image/' + ext);

        canvas.toBlob(
          blob => {

            const f = new File([blob], 'unknown', {type: blob.type});
            observer.next({image, file: f});
            observer.complete();
          },
          ext,
          1
        );

        return;
      }

      this.resizePixels(image, maxWidth).subscribe(
        resp => {
          observer.next(resp);
          observer.complete();
        },
        resizeError => {
          observer.error(resizeError);
        }
       );
    });

  }

  resizeImageFile(file: any, maxWidth: number): Observable<any> {

    return Observable.create(observer => {
      const reader = new FileReader();

      reader.onload = (readerEvent: any) => {

        const image = new Image();
        image.onload = (imageEvent: any) => this.resizePixels(image, maxWidth, file);

        image.src = readerEvent.target.result;
      };

      reader.readAsDataURL(file);

    });
  }

  resizePixels(image: HTMLImageElement, maxWidth: number, file?: File): Observable<any> {

    return Observable.create(observer => {

      const ext = this.getImageExtension(image);

      const fileName = file ? file.name : 'unknown';

      let width = image.naturalWidth;
      let height = image.naturalHeight;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxWidth) {
          width *= maxWidth / height;
          height = maxWidth;
        }
      }

      if (image.naturalWidth <= width) {

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);

        canvas.toBlob(
          blob => {

            const f = new File([blob], fileName, {type: blob.type});

            observer.next({image, file: f});
            observer.complete();

          },
          ext,
          1
        );

        return;
      }

      const imgAux = new Image();

      imgAux.onload = () => {

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(imgAux, 0, 0, width, height);

        const src = canvas.toDataURL('image/' + ext);

        image.src = src; // Resized in pixels the current image in DOM

        canvas.toBlob(
          blob => {

            const f = new File([blob], fileName, {type: blob.type});
            observer.next({image, file: f});
            observer.complete();

          },
          ext,
          1
        );
      };

      imgAux.onerror = loadError => {
        observer.error(loadError);
      };

      imgAux.src = image.src;

    });

  }

  downScaleImage(img, scale) {

    // Thanks http://jsfiddle.net/gamealchemist/r6aVp/

    const imgCV = document.createElement('canvas');

    imgCV.width = img.naturalWidth; // Important modification with respect to http://jsfiddle.net/gamealchemist/r6aVp/
    imgCV.height = img.naturalHeight; // Important modification with respect to http://jsfiddle.net/gamealchemist/r6aVp/

    const imgCtx = imgCV.getContext('2d');

    imgCtx.drawImage(img, 0, 0);

    return this.downScaleCanvas(imgCV, scale);

  }


  isTouchDevice() {
    //NOTE(karl)  avoid using this function, it causes difficulty with prerendering.
    // if possible use techniques:
    // https://www.geeksforgeeks.org/how-to-detect-touch-screen-device-using-css/
    // https://css-irl.info/detecting-hover-capable-devices/
    // https://ferie.medium.com/detect-a-touch-device-with-only-css-9f8e30fa1134
    try {
      if (!document) {
        return false;
      }
    } catch {
      return false;
    }

    return 'ontouchstart' in document;
  }

  setBodyFixed(status: boolean) {
    const body = document.querySelector('body');
    if (status === true) {
      this.currentTopOffset = window.pageYOffset || document.documentElement.scrollTop;
      body.style['top'] = -this.currentTopOffset + 'px';
      body.classList.add('fixed');
  } else {
      body.classList.remove('fixed');
      body.removeAttribute('style');
      document.documentElement.scrollTop = this.currentTopOffset;
    }
  }

  setCounter() {
    this.counterSub.next(this.counter++);
  }

  getCounter() {
    return this.counterSub.asObservable();
  }

  downscaleImage(file: any, size: any): Promise<any> {
    const reader = new FileReader();
    const img = new Image();

    const scales = [.9, .8, .7, .6, .5, .4, .3, .2, .1];
    let properSize = false;

    return new Promise((resolver) => {
      reader.readAsDataURL(file);

      reader.addEventListener('load', () => {
        img.src = reader.result.toString();

        img.addEventListener('load', () => {
          const imgW = img.width;
          const imgH = img.height;

          for (let i = 0, p = Promise.resolve(); i < scales.length; i++) {
            p = p.then(() => new Promise(resolve => {
              if (!properSize) {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = Math.floor(imgW * scales[i]);
                canvas.height = Math.floor(imgH * scales[i]);

                ctx.scale(scales[i], scales[i]); // 0.5
                ctx.drawImage(img, 0, 0);
                ctx.rect(0, 0, imgW, imgH);
                ctx.stroke();

                ctx.scale(1 + scales[i], 1 + scales[i]);
                ctx.rect(0, 0, imgW, imgH);
                ctx.stroke();

                canvas.toBlob(
                  blob => {
                    const f = new File([blob], 'abc', {type: blob.type});
                    console.log(scales[i], f.size);
                    if (f.size <= size) {
                      properSize = true;
                      resolver(f);
                    }
                    resolve();
                  },
                  file.type
                );
              }
            }));
          }
        });
      });
    });
  }

  downScaleCanvas(cv, scale) {

    /* eslint-disable no-bitwise */
    // https://stackoverflow.com/questions/41223940/alternative-to-bitwise-operators-in-uuid-creation

    // Thanks http://jsfiddle.net/gamealchemist/r6aVp/

    if (!(scale < 1) || !(scale > 0)) {
      throw new Error('scale must be a positive number <1 ');
    }

    scale = this.normaliseScale(scale);

    const sqScale = scale * scale; // square scale =  area of a source pixel within target
    const sw = cv.width; // source image width
    const sh = cv.height; // source image height
    const tw = Math.floor(sw * scale); // target image width
    const th = Math.floor(sh * scale); // target image height
    let sx = 0; let sy = 0; let sIndex = 0; // source x,y, index within source array
    let tx = 0; let ty = 0; let yIndex = 0; let tIndex = 0; // target x,y, x,y index within target array
    let tX = 0; let tY = 0; // rounded tx, ty
    let w = 0; let nw = 0; let wx = 0; let nwx = 0; let wy = 0; let nwy = 0; // weight / next weight x / y

    // weight is weight of current source point within target.
    // next weight is weight of current source point within next target's point.
    let crossX = false; // does scaled px cross its current px right border ?
    let crossY = false; // does scaled px cross its current px bottom border ?

    const sBuffer = cv.getContext('2d').getImageData(0, 0, sw, sh).data; // source buffer 8 bit rgba

    const tBuffer = new Float32Array(3 * tw * th); // target buffer Float32 rgb
    let sR = 0; let sG = 0; let  sB = 0; // source's current point r,g,b

    for (sy = 0; sy < sh; sy++) {

        ty = sy * scale; // y src position within target
        tY = 0 | ty;     // rounded : target pixel's y
        yIndex = 3 * tY * tw;  // line index within target array
        crossY = (tY !== (0 | ( ty + scale )));

        if (crossY) { // if pixel is crossing botton target pixel
            wy = (tY + 1 - ty); // weight of point within target pixel
            nwy = (ty + scale - tY - 1); // ... within y+1 target pixel
        }

        for (sx = 0; sx < sw; sx++, sIndex += 4) {
            tx = sx * scale; // x src position within target

        tX = 0 | tx;     // rounded : target pixel's y

            tIndex = yIndex + tX * 3; // target pixel index within target array
            crossX = (tX !== (0 | (tx + scale)));

            if (crossX) { // if pixel is crossing target pixel's right
                wx = (tX + 1 - tx); // weight of point within target pixel
                nwx = (tx + scale - tX - 1); // ... within x+1 target pixel
            }

            sR = sBuffer[sIndex    ];   // retrieving r,g,b for curr src px.
            sG = sBuffer[sIndex + 1];
            sB = sBuffer[sIndex + 2];

            if (!crossX && !crossY) { // pixel does not cross
                // just add components weighted by squared scale.
                tBuffer[tIndex    ] += sR * sqScale;
                tBuffer[tIndex + 1] += sG * sqScale;
                tBuffer[tIndex + 2] += sB * sqScale;
            } else if (crossX && !crossY) { // cross on X only
                w = wx * scale;
                // add weighted component for current px
                tBuffer[tIndex    ] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                // add weighted component for next (tX+1) px
                nw = nwx * scale;
                tBuffer[tIndex + 3] += sR * nw;
                tBuffer[tIndex + 4] += sG * nw;
                tBuffer[tIndex + 5] += sB * nw;
            } else if (!crossX && crossY) { // cross on Y only
                w = wy * scale;
                // add weighted component for current px
                tBuffer[tIndex    ] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;

                // add weighted component for next (tY+1) px
                nw = nwy * scale;

                tBuffer[tIndex + 3 * tw    ] += sR * nw;
                tBuffer[tIndex + 3 * tw + 1] += sG * nw;
                tBuffer[tIndex + 3 * tw + 2] += sB * nw;
            } else { // crosses both x and y : four target points involved
                // add weighted component for current px
                w = wx * wy;
                tBuffer[tIndex    ] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                // for tX + 1; tY px
                nw = nwx * wy;
                tBuffer[tIndex + 3] += sR * nw;
                tBuffer[tIndex + 4] += sG * nw;
                tBuffer[tIndex + 5] += sB * nw;
                // for tX ; tY + 1 px
                nw = wx * nwy;
                tBuffer[tIndex + 3 * tw    ] += sR * nw;
                tBuffer[tIndex + 3 * tw + 1] += sG * nw;
                tBuffer[tIndex + 3 * tw + 2] += sB * nw;
                // for tX + 1 ; tY +1 px
                nw = nwx * nwy;
                tBuffer[tIndex + 3 * tw + 3] += sR * nw;
                tBuffer[tIndex + 3 * tw + 4] += sG * nw;
                tBuffer[tIndex + 3 * tw + 5] += sB * nw;
            }
        } // end for sx
    } // end for sy


    // create result canvas

    const resCV = document.createElement('canvas');
    resCV.width = tw;
    resCV.height = th;

    const resCtx = resCV.getContext('2d');
    const imgRes = resCtx.getImageData(0, 0, tw, th);
    const tByteBuffer = imgRes.data;

    // convert float32 array into a UInt8Clamped Array
    let pxIndex = 0; //

    for (sIndex = 0, tIndex = 0; pxIndex < tw * th; sIndex += 3, tIndex += 4, pxIndex++) {

        tByteBuffer[tIndex] = 0 | ( tBuffer[sIndex]);
        tByteBuffer[tIndex + 1] = 0 | (tBuffer[sIndex + 1]);
        tByteBuffer[tIndex + 2] = 0 | (tBuffer[sIndex + 2]);
        tByteBuffer[tIndex + 3] = 255;
    }

    // writing result to canvas.
    resCtx.putImageData(imgRes, 0, 0);

    return resCV;
  }

  private log2(v) {

    // Thanks http://jsfiddle.net/gamealchemist/r6aVp/
    // taken from http://graphics.stanford.edu/~seander/bithacks.html

    const b =  [ 0x2, 0xC, 0xF0, 0xFF00, 0xFFFF0000 ];
    const S =  [1, 2, 4, 8, 16];

    let i = 0; let r = 0;

    for (i = 4; i >= 0; i--) {
      if (v & b[i])  {
        v >>= S[i];
        r |= S[i];
      }
    }
    return r;
  }

  private normaliseScale(s) {
    // Thanks http://jsfiddle.net/gamealchemist/r6aVp/
    // normalize a scale <1 to avoid some rounding issue with js numbers

    if (s > 1) {
      throw new Error('s must be <1');
    }

    s = 0 | (1 / s);

    let l = this.log2(s);
    let mask = 1 << l;
    let accuracy = 4;

    while (accuracy && l) {
      l--;
      mask |= 1 << l;
      accuracy--;
    }

    return 1 / ( s & mask );
  }
}
