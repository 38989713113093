import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FooterComponent } from './footer.component';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { NotdPartnersModalModule } from '../notd-partners-modal/notd-partners-modal.module';
import { LoginModule } from '../transact-auth/transact-auth.module';

@NgModule({
    declarations: [ FooterComponent ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NotdPartnersModalModule,
        LoginModule,
    ],
    exports: [ FooterComponent ]
})

export class FooterModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faEnvelope, faTwitter);
  }
}
