import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {A2hsIosPromptScreenComponent} from './a2hs-ios-prompt-screen.component';

@NgModule({
  declarations: [ A2hsIosPromptScreenComponent ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [ A2hsIosPromptScreenComponent ]
})

export class A2hsIosPromptScreenModule {}
