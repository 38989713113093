import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ContactService {

    constructor(private http: HttpClient) { }

    sendMessage(message: any): Promise<Response> {
        const url = '/api/util/support/contact';
        return this.http.post<any>(url, message).toPromise();
    }
}
