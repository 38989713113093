import {Injectable} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../services/user_service';
import {NoteService} from '../services/note_service';

@Injectable()
export class NoteDraftGuard  {
    private routerParams: any;

    constructor(
        private userService: UserService,
        private noteService: NoteService,
        private route: ActivatedRoute,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.routerParams = route.params;
        if (route.params['custom-url']) {
            const customUrl = route.params['custom-url'];
            this.noteService.getNoteIdFromCustomUrl(customUrl).then(
                result => this.getNoteInfo(result.post.id),
                err => {
                    console.error('No custom url found, redirect to 404', err);
                    this.router.navigate(['/404']);
                    return false;
                }
              )
              .catch(err => {
                console.error('No custom url found, redirect to 404', err);
                this.router.navigate(['/404']);
                return false;
              });
        } else {
            return this.getNoteInfo(route.params['id']);
        }
    }

    private getNoteInfo(noteId: string): Promise<boolean> {
        let noteState = '';
        let isNoteOwner = false;

        return this.noteService.getNoteInfo(noteId)
            .then(note => {
                if (note.shortURLPath && !this.routerParams['custom-url']) {
                    this.router.navigate(['n', note.shortURLPath]);
                }

                noteState = note.state;

                if (note.parentPostId.length > 0) {
                    this.noteService.getNoteInfo(note.parentPostId.toString(), true).then(() => {
                      this.router.navigate(['/thread', note.parentPostId], { queryParams: { t: noteId } });
                    })
                    .catch(() => {
                      console.log(`${note.parentPostId} doesn't exist`);
                    });
                }

                if (note.childPosts.length > 0) {
                    this.router.navigate(['/thread', note.id], { queryParams: { t: note.id } });
                }

                if (this.userService.isLoggedIn()) {
                    return this.userService.getProfile()
                        .then(user => {
                            isNoteOwner = (user.id.toString() === note.userId.toString()) ? true : false;

                            if (noteState === 'HIDDEN') {
                                return (isNoteOwner) ? true : false;
                            } else {
                                return true;
                            }
                        });
                } else {
                    return (noteState === 'HIDDEN') ? false : true;
                }
            })
            .catch(() => {
                this.router.navigate(['404']);
                return false;
            });
    }
}
