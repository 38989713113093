import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ImageLoadedDirective} from './loage-loaded.directive';

@NgModule({
  declarations: [ ImageLoadedDirective ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [ ImageLoadedDirective ]
})

export class ImageLoadedModule {}
