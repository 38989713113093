import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {NoteService} from '../services/note_service';
import { UserService } from '../services/user_service';

@Injectable()
export class NotePurchaseStatusResolve  {

  constructor(
    private userService: UserService,
    private noteService: NoteService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.params['custom-url']) {
      const customUrl = route.params['custom-url'];
      return this.noteService.getNoteIdFromCustomUrl(customUrl).then(result => this.getNoteStatus(result.post.id));
    } else {
        return this.getNoteStatus(route.params['id']);
    }
  }

  private getNoteStatus(noteId: string): Promise<any> {
    return Promise.all([
      this.noteService.getNotePurchaseStatus(noteId),
      this.userService.getProfile()
    ]).then(results => {
      const status = results[0];
      const user = results[1];
      return (!!user['admin'] || (status.hasOwnProperty('hasAccess') && status['hasAccess'])) ? 1 : 402;
    }, () => null)
  .catch(err => {
    console.error('Error while fetching data', err);
  });
  }
}
