import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {
    @Input() customClass: string;
    @Input() checked: boolean;
    @Input() disabled = false;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() change = new EventEmitter<boolean>();

    constructor(
        private router: Router) { }

    toggle() {
        if (!this.disabled) {
            this.checked = !this.checked;
            this.change.emit(this.checked);
        }
    }
}
