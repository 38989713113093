import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {ConfirmationModalModule} from '../../../app/shared/confirmation-modal/confirmaton-modal.module';

import {NotificationButtonComponent} from './notification-button.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faBell, faInfoCircle, faLink, faSpinner, faTrash} from '@fortawesome/free-solid-svg-icons';

@NgModule({
    declarations: [ NotificationButtonComponent ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        ConfirmationModalModule,
        FontAwesomeModule,
        NgbDropdownModule
    ],
    exports: [ NotificationButtonComponent ]
})

export class NotificationButtonModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faBell, faTrash, faLink, faSpinner, faInfoCircle);
  }
}
