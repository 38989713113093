import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {UserEarningsCounterComponent} from './user-earnings-counter.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faDollarSign} from '@fortawesome/free-solid-svg-icons';

@NgModule({
    declarations: [ UserEarningsCounterComponent ],
    imports: [
        RouterModule,
        CommonModule,
        FontAwesomeModule
    ],
    exports: [ UserEarningsCounterComponent ]
})

export class UserEarningsCounterModule {
  constructor() {
    library.add(faDollarSign);
  }
}
