<section
    [ngClass]="{'preview--mode': isPreviewMode, 'preview--active': activePreviewContainer}"
    class="search-container">
    <form novalidate (ngSubmit)="onSubmit($event)" [formGroup]="searchForm" [ngClass]="properties.customClass">
        <div class="input-group"
             [ngClass]="{ 'icon-left': properties.iconSide === 'left', 'icon-right' : properties.iconSide === 'right' }">
            <svg
                (click)="onSubmit($event)"
                class="submit-btn"
                version="1.1" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" data-id="ae3119987b3269ea978f2a065d929425" style="fill: rgb(0, 0, 0); width: 30px; height: 32px; pointer-events: all; filter: none;" xmlns="http://www.w3.org/2000/svg"><g>
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                      style="fill: rgb(0, 0, 0);"/></g></svg>
            <span
                *ngIf="isPreviewMode && activePreviewContainer && loadingResults"
                class="preview-laoding-spinner"></span>
            <input type="text"
                   (focus)="mobileSubmitBtn(true)"
                   (blur)="mobileSubmitBtn(false)"
                   (keypress)="keyPressEvent($event)"
                   (click)="isPreviewMode ? togglePreviewModal() : ''"
                   class="form-control"
                   placeholder="{{ properties.inputPlaceholderText }}" formControlName="query">
            <a (click)="closePreviewEvent('hide')" *ngIf="isPreviewMode && activePreviewContainer" title="Close search" class="close-preview-btn">
                <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" height="512" viewBox="0 0 413.348 413.348" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"/></svg>
            </a>
            <button type="submit" *ngIf="showMobileSubmitBtn" class="btn mobile-search-btn btn-primary">Search</button>
        </div>
        <button type="submit" *ngIf="properties.showButton" class="btn btn-primary">{{ properties.buttonText }}</button>
    </form>
    <section
        *ngIf="activePreviewContainer"
        class="preview-list-container">
        <div
            class="preview-list-container__default"
            *ngIf="!searchResults.length && !searchForm.get('query').value.length">
            <h2 class="preview-list-container__default__headline">Suggested content</h2>
            <div class="preview-list-container__default__suggested">
                <a
                    class="preview-list-container__default__suggested__note"
                    [routerLink]="['/notes', n.id, n.indexedName || n.id]"
                    *ngFor="let n of previewDefaultResults">
                    {{n.name}}
                </a>
            </div>
            <h2 class="preview-list-container__default__headline">Also Worth to see</h2>
            <div
                *ngIf="categories && categories.length"
                class="preview-list-container__default__others">
                <a  
                    *ngFor="let c of categories"
                    [routerLink]="['/search', 'all', c.cat]" 
                    [title]="c.label">{{c.label}}</a>
            </div>
        </div>
        <div 
            *ngIf="searchResults && searchResults.length"
            class="preview-list-container__results">
            <div
                class="preview-list-container__results__note"
                [ngClass]="{'include--images' : true}"
                *ngFor="let n of searchResults">
                <img-lazy-loading
                    class="note-img"
                    *ngIf="n.note && n.note.thumbnails && n.note.thumbnailImage"
                    [url]="['/notes', n.note.id, n.note.indexedName || n.note.id]"
                    [mainThumb]="n.note.thumbnailImage"
                    [imgWidth]="50"
                    [thumbs]="n.note.thumbnails"
                    [title]="'Read more about ' + n.note.name"></img-lazy-loading>
                <div class="preview-list-container__results__note__description">
                    <a
                        *ngIf="n.note"
                        [routerLink]="['/notes', n.note.id, n.note.indexedName || n.note.id]"
                        title="Read about {{n.note.name}}"
                        class="preview-list-container__results__note__description__title">{{n.note.name}}</a>
                    <div class="preview-list-container__results__note__description__tags" *ngIf="n.note && n.note.price === 0">
                        <span class="preview-list-container__results__note__description__tags__tag tag--free-note" *ngIf="n.note && n.note.price === 0">free note</span>
                    </div>
                </div>
            </div>
            <div class="preview-list-container__results__more">
                <button
                    [routerLink]="['/search', 'query', 'notes', searchForm.get('query').value]" 
                    class="notd__button--v2 button--black">Show more results</button>
            </div>
        </div>
        <div
            *ngIf="searchForm.get('query').value.length > 3 && !searchResults.length && !userTypingEvent && !loadingResults"
            class="preview-list-container__no-results">
            <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><defs><style>
                .cls-1, .cls-2, .cls-3 {
                  fill: none;
                  stroke: grey;
                  stroke-width: 2px;
                }
                .cls-1, .cls-3 {
                  stroke-miterlimit: 10;
                }
                .cls-2, .cls-3 {
                  stroke-linecap: round;
                }
                .cls-2 {
                  stroke-linejoin: round;
                }
              </style></defs><circle class="cls-1" cx="30.76346" cy="30.26346" r="11.5"/><line class="cls-2" x1="38.68749" x2="47.02698" y1="38.6875" y2="47.02702"/><g><path class="cls-2" d="M21.49561,42a20.5,20.5,0,1,1,20.5-20.5"/><line class="cls-3" x1="21.49561" x2="21.49561" y1="1" y2="18.375"/><g><path class="cls-2" d="M16.735,31.91739a41.51361,41.51361,0,0,0-11.37561,2.198"/><path class="cls-1" d="M5.35937,8.88463C9.11178,10.32247,14.93672,11.25,21.49561,11.25s12.38383-.92753,16.13624-2.36537"/></g><path class="cls-2" d="M33.30726,15.76183C31.81816,7.23282,27.09361,1,21.49561,1c-6.7931,0-12.3,9.17816-12.3,20.5s5.5069,20.5,12.3,20.5"/><line class="cls-2" x1="18.75" x2="0.81579" y1="21.5" y2="21.5"/></g></svg>
              <p class="preview-list-container__no-results__label">
                No results found.
              </p>
        </div>
    </section>
</section>
