import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {PipesModule} from '../../shared/pipes/pipes.module';

import {StateSelectorComponent} from './state-selector.component';

@NgModule({
    declarations: [
        StateSelectorComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        PipesModule
    ],
    exports: [ StateSelectorComponent ]
})

export class StateSelectorModule {}
