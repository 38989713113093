import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../services/user_service';

@Injectable()
export class AccountRedirectGuard  {
  constructor(
    private userService: UserService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const userId = route.params.userId;
      if (!userId || userId.length === 0) {
        this.router.navigate(['/']);
        return false;
      }

      return new Promise<boolean>((resolve) => {
        this.userService.getProfile().then((user) => {
          if (user && user.id.toString() === userId) {
            resolve(true);
          } else {
            resolve(false);
            this.router.navigate(['/']);
          }
        }).catch( err => {
          resolve(false);
          this.router.navigate(['/']);
        });
      });
  }
}
