import * as Debug from 'debug';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { getAuthToken, setAuthToken } from '../../interceptors/api.interceptor';
import { UserService} from '../services/user_service';
import { NoteService } from '../services/note_service';
import { Note } from '../types/note';
const debug = Debug('notd:shared:AuthGuard');

@Injectable()
export class NoteOwnerGuard  {
  constructor(
    private userService: UserService,
    private noteService: NoteService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isLoggedIn = false;
    const noteId = route.paramMap.get('id');

    return new Promise(resolve => {
      resolve(true);

      forkJoin([
        this.userService.getProfile(),
        this.noteService.getNoteInfo(noteId),
      ]).subscribe(resp => {
        const loggedUser = resp[0];
        const noteDetails = resp[1];

        isLoggedIn = loggedUser && loggedUser.id ? true : false;

        if (isLoggedIn) {
          resolve(loggedUser.id.toString() === noteDetails.userId.toString());
        } else {
          this.
            router
            .navigate(noteDetails.shortURLPath ?
              ['/n', noteDetails.shortURLPath] : ['/notes', noteDetails.id, noteDetails.indexedName || noteDetails.name || noteDetails.id]);
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
  }
}
