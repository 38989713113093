<nav class="user-menu">
  <ul>
    <li>
      <a
        *ngIf="myUser"
        routerLink="/user/{{myUser.id}}/my-account"
        routerLinkActive="actived"
        i18n="@@STREAM-NAV-003">My Account</a>
    </li>
    <li>
      <a
        [routerLink]="['/streams']"
        data-cy="streams"
        routerLinkActive="actived"
        i18n="@@STREAM-NAV-002">Streams</a>
    </li>
    <li>
      <a
        [routerLink]="['/user/my-notes']"
        routerLinkActive="actived">My notes</a>
    </li>
    <li>
      <a
        *ngIf="myUser"
        routerLink="/user/my-subscriptions"
        routerLinkActive="actived">My Subscriptions</a>
    </li>
    <li>
      <a
        *ngIf="myUser"
        routerLink="/user/my-communities"
        routerLinkActive="actived">My Communities</a>
    </li>
    <li>
      <a
        [routerLink]="['/user/notifications']"
        title="Notifications"
        routerLinkActive="actived"
        i18n="@@STREAM-NAV-004">Notifications</a>
    </li>
    <li>
      <a
        *ngIf="myUser"
        routerLink="/user/{{myUser.id}}/saved-notes"
        routerLinkActive="actived"
        i18n="@@STREAM-NAV-005">Saved</a>
    </li>
    <li>
      <a
        *ngIf="myUser"
        routerLink="/user/followed-streams"
        routerLinkActive="actived">Followed streams</a>
    </li>
    <li>
      <a
        *ngIf="myUser"
        routerLink="/team"
        routerLinkActive="actived"
        i18n="@@STREAM-NAV-006">Team Account</a>
    </li>
    <li>
      <a
        *ngIf="myUser"
        routerLink="/user/{{myUser.id}}/earnings"
        routerLinkActive="actived"
        i18n="@@STREAM-NAV-007">Earnings</a>
    </li>
    <li>
      <a
        [routerLink]="['/user/trash']"
        routerLinkActive="actived">Trash</a>
    </li>
  </ul>
</nav>
