<ng-container *ngIf="isEnabledGeoLocator===true">
  <div class="user-location-wrapper">
    <div class="user-location-wrapper__detector">
      <button
        data-cy="location-btn"
        class="user-location-wrapper__detector__button notd__button--v2"
        title="Detect my location"
        (click)="setCurrentBrowserLocation()">
          <svg *ngIf="!loadingSpinner" class="user-location-wrapper__detector__button__ico" enable-background="new 0 0 128 128" height="128px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="128px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M63.999,104.752c-22.444,0-40.704-18.26-40.704-40.704c0-22.444,18.26-40.703,40.704-40.703s40.704,18.259,40.704,40.703  C104.703,86.492,86.443,104.752,63.999,104.752z M63.999,27.345c-20.238,0-36.704,16.465-36.704,36.703  c0,20.239,16.466,36.704,36.704,36.704s36.704-16.465,36.704-36.704C100.703,43.81,84.237,27.345,63.999,27.345z"/><path d="M63.999,83.962c-10.981,0-19.915-8.933-19.915-19.914s8.934-19.916,19.915-19.916s19.916,8.934,19.916,19.916  S74.98,83.962,63.999,83.962z M63.999,48.132c-8.775,0-15.915,7.14-15.915,15.916c0,8.774,7.14,15.914,15.915,15.914  c8.776,0,15.916-7.14,15.916-15.914C79.915,55.272,72.775,48.132,63.999,48.132z"/><path d="M63.999,27.345c-1.104,0-2-0.896-2-2V12.882c0-1.104,0.896-2,2-2s2,0.896,2,2v12.462  C65.999,26.449,65.104,27.345,63.999,27.345z"/><path d="M63.999,117.146c-1.104,0-2-0.896-2-2v-12.394c0-1.104,0.896-2,2-2s2,0.896,2,2v12.394  C65.999,116.25,65.104,117.146,63.999,117.146z"/><path d="M25.295,66.048H12.868c-1.104,0-2-0.896-2-2c0-1.105,0.896-2,2-2h12.427c1.104,0,2,0.895,2,2  C27.295,65.152,26.399,66.048,25.295,66.048z"/><path d="M115.132,66.048h-12.429c-1.104,0-2-0.896-2-2c0-1.105,0.896-2,2-2h12.429c1.104,0,2,0.895,2,2  C117.132,65.152,116.236,66.048,115.132,66.048z"/></svg>
          <fa-icon *ngIf="loadingSpinner" [icon]="['fas', 'circle-notch']" [spin]="true" [size]="'lg'"></fa-icon>
      </button>
      <input
        #locationInputField
        data-cy="map-location-input"
        type="text"
        (focus)="onLocationInputFocus()"
        [formControl]="locationInput"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        placeholder="Or type your location ..."
        class="user-location-wrapper__detector__input notd__input" />
    </div>
    <div 
      class="user-location-wrapper__detector__location-geo-error"
      *ngIf="geolocationError && geolocationError.code === 1">
      Your browser is blocking access to geolocation. Please try other browser or add address manually.
    </div>
  </div>
  <div class="col-12 text-center" *ngIf="simple && loadingCurrentLocation">
    <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
  </div>
  <div
    class="map-wrapper"
    *ngIf="!simple">
    <google-map
      width="100%"
      height="300px"
      [zoom]="zoom"
      [center]="center"
      (mapClick)="moveMap($event)">
      <map-marker
        [position]="center"></map-marker>
    </google-map>
  </div>
</ng-container>

