import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CacheService} from '../../shared/services/cache_service';
import {StreamService} from '../../shared/services/stream_service';
import {UserService} from '../../shared/services/user_service';
import {Util} from '../../shared/util/util';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from '../../shared/confirmation-modal/confirmation-modal.component';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'stream-subscribe',
  templateUrl: './stream-subscribe.component.html',
  styleUrls: ['./stream-subscribe.component.scss'],
  providers: [DatePipe]
})

export class StreamSubscribeComponent implements OnChanges {
  @Input() streamId: string;
  @Input() periodId: string;
  @Input() subscribeLabel = 'Subscribe';
  @Input() streamSubscribeTitle: string;
  @Input() streamSubscribeContent: string;
  @Output() statusUpdated: EventEmitter<any> = new EventEmitter();
  @Output() subscribeToStreamEvent: EventEmitter<{token: number | string; streamId: number | string; plan: string}> = new EventEmitter();

  token;
  readyToSubscribe;
  transactionEnded;
  label;
  loggedIn;
  errorMessage: string;
  public blockSubscribeButton = false;

  constructor(
    private utilService: Util,
    private streamService: StreamService,
    private cacheService: CacheService,
    private userService: UserService,
    private modalService: NgbModal,
    private elRef: ElementRef,
    private datePipe: DatePipe) { }

  ngOnChanges(changes: SimpleChanges) {
    this.loggedIn = true;

    if (!this.userService.isLoggedIn()) {
      this.loggedIn = false;
      this.readyToSubscribe = true;
      return;
    }

    if (this.streamId && this.periodId) {
      this.getToken();
    }
  }

  public subscribePlan() {
    if (!this.userService.isLoggedIn()) {
      this.userService.login().then(() => {
          this.cacheService.clearCache();
        }
      );
      return;
    }

    this.blockSubscribeButton = true;
    this.subscribeToStreamEvent.emit({
      token: this.token,
      streamId: this.streamId,
      plan: this.periodId
    });
  }

  private getToken() {
    this.errorMessage = '';
    this.readyToSubscribe = false;
    this.streamService.getStreamSubscriptionToken(
      this.streamId,
      this.periodId
      ).then((token) => {
        this.token = token;
        this.readyToSubscribe = true;
        this.label = this.subscribeLabel;
      }).catch(ex => {
        console.error('Error getting subscription token ', ex);
        const error = ex.error || {};
        this.errorMessage = error.message ? error.message : 'Status error';
      });
  }

  private showMessageFormatted(status: any, streamName: string) {
    let content =  this.streamSubscribeContent.replace('%s1', streamName);
    const date = this.datePipe.transform(status.expires, 'MMM dd, yyyy, h:mm aaa');
    content = content.replace('%s2', date);
    const modalOptions: NgbModalOptions = {};
    modalOptions.size = 'lg';
    modalOptions.backdrop = 'static'; // Prevents closing from clicking outside
    modalOptions.keyboard = false; // Prevents closing from pressing ESC

    const refModalConf2 = this.modalService.open(ConfirmationModalComponent, modalOptions);
    refModalConf2.componentInstance.onlyMessage = true;
    refModalConf2.componentInstance.confirmationModalTitle = this.streamSubscribeTitle;
    refModalConf2.componentInstance.confirmationModalMsg = content;
    refModalConf2.componentInstance.waiting = false;
  }

  private showModalMessage(status: any) {
    if (!this.streamSubscribeContent || this.streamSubscribeContent.trim().length === 0) {
      return;
    }

    this.streamService.getStreamInfoObservable(this.streamId).subscribe(
      resp => {
        this.showMessageFormatted(status, resp.name);
      },
      err => {
        this.showMessageFormatted(status, this.streamId);
      }
    );
  }
}
