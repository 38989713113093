import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnChanges {
  @Input() alert: {type: string; message: string; timeout?: number, floating?: boolean};
  @Output() messageRemoved: EventEmitter<boolean> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.alert &&
      changes.alert.currentValue &&
      changes.alert.currentValue.timeout) {
      const timeout = changes.alert.currentValue.timeout;
      console.log('timeout triggered, remove messageg after', changes.alert.currentValue.timeout);
      setTimeout(() => {
        if (this.alert && this.alert.message) {
          console.log('close message');
          this.closeMessage();
        }
      }, timeout);
    }
  }

  closeMessage() {
    this.alert = null;
  }
}
