import { Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { StreamService } from '../services/stream_service';
import { UserService} from '../services/user_service';
import { Observable, forkJoin} from 'rxjs';


@Injectable()
export class NoteGuideGuard  {
  constructor(
    private userService: UserService,
    private streamService: StreamService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.userStreams();
  }

  userStreams(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      forkJoin([
        this.userService.listCommunities(),
        this.userService.getProfile()
      ]).subscribe(resp => {
        const communityMember = resp[0];
        const user = resp[1];

        if (user && user.id !== '') {
          Promise.all([
            this.streamService.getUserStreams(true),
            this.streamService.getUserTeamStreams()
          ]).then((results: any) => {
            const userStreams = results[0];
            const userTeamStreams = results[1].channels ? results[1].channels : [];
            if (userStreams.length > 0 || userTeamStreams.length > 0 || communityMember.length > 0) {
              this.router.navigate(['/note']);
              resolve(false);
            } else {
              resolve(true);
            }
          }).catch(() => {
            resolve(false);
          });
        } else {
          this.userService.login().then(() => {
            resolve(false);
          });
        }
      }, () => {
        resolve(false);
      });
    });
  }
}
