
import {
  AfterViewInit, Component, ElementRef,
  EventEmitter, Input, Inject, NgZone, PLATFORM_ID,
  OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

import {GeoService} from '../../services/geographic_service';

import {Location} from '../../types/location';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { MapGeocoder } from '@angular/google-maps';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'address-loc',
  templateUrl: './address-loc.component.html',
  styleUrls: ['./address-loc.component.scss']
})

export class AddressLocComponent implements OnInit, OnChanges {
  public static geoLocEnabled = false;
  @Input() simple: boolean;
  @Input() defaultLocation: any;
  @ViewChild('locationInputField', {static: false}) locationInputField: ElementRef;
  @Output() locationUpdatedEvent: EventEmitter<Location> = new EventEmitter();
  public isEnabledGeoLocator = false;
  private autocompleteInitialized = false;

  location: Location;
  loadingSpinner = false;

  public mapLoaded: boolean;
  public locationInput: UntypedFormControl = new UntypedFormControl();
  public zoom = 4;
  public center: google.maps.LatLngLiteral;
  public geolocationError: {
    code: number;
    message: string;
  };

  constructor(
      public httpClient: HttpClient,
      // eslint-disable-next-line @typescript-eslint/ban-types
      @Inject(PLATFORM_ID) private platformId: Object,
      private geoService: GeoService,
      private geocoder: MapGeocoder) { }

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId) && navigator) {
      AddressLocComponent.geoLocEnabled = (navigator.geolocation) ? true : false;
      this.isEnabledGeoLocator = AddressLocComponent.geoLocEnabled;
      google.maps.importLibrary("maps");
      google.maps.importLibrary("places");
    }
  }

  public onLocationInputFocus(): void {
    if (!this.autocompleteInitialized) {
      this.loadAutoCompleteFeature();
      this.autocompleteInitialized = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultLocation && changes.defaultLocation.currentValue) {
      const address: string[] = [];

      for (const [key, value] of Object.entries(changes.defaultLocation.currentValue)) {
        if (value && typeof value === 'string') {
          address.push(value);
        }
      }

      const fullAdress = address.join(', ');
      this.locationInput.patchValue(fullAdress);

      this.getAddressFromGeocoder(fullAdress).then(geoResults => {
        this.center = {
          lat: geoResults.lat,
          lng: geoResults.lng
        };
        this.locationInput.setValue(fullAdress);
        this.zoom = 6;

        this.location = new Location ({
          address: geoResults.address,
          latitude: geoResults.lat,
          longitude: geoResults.lng
        });

        this.locationUpdatedEvent.emit(this.location);
      });
    }
  }

  public setCurrentBrowserLocation(): void {
    navigator.geolocation.getCurrentPosition(position => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      this.zoom = 12;
      console.log(position);
    }, err => {
      console.log('gelocation disabled', err);
      this.geoService.getCurrentLocation(true).then((res: any) => {
        const conutryName =  res.countryData && res.countryData.name && res.countryData.name.common ? res.countryData.name.common : res.country;
        const region = res.countryData && res.countryData.region ? res.countryData.region : '';

        this.getAddressFromGeocoder(`${conutryName} ${region}`).then(geoResults => {
          this.center = {
            lat: geoResults.lat,
            lng: geoResults.lng
          };
          this.locationInput.setValue(geoResults.address);
          this.zoom = 6;

          this.location = new Location ({
            address: geoResults.address,
            latitude: geoResults.lat,
            longitude: geoResults.lng
          });

          this.locationUpdatedEvent.emit(this.location);
        });
      }, () => {
        this.geolocationError = {
          code: err.code,
          message: err.message
        };
        console.error('setCurrentBrowserLocation', err);
      });
    });
  }

  public moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }

  private getAddressFromGeocoder(addressString: string): Promise<any> {
    return new Promise(resolve => {
      this.geocoder.geocode({
        address: addressString
      }).subscribe(({results}) => {
        console.log(results);
        const mapAddressDetails: any = results.length ? results[0] : results;
        const address = mapAddressDetails.address_components[0]['long_name' || 'short_name'];
        const mapResult = {
          address,
          lat: mapAddressDetails.geometry.location.lat(),
          lng: mapAddressDetails.geometry.location.lng(),
        };
        resolve(mapResult);
      }, err => {
        console.log('geocode error', err);
      });
    });
  }

  private loadAutoCompleteFeature(): void {

    const autocomplete = new google.maps.places.Autocomplete(this.locationInputField.nativeElement, {});

    autocomplete.setFields([
      'address_components',
      'geometry',
      'icon',
      'name'
    ]);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        console.error('No details available for input:' + this.locationInput.value);
        return;
      } else {
        const address: string[] = [];

        for (const a of place.address_components) {
          const longName = a.long_name;
          address.push(longName);
        }

        const fullAdress = address.join(', ');

        this.getAddressFromGeocoder(fullAdress).then(geoResults => {
          this.center = {
            lat: geoResults.lat,
            lng: geoResults.lng
          };
          this.locationInput.setValue(fullAdress);
          this.zoom = 6;

          this.location = new Location ({
            address: geoResults.address,
            latitude: geoResults.lat,
            longitude: geoResults.lng
          });

          this.locationUpdatedEvent.emit(this.location);
        });

        return;
      }
    });
  }
}
