export const categoriesBase = [
    { type: 'news', typeLabel: 'News', category: 'animals', label: 'Animals' },
    { type: 'news', typeLabel: 'News', category: 'architecture', label: 'Architecture' },
    { type: 'news', typeLabel: 'News', category: 'art', label: 'Art' },
    { type: 'news', typeLabel: 'News', category: 'autos', label: 'Autos' },
    { type: 'news', typeLabel: 'News', category: 'business-finance', label: 'Business/Finance' },
    { type: 'news', typeLabel: 'News', category: 'cooking-food', label: 'Cooking/Food' },
    { type: 'news', typeLabel: 'News', category: 'crime', label: 'Crime' },
    { type: 'news', typeLabel: 'News', category: 'tragedies', label: 'Tragedies' },
    { type: 'news', typeLabel: 'News', category: 'education', label: 'Education' },
    { type: 'news', typeLabel: 'News', category: 'entertainment', label: 'Entertainment' },
    { type: 'news', typeLabel: 'News', category: 'environment', label: 'Environment' },
    { type: 'news', typeLabel: 'News', category: 'fashion', label: 'Fashion' },
    { type: 'news', typeLabel: 'News', category: 'fitness', label: 'Fitness' },
    { type: 'news', typeLabel: 'News', category: 'gaming', label: 'Gaming' },
    { type: 'news', typeLabel: 'News', category: 'gardening-farming', label: 'Gardening & Farming' },
    { type: 'news', typeLabel: 'News', category: 'gun-violence', label: 'Gun violence'},
    { type: 'news', typeLabel: 'News', category: 'health', label: 'Health' },
    { type: 'news', typeLabel: 'News', category: 'immigration', label: 'Immigration' },
    { type: 'news', typeLabel: 'News', category: 'industries', label: 'Industries' },
    { type: 'news', typeLabel: 'News', category: 'military', label: 'Military' },
    { type: 'news', typeLabel: 'News', category: 'music', label: 'Music' },
    { type: 'news', typeLabel: 'News', category: 'news-local', label: 'News - Local' },
    { type: 'news', typeLabel: 'News', category: 'news-state', label: 'News - State' },
    { type: 'news', typeLabel: 'News', category: 'news-us', label: 'News - US' },
    { type: 'news', typeLabel: 'News', category: 'news-world', label: 'News - World' },
    { type: 'news', typeLabel: 'News', category: 'nightlife', label: 'Nightlife' },
    { type: 'news', typeLabel: 'News', category: 'real-estate', label: 'Real Estate' },
    { type: 'news', typeLabel: 'News', category: 'religion', label: 'Religion' },
    { type: 'news', typeLabel: 'News', category: 'science', label: 'Science' },
    { type: 'news', typeLabel: 'News', category: 'sports', label: 'Sports' },
    { type: 'news', typeLabel: 'News', category: 'technology', label: 'Technology' },
    { type: 'news', typeLabel: 'News', category: 'theater', label: 'Theater' },
    { type: 'news', typeLabel: 'News', category: 'transportation', label: 'Transportation' },
    { type: 'news', typeLabel: 'News', category: 'travel', label: 'Travel' },
    { type: 'news', typeLabel: 'News', category: 'general', label: 'General' },
    { type: 'news', typeLabel: 'News', category: 'media', label: 'Media' },

    { type: 'opinion', typeLabel: 'Opinion', category: 'animals', label: 'Animals' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'architecture', label: 'Architecture' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'art', label: 'Art' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'autos', label: 'Autos' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'business-finance', label: 'Business/Finance' },
    { type: 'opinion', typeLabel: 'opinion', category: 'cooking-food', label: 'Cooking/Food' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'culture', label: 'Culture' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'economy', label: 'Economy' },
    { type: 'opinion', typeLabel: 'opinion', category: 'education', label: 'Education' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'entertainment', label: 'Entertainment' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'environment', label: 'Environment' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'fashion', label: 'Fashion' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'fitness', label: 'Fitness' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'gaming', label: 'Gaming' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'gardening-farming', label: 'Gardening & Farming' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'gun-violence', label: 'Gun violence'},
    { type: 'opinion', typeLabel: 'Opinion', category: 'health', label: 'Health' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'music', label: 'Music' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'politics-government', label: 'Politics/Government' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'real-estate', label: 'Real Estate' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'religion', label: 'Religion' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'relationships', label: 'Relationships' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'spirituality', label: 'Spirituality' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'sports', label: 'Sports' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'technology', label: 'Technology' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'theater', label: 'Theater' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'transportation', label: 'Transportation' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'travel', label: 'Travel' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'general', label: 'General' },
    { type: 'opinion', typeLabel: 'Opinion', category: 'media', label: 'Media' },

    { type: 'ideas', typeLabel: 'Ideas', category: 'architecture', label: 'Architecture' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'autos', label: 'Autos' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'business-finance', label: 'Business/Finance' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'economy', label: 'Economy' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'education', label: 'Education' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'environment', label: 'Environment' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'fitness', label: 'Fitness' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'gaming', label: 'Gaming' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'gardening-farming', label: 'Gardening & Farming' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'health', label: 'Health' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'home', label: 'Home' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'personal-growth', label: 'Personal Growth' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'politics-government', label: 'Politics/Government' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'science', label: 'Science' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'spirituality', label: 'Spirituality' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'technology', label: 'Technology' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'transportation', label: 'Transportation' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'general', label: 'General' },
    { type: 'ideas', typeLabel: 'Ideas', category: 'media', label: 'Media' },

    { type: 'commentary', typeLabel: 'Commentary', category: 'animals', label: 'Animals' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'business-finance', label: 'Business/Finance' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'comedy', label: 'Comedy' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'culture', label: 'Culture' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'economy', label: 'Economy' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'entertainment', label: 'Entertainment' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'environment', label: 'Environment' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'gaming', label: 'Gaming' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'music', label: 'Music' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'news-local', label: 'News – Local' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'news-state', label: 'News – State' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'news-us', label: 'News – US' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'news-world', label: 'News – World' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'nightlife', label: 'Nightlife' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'politics-government', label: 'Politics/Government' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'relationships', label: 'Relationships' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'spirituality', label: 'Spirituality' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'sports', label: 'Sports' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'technology', label: 'Technology' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'general', label: 'General' },
    { type: 'commentary', typeLabel: 'Commentary', category: 'media', label: 'Media' },

    { type: 'reviews', typeLabel: 'Reviews', category: 'architecture', label: 'Architecture' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'art', label: 'Art' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'autos', label: 'Autos' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'books', label: 'Books' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'cooking-food', label: 'Cooking/Food' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'entertainment', label: 'Entertainment' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'fashion', label: 'Fashion' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'gaming', label: 'Gaming' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'home', label: 'Home' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'music', label: 'Music' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'nightlife', label: 'Nightlife' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'technology', label: 'Technology' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'theater', label: 'Theater' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'travel', label: 'Travel' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'general', label: 'General' },
    { type: 'reviews', typeLabel: 'Reviews', category: 'media', label: 'Media' },

    { type: 'publicity', typeLabel: 'Publicity', category: 'autos', label: 'Autos' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'business-finance', label: 'Business/Finance' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'cooking-food', label: 'Cooking/Food' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'entertainment', label: 'Entertainment' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'fashion', label: 'Fashion' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'gaming', label: 'Gaming' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'health-fitness', label: 'Health & Fitness' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'home', label: 'Home' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'music', label: 'Music' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'real-estate', label: 'Real Estate' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'sports', label: 'Sports' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'technology', label: 'Technology' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'theater', label: 'Theater' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'travel', label: 'Travel' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'general', label: 'General' },
    { type: 'publicity', typeLabel: 'Publicity', category: 'media', label: 'Media' },

    { type: 'essays', typeLabel: 'Essays', category: 'architecture', label: 'Architecture' },
    { type: 'essays', typeLabel: 'Essays', category: 'art', label: 'Art' },
    { type: 'essays', typeLabel: 'Essays', category: 'books', label: 'Books' },
    { type: 'essays', typeLabel: 'Essays', category: 'business-finance', label: 'Business/Finance' },
    { type: 'essays', typeLabel: 'Essays', category: 'comedy', label: 'Comedy' },
    { type: 'essays', typeLabel: 'Essays', category: 'cooking-food', label: 'Cooking/Food' },
    { type: 'essays', typeLabel: 'Essays', category: 'culture', label: 'Culture' },
    { type: 'essays', typeLabel: 'Essays', category: 'entertainment', label: 'Entertainment' },
    { type: 'essays', typeLabel: 'Essays', category: 'environment', label: 'Environment' },
    { type: 'essays', typeLabel: 'Essays', category: 'fiction', label: 'Fiction' },
    { type: 'essays', typeLabel: 'Essays', category: 'fitness', label: 'Fitness' },
    { type: 'essays', typeLabel: 'Essays', category: 'gardening-farming', label: 'Gardening & Farming' },
    { type: 'essays', typeLabel: 'Essays', category: 'health', label: 'Health' },
    { type: 'essays', typeLabel: 'Essays', category: 'home', label: 'Home' },
    { type: 'essays', typeLabel: 'Essays', category: 'music', label: 'Music' },
    { type: 'essays', typeLabel: 'Essays', category: 'poetry', label: 'Poetry' },
    { type: 'essays', typeLabel: 'Essays', category: 'personal-growth', label: 'Personal Growth' },
    { type: 'essays', typeLabel: 'Essays', category: 'politics-government', label: 'Politics/Government' },
    { type: 'essays', typeLabel: 'Essays', category: 'relationships', label: 'Relationships' },
    { type: 'essays', typeLabel: 'Essays', category: 'romance', label: 'Romance' },
    { type: 'essays', typeLabel: 'Essays', category: 'religion', label: 'Religion' },
    { type: 'essays', typeLabel: 'Essays', category: 'science', label: 'Science' },
    { type: 'essays', typeLabel: 'Essays', category: 'spirituality', label: 'Spirituality' },
    { type: 'essays', typeLabel: 'Essays', category: 'sports', label: 'Sports' },
    { type: 'essays', typeLabel: 'Essays', category: 'travel', label: 'Travel' },
    { type: 'essays', typeLabel: 'Essays', category: 'general', label: 'General' },
    { type: 'essays', typeLabel: 'Essays', category: 'media', label: 'Media' },
/*
    { type: 'communities', typeLabel: 'Communities', category: 'architecture', label: 'Architecture' },
    { type: 'communities', typeLabel: 'Communities', category: 'art', label: 'Art' },
    { type: 'communities', typeLabel: 'Communities', category: 'autos', label: 'Autos' },
    { type: 'communities', typeLabel: 'Communities', category: 'business-finance', label: 'Business/Finance' },
    { type: 'communities', typeLabel: 'Communities', category: 'cooking-food', label: 'Cooking/Food' },
    { type: 'communities', typeLabel: 'Communities', category: 'culture', label: 'Culture' },
    { type: 'communities', typeLabel: 'Communities', category: 'education', label: 'Education' },
    { type: 'communities', typeLabel: 'Communities', category: 'entertainment', label: 'Entertainment' },
    { type: 'communities', typeLabel: 'Communities', category: 'environment', label: 'Environment' },
    { type: 'communities', typeLabel: 'Communities', category: 'fashion', label: 'Fashion' },
    { type: 'communities', typeLabel: 'Communities', category: 'fitness', label: 'Fitness' },
    { type: 'communities', typeLabel: 'Communities', category: 'gaming', label: 'Gaming' },
    { type: 'communities', typeLabel: 'Communities', category: 'gardening-farming', label: 'Gardening & Farming' },
    { type: 'communities', typeLabel: 'Communities', category: 'health', label: 'Health' },
    { type: 'communities', typeLabel: 'Communities', category: 'home', label: 'Home' },
    { type: 'communities', typeLabel: 'Communities', category: 'music', label: 'Music' },
    { type: 'communities', typeLabel: 'Communities', category: 'news-local', label: 'News - Local' },
    { type: 'communities', typeLabel: 'Communities', category: 'politics-government', label: 'Politics/Government' },
    { type: 'communities', typeLabel: 'Communities', category: 'religion', label: 'Religion' },
    { type: 'communities', typeLabel: 'Communities', category: 'science', label: 'Science' },
    { type: 'communities', typeLabel: 'Communities', category: 'sports', label: 'Sports' },
    { type: 'communities', typeLabel: 'Communities', category: 'technology', label: 'Technology' },
    { type: 'communities', typeLabel: 'Communities', category: 'theater', label: 'Theater' },
    { type: 'communities', typeLabel: 'Communities', category: 'travel', label: 'Travel' },
    { type: 'communities', typeLabel: 'Communities', category: 'general', label: 'General' },
    { type: 'communities', typeLabel: 'Communities', category: 'media', label: 'Media' },
*/
    { type: 'photography', typeLabel: 'Photography', category: 'animals', label: 'Animals' },
    { type: 'photography', typeLabel: 'Photography', category: 'architecture', label: 'Architecture' },
    { type: 'photography', typeLabel: 'Photography', category: 'autos', label: 'Autos' },
    { type: 'photography', typeLabel: 'Photography', category: 'cooking-food', label: 'Cooking/Food' },
    { type: 'photography', typeLabel: 'Photography', category: 'culture', label: 'Culture' },
    { type: 'photography', typeLabel: 'Photography', category: 'environment', label: 'Environment' },
    { type: 'photography', typeLabel: 'Photography', category: 'fashion', label: 'Fashion' },
    { type: 'photography', typeLabel: 'Photography', category: 'home', label: 'Home' },
    { type: 'photography', typeLabel: 'Photography', category: 'news', label: 'News' },
    { type: 'photography', typeLabel: 'Photography', category: 'personal', label: 'Personal' },
    { type: 'photography', typeLabel: 'Photography', category: 'sports', label: 'Sports' },
    { type: 'photography', typeLabel: 'Photography', category: 'travel', label: 'Travel' },
    { type: 'photography', typeLabel: 'Photography', category: 'general', label: 'General' },
    { type: 'photography', typeLabel: 'Photography', category: 'media', label: 'Media' },

    { type: 'tutorials', typeLabel: 'Tutorials', category: 'animals', label: 'Animals' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'art', label: 'Art' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'beauty', label: 'Beauty' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'cooking', label: 'Cookin' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'design', label: 'Design' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'engineering', label: 'Engineering' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'fashion', label: 'Fashion' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'fitness', label: 'Fitness' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'finance', label: 'Finance' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'health', label: 'Health' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'math', label: 'Math' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'music', label: 'Music' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'gaming', label: 'Gaming' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'technology', label: 'Technology' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'travel', label: 'Travel' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'photography', label: 'Photography' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'programming', label: 'Programming' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'pepairs', label: 'Repairs' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'sports', label: 'Sports' },
    { type: 'tutorials', typeLabel: 'Tutorials', category: 'writing', label: 'Writing' }
];
