import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule} from '@angular/router';
// Modules
import { AddressModule} from '../shared/address/address.module';
import { HeaderModule} from '../shared/header/header.module';
import { FooterModule} from '../shared/footer/footer.module';
import { InlineMsgModule} from '../shared/inline-msg/inline-msg.module';
import { StreamNavModule} from '../streams/stream-nav/stream-nav.module';
import { UploaderFieldModule} from '../shared/uploader-field/uploader-field.module';
import { ToggleSwitchModule} from '../shared/toggle-switch/toggle-switch.module';
import { SubscriptionsTableModule} from './subscriptions-table/subscriptions-table.module';
import { ActivityTableModule} from './activity-table/activiy-table.module';
import { ImageEditorModule} from '../shared/image-editor/image-editor.module';
import { TeamSubscriptionsTableModule } from './team-subscriptions-table/subscriptions-table.module';
// Services
import { NoteService} from '../shared/services/note_service';
import { StreamService} from '../shared/services/stream_service';
import { CacheService} from '../shared/services/cache_service';
import { UserService} from '../shared/services/user_service';
import { Util} from '../shared/util/util';

import { routes} from './user.routes';
import { UserComponent} from './user.component';
import { WordCounterDirective} from '../shared/validators/word-counter.directive';
import { CommonModule} from '@angular/common';

import { FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { faSync} from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare} from '@fortawesome/free-regular-svg-icons';
import { AlertMessageModule} from '../shared/alert-message/alert-message.module';
import { ConfirmationModalModule } from '../shared/confirmation-modal/confirmaton-modal.module';

@NgModule({
  declarations: [
    UserComponent,
    WordCounterDirective,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    AddressModule,
    HeaderModule,
    FooterModule,
    InlineMsgModule,
    StreamNavModule,
    UploaderFieldModule,
    ToggleSwitchModule,
    SubscriptionsTableModule,
    ActivityTableModule,
    FontAwesomeModule,
    ImageEditorModule,
    AlertMessageModule,
    ConfirmationModalModule,
    TeamSubscriptionsTableModule,
    ReactiveFormsModule
  ],
  providers: [
    Util,
    UserService,
    CacheService,
    StreamService,
    NoteService
  ],
  exports: [
    RouterModule,
    WordCounterDirective,
  ],
  bootstrap: []

})

export class UserModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faCheckSquare, faSquare);
  }
}
