import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {categoriesBase} from '../types/categories-base';


@Injectable()
export class CategoriesService {

    constructor(private http: HttpClient) { }

    getTypes() {
        const types: any[] = [];

        categoriesBase.map(cat => {
            types.push(cat.type);
        });

        return Array.from(new Set(types));
    }

    getCategories() {
        const categories: any[] = [];

        categoriesBase.map(cat => {
            categories.push(cat.category);
        });

        return Array.from(new Set(categories));
    }

    getCategoriesByType(typeName: string) {
        const results: any[] = [];

        categoriesBase.map(cat => {
            if (cat.type === typeName) {
                results.push({ category: cat.category, label: cat.label });
            }
        });

        return results;
    }

    getStructure() {
        const results: any[] = [];
        const typesSorted = this.getTypes().sort();

        typesSorted.map((type) => {
            results.push({
                type,
                typeLabel: this.getTypeLabel(type),
                categories: this.getCategoriesByType(type).sort((a, b) => (a.category > b.category) ? 1 : -1)
            });
        });

        return results;
    }

    getTypeLabel(typeName: string) {
        return categoriesBase.filter(c => c.type === typeName )[0]['typeLabel'];
    }

    getCategoryLabel(categoryName: string) {
        return categoriesBase.filter(c => c.category === categoryName)[0]['label'];
    }

    getCategoryData(type: string, categoryName: string) {
        return categoriesBase.filter(c => c.type === type && c.category === categoryName);
    }
}
