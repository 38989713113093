import {Injectable, Injector} from '@angular/core';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Event, NavigationError, Router} from '@angular/router';
import {errorsIgnoreList} from '../../shared/errors-handler/errors-ignore-list';

import {Observable, of, fromEvent, map, merge} from 'rxjs';

import * as StackTraceParser from 'error-stack-parser';

@Injectable()
export class ErrorsService {
    onlineOfflineStatus: Observable<boolean>;

    constructor(
        private injector: Injector,
        private http: HttpClient,
        private router: Router
    ) {
        this.router
            .events
            .subscribe((event: Event) => {
                if (event instanceof NavigationError) {
                    // Redirect to the ErrorComponent
                    this.log(event.error)
                        .subscribe((errorWithContext) => {
                            this.router.navigate(['/error'], { queryParams: errorWithContext });
                        });
                }
            });

        this.onlineOfflineStatus = merge(
            of(navigator.onLine),
            fromEvent(window, 'online')
              .pipe(map(() => true)),
            fromEvent(window, 'offline')
              .pipe(map(() => false))
        );
    }

    log(error) {
        // Log the error to the console
        console.error('ErrorService', error);

        // Send error to server
        return this.http.post<any>('/api/util/error/log', this.addContextInfo(error) );
    }

    addContextInfo(error) {
        const name = error.name || null;
        const appId = 'notd.io';
        const time = new Date().getTime();
        const id = `${appId}-${time}`;
        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const status = error.status || null;
        const message = error.message || error.message || error.toString();
        const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);

        return {
            msg: message,
            url,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            line_number: '',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            column_number: '',
            stack,
            status,
//            error  // DO NOT send becuse:  JSON.stringify cannot serialize cyclic structures.
        };
    }

    checkIgnoreList(error) {
        let result = false;
        errorsIgnoreList.map(ignoreError => {
            if (ignoreError.status === error.status && error.url.indexOf(ignoreError.url) >= 0) {
                result = true;
            }
        });

        return !result;
    }
}
