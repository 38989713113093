import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AdBlockCheckerComponent} from './adblock-checker.component';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';

@NgModule({
  declarations: [ AdBlockCheckerComponent ],
  imports: [
    RouterModule,
    CommonModule,
    FontAwesomeModule
  ],
  exports: [ AdBlockCheckerComponent ]
})

export class AdBlockCheckerModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faTimesCircle);
  }
}
