import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user_service';
import { Stream } from '../types/stream';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { CacheService } from '../services/cache_service';

@Component({
  selector: 'create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss']
})
export class CreateNoteComponent implements OnInit, OnDestroy {
  @Input() onlyButton = false;
  @Input() miniButton = false;
  public isLoggedIn = false;
  public showBlock = true;
  public disableNewNoteButton = false;
  public userStreams: Stream[] = [];
  public communityStreamMember = false;
  public loadingNoteCreator = false;

  private updatedStream: any;
  private userChange: any;
  private listCommunitiesSubs: any;
  private routerSubscription: any;

  constructor(
    private userService: UserService,
    private cacheService: CacheService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) protected platformId: Object
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.userService.isLoggedIn();
    if (isPlatformBrowser(this.platformId)) {
      this.showBlock = this.getBlockStatus();
    }

    this.userChange = this.userService.curUser$.subscribe(() => {
      this.isLoggedIn = this.userService.isLoggedIn();
    });

    this.routerSubscription = this.route.url.subscribe(() => {
      if (
        (this.router.url.indexOf('note') > 0 && this.route.snapshot.params.id && !this.route.snapshot.params.name) ||
        (this.router.url.indexOf('note') > 0 && !this.route.snapshot.params.id && !this.route.snapshot.params.name) ||
        (this.router.url.indexOf('new-note-guide') > 0)) {
        this.showBlock = false;
        this.onlyButton = false;
        this.miniButton = false;
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.userChange) {
      this.userChange.unsubscribe();
    }
  }

  public hideBlock() {
    this.cacheService.setCache('scnb', 'false');
    this.showBlock = false;
  }

  public createNewNote() {
    this.loadingNoteCreator = true;
    this.router.navigate(['/note']);
  }

  private getBlockStatus(): boolean {
    return this.cacheService.cache.get('scnb') && this.cacheService.cache.get('scnb') === 'false' ?
      false : !this.cacheService.cache.get('scnb') ? true : false;
  }
}
