import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {GeoService} from '../services/geographic_service';

import {CountriesMap} from '../types/countries_map';

export interface Country {
  countryName?: string;
  countryCode?: string;
}

@Component({
  selector: 'notd-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss']
})
export class CountrySelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() defaultCountryCode: string;
  @Input() required: boolean;
  @Output() countryEmitter: EventEmitter<Country> = new EventEmitter();

  countryList;
  selectedItem;
  countrySubs;

  constructor(
    private geoService: GeoService) { }


  ngOnInit() {

    this.countryList = null;
    this.selectedItem = null;

    this.countrySubs = this.geoService.getCountries().subscribe((list: CountriesMap) => {
        this.countryList = list;
        if (this.defaultCountryCode) {
          if (this.countryList[this.defaultCountryCode]) {
            this.selectedItem = this.defaultCountryCode;
          }
        }
      },
      err => {
        console.error('Error', err);
      });
  }

  selectItem(k) {

    if (k === '') {
      k = null;
    }

    this.selectedItem = k;

    if (k) {

      this.countryEmitter.emit({countryCode: k, countryName: this.countryList[k]});

      switch (k) {
        case 'US':
          break;
        default:
      }

    } else {

      this.countryEmitter.emit();

    }

  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.required === undefined) {
      this.required = false;
    }


    if (changes.defaultCountryCode.firstChange) {

      return;
    }

    this.selectedItem = changes.defaultCountryCode.currentValue;
  }

  ngOnDestroy() {
    if (this.countrySubs) {
      this.countrySubs.unsubscribe();
    }
  }
}
