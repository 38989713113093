import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms';
import {CustomValidators } from 'ng2-validation';
import {ContactService} from '../services/contact_service';

@Component({
  selector: 'notd-contact-form-modal',
  templateUrl: './contact-form-modal.component.html',
  styleUrls: ['./contact-form-modal.component.scss']
})

export class ContactFormModalComponent implements OnInit {
  contactForm: UntypedFormGroup;
  contactMessageSent = false;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private contactService: ContactService,
    private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.createContactForm();
  }

  createContactForm() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required ],
      email: ['', [ Validators.required, CustomValidators.email] ],
      subject: ['', Validators.required ],
      message: ['', Validators.required ]
    });
  }

  onContactSubmit() {
    if (this.contactForm.valid) {
      const message = this.contactForm.value;
      this.contactService.sendMessage(message).then(() => {
        this.contactForm.reset();
        this.contactMessageSent = true;
      });
    } else {
      this.validateAllFormFields(this.contactForm);
    }
  }

  openContactModal(content) {
    this.contactForm.reset();
    this.contactMessageSent = false;
    this.modalService.open(content, { windowClass: 'contact-us-modal' });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
