import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {LoginDirective} from './transact-auth.directive';

@NgModule({
    declarations: [ LoginDirective ],
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [ LoginDirective ]
})

export class LoginModule {}
