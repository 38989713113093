<div id="wraptest">
    <div #adBanner class="adBanner"></div>
</div>

<div class="block-wrapper" *ngIf="showModal && !cacheData">
    <div class="block-wrapper__modal">
        <header class="block-wrapper__modal__header">
            <fa-icon class="block-wrapper__modal__header__icon" [icon]="['far', 'times-circle']"></fa-icon>
            <h2 class="block-wrapper__modal__header__headline">You are using an ad blocker</h2>
        </header>
        <div class="block-wrapper__modal__body">
            <p>Your browser is blocking transact payments script.</p>
            <p>
                Transact.io respects your privacy, does not display advertisements, and does not sell your data. 
                To enable payment or login you will need to allow scripts from <a href="https://transact.io" title="A debit card for digital media. A la Carte..." target="_blank">Transact.io</a>.</p>
        </div>
        <footer class="block-wrapper__modal__footer">
            <a class="block-wrapper__modal__footer__button" (click)="closeModal()">Don't bother me again</a>
        </footer>
    </div>
</div>
