import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {FormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ContactFormModalComponent } from '../contact-form-modal/contact-form-modal.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public user = {};
  buildDate = '';
  verHash = '';
  contactForm: UntypedFormGroup;
  contactMessageSent = false;

  constructor(
    private modalService: NgbModal) {
      this.buildDate = new Date(environment.buildDate).toUTCString();

  }

  ngOnInit() {
    this.verHash = environment.gitHash;
  }

  openContactModal() {
    const modalRef = this.modalService.open(ContactFormModalComponent);
  }
}
