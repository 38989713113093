import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';


import {InlineMsgComponent} from './inline-msg.component';

@NgModule({
    declarations: [ InlineMsgComponent ],
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [ InlineMsgComponent ]
})

export class InlineMsgModule {}
