<div class="current-image" *ngIf="currentImg && !onlyModal" [ngClass]="[customClass]">
  <a data-cy="avatar-editor" (click)="editImageModal()" class="show-editor-modal">Click to edit</a>
  <img *ngIf="(!lazyLoading.enable && !lazyLoading.mainThumb) || (lazyLoading.enable && !lazyLoading.mainThumb && !lazyLoading.thumbs)" [src]="currentImg" [alt]="imgAlt" class="default-image">
  <img-lazy-loading
    class="lazy-loading-image"
    *ngIf="lazyLoading.enable && lazyLoading.mainThumb && lazyLoading.thumbs"
    [mainThumb]="lazyLoading.mainThumb"
    [thumbs]="lazyLoading.thumbs"></img-lazy-loading>
</div>

<ng-template #imageEditorModal let-c="close" let-d="dismiss">
  <section class="header">
    <a (click)="d('Cross click')" data-cy="close-modal" class="header__close-btn" title="Close">
      <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" height="512" viewBox="0 0 413.348 413.348" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"/></svg>
    </a>
    <h2 class="header__title">
      Edit image
    </h2>
  </section>
  <div class="body">
    <div [withClass]="classes.actions">
      <button raised ly-button class="notd__button--v2 button--green text-transform-capitalize select-file" (click)="_fileInput.click()">
        <span>Select File</span>
      </button>
      <button *ngIf="cropping.isLoaded" type="button" raised ly-button class="notd__button--v2 button--green text-transform-capitalize" (click)="cropping.clean(); originalImg = null">Clear</button>
      <!-- Hidden input -->
      <input #_fileInput type="file" id="file-input" (change)="cropping.selectInputEvent($event); getCropper(cropping);" accept="image/*" hidden>
    </div>
    <div *ngIf="cropping.isLoaded && !originalImg" class="action-buttons">
      <button (click)="cropping.setScale(1)" class="btn btn-outline-dark">1:1</button>
      <button (click)="cropping.zoomIn()" class="btn btn-outline-dark">zoom-in</button>
      <button (click)="cropping.zoomOut()" class="btn btn-outline-dark">zoom-out</button>
      <button (click)="cropping.center()" class="btn btn-outline-dark">center</button>
      <button (click)="cropping.fit()" class="btn btn-outline-dark">fit</button>
      <button (click)="cropping.fitToScreen()" class="btn btn-outline-dark">fit to screen</button>
      <button (click)="cropping.rotate(90)" class="btn btn-outline-dark">rotate</button>
    </div>
    <div class="original-img-wrapper" *ngIf="originalImg">
      <img [src]="originalImg.originalDataURL" />
    </div>
    <ly-img-cropper #cropping
                 [hidden]="originalImg"
                 [withClass]="classes.cropping"
                 [config]="myConfig"
                 [(scale)]="scale"
                 (dragover)="(cropping.isLoaded) ? cropping.clean() : ''"
                 (loaded)="onImageLoaded($event)"
                 (cropped)="onCrop($event)">
      <span>Drag and drop image</span>
    </ly-img-cropper>
    <div *ngIf="cropping.isLoaded && !originalImg" [className]="classes.range">
      <input type="range" [className]="classes.rangeInput" [attr.min]="cropping.minScale" max="1" [(ngModel)]="scale" step="any">
      <!--<button *ngIf="cropping.isLoaded" raised ly-button class="btn notd-primary-btn crop-btn" (click)="cropping.crop()" ly-button>Crop</button>-->
      <button type="button" style="margin-right: 5px;" raised ly-button class="notd__button--v2 button--green text-transform-capitalize save-btn" aria-label="Save" (click)="cropping.crop(); c('save');">Save</button>
      <button type="button" raised ly-button class="notd__button--v2 button--green text-transform-capitalize" aria-label="Close" (click)="c('close'); cropping.clean();">Close</button>
    </div>
    <!--<div class="cropped-image-area" *ngIf="cropping.isCropped">-->
      <!--<h2>Your final image:</h2>-->
      <!--<div class="img-place">-->
        <!--<img [src]="croppedImg.dataURL" [width]="myConfig.width" [height]="myConfig.height">-->
      <!--</div>-->
    <!--</div>-->
  </div>
  <!--<div class="modal-footer">-->
    <!--<button type="button" raised ly-button class="btn notd-secondary-btn" aria-label="Close" (click)="c('close'); cropping.clean();">Close</button>-->
    <!--<button type="button" raised ly-button class="btn notd-primary-btn" *ngIf="cropping.isCropped || originalImg" aria-label="Save" (click)="c('save'); cropping.clean();">Save</button>-->
  <!--</div>-->
</ng-template>

