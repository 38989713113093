import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {NotificationService} from './notification_service';

@Injectable()
export class PwaService {
  promptEvent: any = null;

  constructor(
    private swUpdate: SwUpdate,
    private notificationService: NotificationService,
  ) {
    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
    });

    swUpdate.versionUpdates.subscribe(() => {
      this.notificationService.notify({
        message: 'There is new update available for Notd',
        type: 'success',
        button: {
          label: 'Update',
          action: 'makePwaAppUpdate'
        }
      });
    });
  }
}
