import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbAccordionModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {CreateNoteComponent} from './create-note.component';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [ CreateNoteComponent ],
  imports: [
    RouterModule,
    CommonModule,
    NgbAccordionModule,
  ],
  exports: [ CreateNoteComponent ]
})

export class CreateNoteModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCircleNotch);
  }
}
