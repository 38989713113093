<div #notificationModalToggle="ngbDropdown" ngbDropdown (openChange)="onClickDropdown($event)">
  <button data-cy="notifications-btn" class="btn notifications-button" ngbDropdownToggle>
    <div data-cy="notifications-total" class="notifications-total" [ngClass]="{'big' : count > 99}">{{count > 99 ? '99+' : count}}</div>
    <fa-icon data-cy="notifications-header" [icon]="['fas', 'bell']"></fa-icon>
  </button>
  <div ngbDropdownMenu data-cy="notification-dropdown" class="notifications-dropdown-list">
    <section *ngIf="count > 0">
      <div class="header">
        <span class="title-name">Notifications</span>
        <div class="links">
          <a data-cy="notifications-show-link" title="See All" [routerLink]="['/user/notifications']">
            Show more
            <!--<fa-icon [icon]="['fas', 'link']"></fa-icon>-->
          </a>
          <a data-cy="notifications-delete-link" title="Delete All" (click)="deleteAllNotifications()">
            Delete All
            <!--<fa-icon [icon]="['fas', 'trash']"></fa-icon>-->
          </a>
        </div>
      </div>
      <ul data-cy="notifications-list" class="list">
        <li *ngFor="let notification of notifications; let i = index">
          <a [routerLink]="notification.url"
             title="{{notification.title}}"
             ngbDropdownToggle
             [ngClass]="{'image-loaded': notification['imageLoaded']}">
            <img class="thumbnail"
                 *ngIf="dropdownOpen"
                 (load)="onImageLoaded(i)"
                 src="{{notification.img || '/assets/logo-notd.png'}}">
            <fa-icon 
              class="loader"
              *ngIf="dropdownOpen"
              [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
            <span class="n-name" [innerHTML]="notification.message"></span>
          </a>
        </li>
      </ul>
    </section>
    <div
      (click)="toggleNotifications()"
      class="notifications__empty" *ngIf="!count">
      <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
      <p>You don't have any notifications yet!</p>
    </div>
    <!--<a class="bottom" title="Show more" [routerLink]="['/user/notifications']">Show more</a>-->
  </div>
</div>
