import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';

import {InlineMsgModule} from '../../shared/inline-msg/inline-msg.module';
import {FileUploadModule} from 'ng2-file-upload';

import {UploaderFieldComponent} from './uploader-field.component';

@NgModule({
    declarations: [ UploaderFieldComponent ],
    imports: [
        RouterModule,
        CommonModule,
        InlineMsgModule,
        NgbPopoverModule,
        FileUploadModule
    ],
    exports: [ UploaderFieldComponent ]
})

export class UploaderFieldModule {}
