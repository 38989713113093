import { Component, AfterViewInit, ViewChild, ElementRef, Input, OnInit, HostListener } from '@angular/core';
import { CacheService } from '../services/cache_service';

@Component({
  selector: 'app-ad-block-checker',
  templateUrl: './adblock-checker.component.html',
  styleUrls: ['./adblock-checker.component.scss']
})
export class AdBlockCheckerComponent implements OnInit, AfterViewInit {
    @ViewChild('adBanner', {static: false}) adBanner: ElementRef;
    @Input() timer = 1000;

    showModal = false;
    cacheData: boolean;
    constructor(private cacheService: CacheService) { }

    @HostListener('window:transactFileError', ['$event'])
    onTransactFileError() {
        console.log('onTransactFileError listener triggered');
        this.showAdBlockModal();
    }

    ngOnInit() {
        this.cacheData = this.cacheService.cache.get('hideAdBlockModal') || false;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const result = this.adBanner.nativeElement.offsetHeight === 0;

            if (result) {
                this.showAdBlockModal();
            }
        }, this.timer);
    }

    showAdBlockModal() {
        this.showModal = true;
    }

    closeModal() {
        this.cacheService.setCache('hideAdBlockModal', true);
        this.showModal = false;
    }
}
