export class SearchQuery {
    public query: string;
    public type: string;
    public tags: string;
    public size = 10;
    public fromOffset = 0;
    public fromDate: string;
    public mediaTag: string;
    public ratingMin: string;
    public distance: string;
    public location = {
        address: '',
        city: '',
        region: '',
        countryCode: '',
        latitude: '',
        longitude: ''
    };

    constructor(payload?: Partial<SearchQuery>) {
        for (const key in payload) {
            if (this.hasOwnProperty(key)) {
              this[key] = payload[key];
            }
        }
    }
}
