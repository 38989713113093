<section class="ios-home-screen__wrapper">
    <div class="ios-home-screen__wrapper__step">
        <img
            [src]="'assets/svg/share-button.svg'"
            class="ios-home-screen__wrapper__step__icon"
            alt="Share Button"/>
        <p class="ios-home-screen__wrapper__step__info">
            Tap the <strong>Share buttton</strong> in Safari's menu
        </p>
    </div>
    <div class="ios-home-screen__wrapper__step">
        <img
            [src]="'assets/svg/add-to-home-screen.svg'"
            class="ios-home-screen__wrapper__step__icon"
            alt="Share Button"/>
        <p class="ios-home-screen__wrapper__step__info">
            Tap the <strong>Add to home screen</strong> icon
        </p>
    </div>
    <p class="ios-home-screen__wrapper__close-popup">
        <a (click)="a2hs.closeA2hsPopup()">close popup</a>
    </p>
</section>