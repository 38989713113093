import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import {Country} from '../country-selector/country-selector.component';

import {NgForm} from '@angular/forms';
import {Location} from '../types/location';
import {AddressLocComponent} from './address-loc/address-loc.component';

@Component({
  selector: 'notd-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnChanges, AfterViewInit {
  @Input() address;
  @Input() simple: boolean;
  @Input() allowLocator;
  @Input() countryRequired;
  @Input() showManualFields;
  @Input() autocompleteDisplay;

  @Output() geoLocatorEmitter: EventEmitter<any> = new EventEmitter(); // GeoLocation
  @Output() countryEmitter: EventEmitter<Country> = new EventEmitter();
  @Output() formEmitter: EventEmitter<NgForm> = new EventEmitter();

  @ViewChild('addressForm', {static: false}) public addressForm: NgForm;

  countryCodeSelected: string;
  defaultLocation: Location; // GeoLocation

  public isEnabledGeoLocator: boolean = AddressLocComponent.geoLocEnabled;

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    this.formEmitter.emit(this.addressForm);

    if (this.showManualFields === undefined) {
      this.showManualFields = false;
    }

    if (this.countryRequired === undefined) {
      this.countryRequired = false;
    }

    if (this.allowLocator === undefined) {
      this.allowLocator = true;
    }

    if ((changes.address && changes.address.currentValue) || this.address) {
      this.countryCodeSelected = this.address.countryCode;
      this.setDefaultLocation();
      this.countryCodeSelected = changes.address.currentValue.countryCode;
    }
  }

  ngAfterViewInit() {
    this.formEmitter.emit(this.addressForm);
  }

  geoLocatorEvent(position: any) { // GeoLocation
    this.countryCodeSelected = null;
    this.address.city = null;
    this.cdRef.detectChanges();
    this.geoLocatorEmitter.emit(position);
    this.countryEmitter.emit();
  }

  countryEvent(country: Country) {
    if (country) {
      this.countryCodeSelected = country.countryCode;
    }

    this.defaultLocation = null;
    this.countryEmitter.emit(country);
  }

  locationUpdated(location: Location) {
    this.countryCodeSelected = null;
    if (this.address && this.address.city) {
      this.address.city = null;
    }
    this.geoLocatorEmitter.emit(location);
    this.countryEmitter.emit();
  }

  private setDefaultLocation() {
    this.defaultLocation = null;
    if (this.address.latitude === undefined || this.address.longitude === undefined) {
      return;
    }
    this.defaultLocation = new Location({});
    this.defaultLocation.latitude = this.address.latitude;
    this.defaultLocation.longitude = this.address.longitude;
    this.defaultLocation.city = this.address.city;
    this.defaultLocation.countryName = this.address.countryName;
    this.defaultLocation.region = this.address.region;
  }
}
