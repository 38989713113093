import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {AlertMessageComponent} from './alert-message.component';

@NgModule({
  declarations: [ AlertMessageComponent ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [ AlertMessageComponent ]
})

export class AlertMessageModule {}
