import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ToggleSwitchComponent} from './toggle-switch.component';

@NgModule({
    declarations: [
        ToggleSwitchComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [ ToggleSwitchComponent ]
})

export class ToggleSwitchModule {}
