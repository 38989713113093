import {Injectable, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../services/user_service';
import {User} from '../types/user';

@Injectable()
export class UserResolve  {

// eslint-disable-next-line @typescript-eslint/ban-types
constructor(@Inject(PLATFORM_ID) private platformId: Object,
            private userService: UserService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<User> {
    if (isPlatformServer(this.platformId)) {
      // on sever side user is never logged in
      return Promise.resolve(null);
    }
    return this.userService.getProfile(true).then((user) => {
        if (user) {
            return user;
        } else {
            this.router.navigate(['/']);
        }
      }, () => null)
      .catch(err => {
        console.log(err);
      });
  }
}
