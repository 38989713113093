import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ImgLazyLoadingComponent} from './image-lazy-loading.component';
import { ImageLoadedModule } from '../directives/loage-loaded.module';

@NgModule({
    declarations: [
      ImgLazyLoadingComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ImageLoadedModule
    ],
    exports: [ ImgLazyLoadingComponent ]
})

export class ImgLazyLoadingModule {}
