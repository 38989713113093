import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
/** Import Alyle UI */
import {LY_THEME, LyThemeModule} from '@alyle/ui';

import {LyButtonModule} from '@alyle/ui/button';
import {LyToolbarModule} from '@alyle/ui/toolbar';
import {LyImageCropperModule} from '@alyle/ui/image-cropper';

import {MinimaDark, MinimaLight} from '@alyle/ui/themes/minima';

import {ImageEditorComponent} from './image-editor.component';
import { ImgLazyLoadingModule } from '../image-lazy-loading/image-lazy-loading.module';

@NgModule({
  declarations: [ ImageEditorComponent ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LyThemeModule.setTheme('minima-light'),
    LyButtonModule,
    LyToolbarModule,
    LyImageCropperModule,
    ImgLazyLoadingModule
  ],
  providers: [
    {
      provide: LY_THEME, useClass: MinimaLight, multi: true
    },
    {
      provide: LY_THEME, useClass: MinimaDark, multi: true
    },
  ],
  exports: [ ImageEditorComponent ]
})

export class ImageEditorModule {}
