import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, map, of} from 'rxjs';

import {CountriesMap} from '../types/countries_map';
// import {MapsAPILoader} from '@agm/core';

import {CacheService} from './cache_service';

@Injectable()
export class GeoService {

  constructor(
      private http: HttpClient,
      // private mapsAPILoader: MapsAPILoader,
      private cacheService: CacheService) { }

  getAddressFromCord(lat: any, lng: any): Observable<any> {
      const params: HttpParams = new HttpParams()
          .set( 'lat', lat )
          .set( 'lng', lng );

      return this.http.get<any>(`api/geographic/geocoder/reverse/lat_lng` , { params });
  }


  // eslint-disable-next-line @typescript-eslint/ban-types
  getCountries(): Observable<CountriesMap | object> {
    const cache = this.cacheService.getCountryList();

    if (cache) {
      return of(cache);
    }

    return this.http.get('/api/geographic/countries/dict', {});
  }

  getStates(countryId: string): Observable<string[]> {
    const cache = this.cacheService.getCountryStates(countryId);

    if (cache) {
      return of(cache);
    }

    return this.http.get('/api/geographic/country/' + countryId + '/states', {})
      .pipe(
        map((resp: any) => {
          this.cacheService.setCountryStates(countryId, resp);
          return resp;
        })
      );
  }

  getCurrentLocation(rethrow: boolean): Promise<Response> {
    return this.http.get<any>(`/api/geographic/current/location`, {})
    .toPromise()
    .catch(err => {
      console.error('getCurrentLocation error', err);
      if (rethrow) {
        throw err;
      }
    });
  }
}
