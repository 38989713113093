import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GeoService } from '../services/geographic_service';
import { Util } from '../util/util';

@Component({
    selector: 'notd-partners-modal',
    templateUrl: './notd-partners-modal.component.html',
    styleUrls: ['./notd-partners-modal.component.scss']
})
export class NotdPartnersModalComponent implements OnInit {
    @ViewChild('notdPartnersModal', {static: false}) notdPartnersModal: ElementRef;
    @Input() buttonLabel: string;
    @Input() splitButtons: boolean;
    @Input() customReturnUrl: {
        global: {
            url: string;
            enable: boolean;
        };
        signup: {
            url: string;
            enable: boolean;
        };
        login: {
            url: string;
            enable: boolean;
        };
    };
    @Output() modalClosed: EventEmitter<boolean> = new EventEmitter();
    public inUS = false;
    private modalRef: any;

    constructor(
        private utilService: Util,
        private geoService: GeoService,
        private modalService: NgbModal,
        // eslint-disable-next-line @typescript-eslint/ban-types
        @Inject(PLATFORM_ID) protected platformId: Object) { }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.geoService.getCurrentLocation(true).then((location: any) => {
                this.inUS = location && location.country && location.country === 'US';
            }).catch(err=> {
                // fallback to US
                this.inUS = true;
            });
        }
    }

    public showModal(): void {
        this.modalRef = this.modalService.open(this.notdPartnersModal, { windowClass: 'notd-partners-modal', size: 'lg' });

        this.modalRef.result.then(result => {
            console.log(`Closed with:`, result);
            this.modalClosed.emit(true);
        }, reason => {
            console.log(`Dismissed`, reason);
            this.modalClosed.emit(true);
        })
        .catch(e => {
            console.log('error: ', e);
        });
    }

    public hideModal(): void {
        this.modalRef.close();
    }
}
