import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../services/user_service';

@Injectable()
export class LoginRedirectGuard  {

  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.getProfile();
  }

  getProfile(): Promise<boolean> {
    return this.userService.getProfile().then(user => {
      if (user && user.id !== '') {
        return true;
      } else {
        this.userService.login().then(() => false);
      }
    }).catch(err => false);
  }

}
