/* eslint-disable @typescript-eslint/naming-convention */
export enum StreamKind {
  Invalid = -1,
  Default = 0, // default channel that
  OpenCommunity = 1, // Open community anyone can join
  InviteOnlyCommunity = 2, // Invite required to join. Community is visible, searchable
  PrivateCommunity = 3, // Community is invisible to searching, can only be added by invite
  Free = 4, // FREE, for use only by ourselves for our own publicity, PR, info, etc
  InviteOnly = 5, // Stream where only owner can post, and is invite only
  Last = 6, // end of enum
}
