<section class="team-subscriptions-table">
  <section>
    <section
      *ngIf="loadingSubscriptions"
      class="team-subscriptions-table__loading">
      <span class="team-subscriptions-table__loading__spinner"></span>
      <p class="team-subscriptions-table__loading__label">Loading team subscriptions...</p>
    </section>
    <section
      *ngIf="!loadingSubscriptions && teamSubscriptions.length"
      class="team-subscriptions-table__table">
      <table class="notd__table">
        <thead>
          <tr>
            <th>
              <column-sorter [text]="'Start Date'" [list]="teamSubscriptions" [field]="'created'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Expires'" [list]="teamSubscriptions" [field]="'expires'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Team'" [list]="teamSubscriptions" [field]="'teamId'"></column-sorter>
            </th>
            <th class="row-costs">
              <column-sorter [text]="'Price'" [list]="teamSubscriptions" [field]="'price'"></column-sorter>
            </th>
            <th>
              <column-sorter [text]="'Period'" [list]="teamSubscriptions" [field]="'period'"></column-sorter>
            </th>
            <th class="row-extend label">
              Extend
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let team of teamSubscriptions">
            <td>
              {{team.created | date: 'MMM dd, yyyy'}}
            </td>
            <td [ngClass]="{'expired': team.expires}">
              {{team.expires | date: 'MMM dd, yyyy HH:mm'}}
            </td>
            <td>
              <a
                class="notd__link team--link"
                title="Show team details!"
                (click)="showTeamDetailsModal(team)">{{team.teamId}}</a>
              <span
                class="streams-counter"
                *ngIf="team.streams">Streams included: {{team.streams.length}}</span>
            </td>
            <td>
              <span>{{team.price / 100 | currency:'USD':'symbol'}}</span>
            </td>
            <td>
              <div 
                class="" 
                [ngSwitch]="team.period">
                <span *ngSwitchCase="0">Daily</span>
                <span *ngSwitchCase="1">Monthly</span>
                <span *ngSwitchCase="3">Annual</span>
              </div>
            </td>
            <td>
              <button
                class="notd__button--v2 button--black"
                (click)="showTeamDetailsModal(team)">
                Details
              </button>
              <button
                class="notd__button--v2 button--green"
                (click)="showTeamSubscriptionsModal(team)">
                <span>Extend</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section
      *ngIf="!loadingSubscriptions && teamSubscriptions.length === 0"
      class="team-subscriptions-table__no-results">
      <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
      <p>You don't have any team subscriptions yet.</p>
    </section>
  </section>
</section>

<ng-template #subscribeToTeamModal let-c="close" let-d="dismiss">
  <a (click)="d('Cross click')" data-cy="close-modal" class="subscribe-to-team-modal__close-btn" title="Close Modal">
      <svg enable-background="new 0 0 413.348 413.348" height="512" viewBox="0 0 413.348 413.348" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"/></svg>
  </a>
  <section
      *ngIf="loadingRedirection.status && loadingRedirection.planType === 'team'"
      [ngClass]="{'error': loadingRedirection.type === 'error'}"
      class="subscribe-to-team-modal__content__progress">
      <div class="subscribe-to-team-modal__content__progress__content" *ngIf="loadingRedirection.type !== 'error'">
          <span class="subscribe-to-team-modal__content__progress__content__spinner"></span>
          <p class="subscribe-to-team-modal__content__progress__content__label">Redirecting to the transact payment website...</p>
      </div>
      <div class="subscribe-to-team-modal__content__progress__content" *ngIf="loadingRedirection.type === 'error'">
          <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
          <p class="subscribe-to-team-modal__content__progress__content__label">An error occurred while redirecting to the payment page.</p>
      </div>
  </section>
  <section
    *ngIf="loadingRedirection.type === 'error'"
    class="subscribe-to-team-modal">
    <div class="subscribe-to-team-modal__content__buttons">
      <button
          type="button"
          class="notd__button--v2 button--black" (click)="d('Cross click');">Close</button>
    </div>
  </section>
</ng-template>


<ng-template #teamDetailsModal let-c="close" let-d="dismiss">
  <a (click)="d('Cross click')" data-cy="close-modal" class="team-details-modal__close-btn" title="Close Modal">
      <svg enable-background="new 0 0 413.348 413.348" height="512" viewBox="0 0 413.348 413.348" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"/></svg>
  </a>
  <h2 class="team-details-modal__title" *ngIf="teamModalDetails">
    Team {{teamModalDetails.teamId}} streams list.
  </h2>
  <h3
    class="team-details-modal__subtitle">As a team member, you have full access to the streams listed below.
  </h3>
  <section
    *ngIf="teamModalDetails && teamModalDetails.streams"
    class="team-details-modal__table-wrapper">
    <table>
      <thead>
          <tr>
              <th>Stream Name</th>
              <th>Notes</th>
              <th>Created</th>
          </tr>
      </thead>
      <tbody>
          <tr
              *ngFor="let stream of teamModalDetails.streams;"
              target="_blank"
              [title]="'Visit ' + stream.name"
              (click)="closeTeamDetailsModa()"
              [routerLink]="['/streams', stream.id, 'view', stream.indexedName || stream.name || stream.id]">
              <td class="note-name">{{stream.name}}</td>
              <td class="note-name">{{stream.numPosts}}</td>
              <td>{{stream['created'] | date: 'dd/MM/yyyy'}}</td>
          </tr>
      </tbody>
    </table>
  </section>
  <section class="team-details-modal__buttons">
    <button
      type="button"
      class="notd__button--v2 button--black" (click)="d('Cross click');">Close</button>
  </section>
</ng-template>

<ng-template #subscribeTeamPlansModal class="subscription-plans-modal" let-c="close" let-d="dismiss">
  <a (click)="d('Cross click')" data-cy="close-modal" class="subscription-plans-modal__close-btn" title="Close Team Subscripton Plans Modal">
      <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" height="512" viewBox="0 0 413.348 413.348" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"/></svg>
  </a>
<h2 class="subscription-plans-modal__title plans--team">
    Team Subscription Plans
</h2>
<div
    class="subscription-plans-modal__info">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 496.158 496.158" style="enable-background:new 0 0 496.158 496.158;" xml:space="preserve">
        <path style="fill:#25B7D3;" d="M496.158,248.085c0-137.022-111.069-248.082-248.075-248.082C111.07,0.003,0,111.063,0,248.085
            c0,137.001,111.07,248.07,248.083,248.07C385.089,496.155,496.158,385.086,496.158,248.085z"/>
        <g>
            <path style="fill:#FFFFFF;" d="M315.249,359.555c-1.387-2.032-4.048-2.755-6.27-1.702c-24.582,11.637-52.482,23.94-57.958,25.015
                c-0.138-0.123-0.357-0.348-0.644-0.737c-0.742-1.005-1.103-2.318-1.103-4.015c0-13.905,10.495-56.205,31.192-125.719
                c17.451-58.406,19.469-70.499,19.469-74.514c0-6.198-2.373-11.435-6.865-15.146c-4.267-3.519-10.229-5.302-17.719-5.302
                c-12.459,0-26.899,4.73-44.146,14.461c-16.713,9.433-35.352,25.41-55.396,47.487c-1.569,1.729-1.733,4.314-0.395,6.228
                c1.34,1.915,3.825,2.644,5.986,1.764c7.037-2.872,42.402-17.359,47.557-20.597c4.221-2.646,7.875-3.989,10.861-3.989
                c0.107,0,0.199,0.004,0.276,0.01c0.036,0.198,0.07,0.5,0.07,0.933c0,3.047-0.627,6.654-1.856,10.703
                c-30.136,97.641-44.785,157.498-44.785,182.994c0,8.998,2.501,16.242,7.432,21.528c5.025,5.393,11.803,8.127,20.146,8.127
                c8.891,0,19.712-3.714,33.08-11.354c12.936-7.392,32.68-23.653,60.363-49.717C316.337,364.326,316.636,361.587,315.249,359.555z"/>
            <path style="fill:#FFFFFF;" d="M314.282,76.672c-4.925-5.041-11.227-7.597-18.729-7.597c-9.34,0-17.475,3.691-24.176,10.971
                c-6.594,7.16-9.938,15.946-9.938,26.113c0,8.033,2.463,14.69,7.32,19.785c4.922,5.172,11.139,7.794,18.476,7.794
                c8.958,0,17.049-3.898,24.047-11.586c6.876-7.553,10.363-16.433,10.363-26.393C321.646,88.105,319.169,81.684,314.282,76.672z"/>
        </g>
    </svg>
    <p>
      Subscribe to team and get access to team streams content.
    </p>
</div>
<div 
    class="subscription-plans-modal__plans-wrapper"
    *ngIf="!modalTeamPlansObject.selectedPlan">
    <div
        class="subscription-plans-modal__plan plan--team"
        *ngFor="let plan of modalTeamPlansObject.plans">
        <span class="subscription-plans-modal__plan__price">{{ plan.price / 100 | currency:'USD':'symbol':'1.2-2' }}</span>
        <div class="subscription-plans-modal__plan__type" [ngSwitch]="plan.period">
            <span *ngSwitchCase="1">per Day</span>
            <span *ngSwitchCase="3">per Month</span>
            <span *ngSwitchCase="4">per Year</span>
        </div>
        <button
            class="subscription-plans-modal__plan__subscribe-button notd__button--v2"
            (click)="subscribeToTeamPlan(plan)"
            [ngClass]="{'renew-sub' : plan.expired, 'button--black': !plan.expired}">
            {{ plan.expired ? 'Renew Subscription' : 'Subscribe' }}
        </button>
        <span class="subscription-plans-modal__plan__label-expired" *ngIf="plan.expired">
            <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
            Expired on {{plan.expiresDate | date: 'dd/MM/yyyy'}}
        </span>
    </div>
</div>
<div class="subscription-plans-modal__selected-plan"
    *ngIf="modalTeamPlansObject.selectedPlan">
    <span 
        class="subscription-plans-modal__selected-plan__type" 
        [ngSwitch]="modalTeamPlansObject.selectedPlan.plan.periodStr">
        <span *ngSwitchCase="'MONTH'">Monthly</span>
        <span *ngSwitchCase="'YEAR'">Annual</span>
        <span *ngSwitchCase="'DAY'">Daily</span>
    </span>
    <span class="subscription-plans-modal__selected-plan__label">Subscription Plan</span>
    <div class="subscription-plans-modal__selected-plan__price-wrapper">
        <span class="subscription-plans-modal__selected-plan__price-wrapper__price">{{ modalTeamPlansObject.selectedPlan.plan.price / 100 | currency:'USD':'symbol':'1.2-2' }}</span>
        <div class="subscription-plans-modal__selected-plan__price-wrapper__per" [ngSwitch]="modalTeamPlansObject.selectedPlan.plan.period">
            <span *ngSwitchCase="1">per Day</span>
            <span *ngSwitchCase="3">per Month</span>
            <span *ngSwitchCase="4">per Year</span>
        </div>
    </div>
    <button
        *ngIf="showGoBackButton"
        class="subscription-plans-modal__selected-plan__change-plan-btn notd__button--v2 button--green"
        (click)="modalTeamPlansObject.selectedPlan = ''; loadingRedirection.status = false; loadingRedirection.type = ''">
        Go back
    </button>
</div>
<section
    *ngIf="loadingRedirection.status && loadingRedirection.planType === 'team'"
    [ngClass]="{'error': loadingRedirection.type === 'error'}"
    class="subscription-plans-modal__selected-plan__loading">
    <div class="subscription-plans-modal__selected-plan__loading__content" *ngIf="loadingRedirection.type !== 'error'">
        <span class="subscription-plans-modal__selected-plan__loading__content__spinner"></span>
        <p class="subscription-plans-modal__selected-plan__loading__content__label">Redirecting to transact payment website...</p>
    </div>
    <div class="subscription-plans-modal__selected-plan__loading__content" *ngIf="loadingRedirection.type === 'error'">
        <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
        <p class="subscription-plans-modal__selected-plan__loading__content__label">An error occurred while redirecting to the payment page.</p>
    </div>
</section>
<section
    class="subscription-plans-modal__transact-wrapper team-plans-wrapper">
    <p class="payment-content-wrapper error">
    <fa-icon [icon]="['far', 'times-circle']"></fa-icon>
    <span class="message">
        Error while connecting to Transact.io. Please disable your adblock and try again. 
        <a (click)="reloadPage()" class="reload-page">Reload the page</a>.
    </span>
    </p>
</section>
</ng-template>