import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {StreamSubscribeModule} from '../../streams/stream-subscribe/stream-subscribe.module';
import {ColumnSorterModule} from '../../shared/column-sorter/column-sorter.module';

import {TeamSubscriptionsTableComponent} from './subscriptions-table.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faSync} from '@fortawesome/free-solid-svg-icons';

@NgModule({
    declarations: [
      TeamSubscriptionsTableComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        RouterModule,
        StreamSubscribeModule,
        ColumnSorterModule,
        FontAwesomeModule
    ],
    exports: [ TeamSubscriptionsTableComponent ]
})

export class TeamSubscriptionsTableModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync);
  }
}
