import {UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

import {Directive, EventEmitter, Input, Output} from '@angular/core';


@Directive({
  selector: '[trimText][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: TrimTextDirective,
      multi: true
    }
  ]
})

export class TrimTextDirective implements Validator {

  @Input() trimTrail;
  @Output() textTrimmedEvent: EventEmitter<any> = new EventEmitter();

  validator: ValidatorFn;

  constructor() {
    this.validator = this.trimValidator();
  }

  validate(c: UntypedFormControl) {

    return this.validator(c);

  }

  trimValidator(): ValidatorFn {

    return (c: UntypedFormControl) => {

      const modelValue = c.value || '';


      if (modelValue.startsWith(' ')) {

         return {
          trimValidator: {
            value: false
          }
        };

      }

      if (this.trimTrail === 'true' && modelValue.endsWith(' ')) {

         return {
          trimValidator: {
            value: false
          }
        };

      }

      this.textTrimmedEvent.emit(modelValue.trim());

      return null; // IS VALID

    };

  }
}

