<div class="form-group">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 notd-filter-element">
      <select data-cy="activity-days-selector" class="form-control" [(ngModel)]="selectedPeriod" (ngModelChange)="refresh()">
        <option *ngFor="let days of [7, 15, 30, 365]" [value]="days" selected = "selected" i18n="@@GLOBAL-LAST-DAYS">Last {{days}} days</option>
      </select>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 notd-filter-element">
      <select data-cy="activity-type-selector" class="form-control" [(ngModel)]="selectedType" (ngModelChange)="refresh()">
        <option [value]="-1" selected = "selected" i18n="@@ACTIVITY-TABLE-001">All types</option>
        <option [value]="enumType.SINGLE_POST" i18n="@@ACTIVITY-TABLE-002">Single subscription</option>
        <option [value]="enumType.CHANNEL_SUBSCRIPTION" i18n="@@ACTIVITY-TABLE-003">Stream subscription</option>
      </select>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 notd-filter-element">
      <select data-cy="activity-payment-selector" class="form-control" [(ngModel)]="selectedState" (ngModelChange)="refresh()">
        <option [value]="enumState.DONE" selected i18n="@@ACTIVITY-TABLE-004">Paid</option>
        <option [value]="enumState.CANCELED" i18n="@@ACTIVITY-TABLE-005">Canceled</option>
        <option [value]="enumState.REFUNDED" i18n="@@ACTIVITY-TABLE-006">Refunded</option>
      </select>
    </div>
  </div>
</div>
<div *ngIf="myActivity.length > 0;then activity_content else noactivity_content"></div>
<ng-template #activity_content>
  <div class="notd-table">
    <div class="row notd-table-header">
      <div class="col-2">
        <column-sorter i18n-text="@@ACTIVITY-TABLE-007" text = "Date" [list] = "myActivity" [field] = "'created'"></column-sorter>
      </div>
      <div class="col-3">
        <column-sorter i18n-text="@@ACTIVITY-TABLE-008" text = "Title" [list] = "myActivity" [field] = "'_noteName'"></column-sorter>
      </div>
      <div class="col-2">
        <column-sorter i18n-text="@@ACTIVITY-TABLE-009" text = "Type" [list] = "myActivity" [field] = "'payType'"></column-sorter>
      </div>
      <div class="col-3">
        <column-sorter i18n-text="@@ACTIVITY-TABLE-010" text = "Stream" [list] = "myActivity" [field] = "'_streamName'"></column-sorter>
      </div>
      <div class="col-2">
        <column-sorter i18n-text="@@ACTIVITY-TABLE-011" text = "Cost" [list] = "myActivity" [field] = "'price'"></column-sorter>
      </div>
    </div>
    <div class="row notd-table-row" *ngFor="let item of myActivity">
      <div class="col-2">{{item.created | date: 'MMM dd, yyyy'}}</div>
      <div *ngIf="!item._noteName" class="col-3">
        <fa-icon *ngIf="!item._streamNotFound" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
        <span *ngIf="item._streamNotFound" i18n="@@GLOBAL-NOT-FOUND">Not found</span>
      </div>
      <div *ngIf="item._noteName" class="col-3 text-left">{{item._noteName}}</div>
      <div class="col-2" *ngIf="item.payType === enumType.SINGLE_POST" i18n="@@ACTIVITY-TABLE-012">Single</div>
      <div class="col-2" *ngIf="item.payType === enumType.CHANNEL_SUBSCRIPTION" i18n="@@ACTIVITY-TABLE-013">Stream</div>
      <div *ngIf="!item._streamName" class="col-3">
        <fa-icon *ngIf="!item._streamNotFound" [icon]="['fas', 'sync']" [spin]="true"></fa-icon>
        <span *ngIf="item._streamNotFound" i18n="@@GLOBAL-NOT-FOUND">Not found</span>
      </div>
      <div *ngIf="item._streamName" class="col-3 text-left">
        <a [routerLink]="item._owned?['/user', item.userId, 'streams', item.channelId, item._streamName]:['/streams', item.channelId, 'view', item._streamName]">
          {{item._streamName}}
        </a>
      </div>
      <div class="col-2 text-right">{{item.price/100 | currency:'USD':'symbol'}}</div>
    </div>
  </div>
</ng-template>
<ng-template #noactivity_content>
  <div class="col-12 text-center" *ngIf="loadingActivity">
    <fa-icon [icon]="['fas', 'sync']" [spin]="true" [size]="'lg'"></fa-icon>
  </div>
  <div class="col-12 text-center" *ngIf="!loadingActivity">
    NO ACTIVITY ACCORDING TO FILTER
  </div>
</ng-template>
