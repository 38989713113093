<section class="footer">
  <div class="notd__grid wrapper--centered">
    <ul>
      <li>
        <a data-cy="sitemap" [routerLink]="['/sitemap']">Sitemap</a>
      </li>
      <!-- <li>
        <a data-cy="about-footer" [routerLink]="['/about']">About</a>
      </li> -->
      <li>
        <a data-cy="why-publish-footer" class="why-publish-footer" [routerLink]="['/why-publish']" title="Why you should publish on Notd.io">Why Publish</a>
      </li>
      <li>
        <a [routerLink]="['/how-notd-works']">How Notd Works</a>
      </li>
      <!-- <li>
        <a class="promo-footer" [routerLink]="['/promotion']">Promotion</a>
      </li> -->
      <li>
        <a data-cy="support-footer" class="support-footer" (click)="openContactModal()" title="support @ notd" >Support</a>
      </li>
      <li>
        <a data-cy="faq-footer" class="faq-footer" [routerLink]="['/faq']" title="Frequently Asked Questions">FAQ</a>
      </li>
      <li>
        <a data-cy="terms-footer" class="terms-footer" [routerLink]="['/terms']">Terms</a>
      </li>
      <li>
        <a data-cy="privacy-footer" class="privacy-footer" [routerLink]="['/privacy-policy']">Privacy Policy</a>
      </li>
    </ul>
    <div class="footer__info">
      <div class="footer__info__social-links">
        <a data-cy="twitter-footer" href="https://twitter.com/notd_io" target="_blank" title="Follow us on twitter">
          <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
        </a>
        <a data-cy="support-icon-footer" (click)="openContactModal()" title="support @ notd">
          <fa-icon [icon]="['fas', 'envelope']"></fa-icon>
        </a>
      </div>
      <div class="footer__info__copyrights">
        &copy; 2024 News of the Day, Inc., a California Benefit Corporation, based in Santa Barbara, CA
      </div>
      <p class="footer__info__build" data-cy="build-date" *ngIf="buildDate">
        Updated: {{buildDate | date: 'longDate'}}
        Ver: {{ verHash }}
      </p>
    </div>
  </div>
</section>

<notd-partners-modal
  #notdPartnersModal
  [splitButtons]="true"
  [customReturnUrl]="{signup: {enable: true, url: 'new-note-guide'}, login: {enable: true, url: 'new-note-guide'}}"
  [buttonLabel]="'Sign-up or Login'"></notd-partners-modal>
