import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ConfirmationModalModule} from '../confirmation-modal/confirmaton-modal.module';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faSync, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

import {NotdPartnersModalComponent} from './notd-partners-modal.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { LoginModule } from '../transact-auth/transact-auth.module';

@NgModule({
    declarations: [ NotdPartnersModalComponent ],
    imports: [
        RouterModule,
        CommonModule,
        ConfirmationModalModule,
        FontAwesomeModule,
        LoginModule
    ],
    providers: [
        NgbActiveModal
    ],
    exports: [ NotdPartnersModalComponent ]
})

export class NotdPartnersModalModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faSync, faTimesCircle);
    }
}
