import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {NoDbClickDirective} from './no-db-click.directive';

@NgModule({
  declarations: [ NoDbClickDirective ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [ NoDbClickDirective ]
})

export class NoDbClickModule {}
