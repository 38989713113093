import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, publish, refCount} from 'rxjs';


@Injectable()
export class NotificationService {
    private _notification: BehaviorSubject<any> = new BehaviorSubject(null);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    notification$: Observable<any> = this._notification.asObservable().pipe(publish(),refCount());

    notify(message) {
        this._notification.next(message);
        setTimeout(() => this._notification.next(null), 15000);
    }

}
