<section class="img-lazy-wrapper">
    <div class="img-lazy-wrapper__preview"
        *ngIf="thumbPreview">
        <img loading="lazy"
            [src]="thumbPreview" [alt]="title"/>
    </div>
    <div class="img-lazy-wrapper__image">
        <a class="img-lazy-wrapper__image__link"
            *ngIf="url && thumbs && thumbDefault"
            [routerLink]="url"
            [title]="title">
            <img
                imageLoaded
                loading="lazy"
                [srcset]="srcSet"
                [src]="thumbDefault"
                [alt]="title"
                [sizes]="sizes" />
        </a>
        <img
            *ngIf="!url && thumbs && thumbDefault"
            class="img-lazy-wrapper__image__pic"
            imageLoaded
            loading="lazy"
            [srcset]="srcSet"
            [src]="thumbDefault"
            [alt]="title"
            [sizes]="sizes" />
    </div>
</section>