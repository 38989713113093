import * as Debug from 'debug';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { getAuthToken, setAuthToken } from '../../interceptors/api.interceptor';
import { UserService} from '../services/user_service';
const debug = Debug('notd:shared:AuthGuard');

@Injectable()
export class AuthGuard  {

  constructor(
    private userService: UserService,
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isLoggedIn = this.userService.isLoggedIn();

    return new Promise(resolve => {
      if (
        !isLoggedIn &&
        this.userService.getParameterByName('state') &&
        this.userService.getParameterByName('code') &&
        !getAuthToken()) {
          debug('runOAuth');
          this.userService.runOAuth().then(response => {
            if (!response || !response.headers) {
              resolve(false);
              return;
            }

            const token = response.headers.get('Notd-Auth');

            if (!token) {
              resolve(false);
              return;
            }
            debug('got token', token);
            setAuthToken(token);

            return this.userService.getProfile()
              .then(() => resolve(true))
              .catch(err => resolve(false));
          });
      } else if (!isLoggedIn) {
        this.router.navigate(['/']);
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }
}
