import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../shared/services/user_service';
import {StreamService} from '../../shared/services/stream_service';
import {Payment, PaymentState, PaymentType} from '../../shared/types/payment';
import {NoteService} from '../../shared/services/note_service';

@Component({
  selector: 'activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss']
})

export class ActivityTableComponent implements OnInit, OnDestroy {

  myActivity: any = [];
  loadingActivity = false;
  selectedPeriod;
  selectedType: PaymentType;
  selectedState: PaymentState;
  enumType: typeof PaymentType = PaymentType;
  enumState: typeof PaymentState = PaymentState;

  timeout;
  activity$;

  constructor(
    private userService: UserService,
    private streamService: StreamService,
    private noteService: NoteService) {}

  ngOnInit() {
    this.selectedPeriod = 7;
    this.selectedType = 0;
    this.selectedState = PaymentState.DONE;
    this.loadingActivity = false;

    this.refresh();
  }

  public setOwnedStream(item: any): void {
    this.streamService.getUserStreams(true).then((userStreams: any) => {
      const myStream = userStreams.filter(us => item.channelId === us.id);
      if (myStream.length > 0) {
          item._owned = true;
          item.userId = myStream[0].userId;
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.activity$) {
      this.activity$.unsubscribe();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  refresh() {
    if (this.activity$) {
      this.activity$.unsubscribe();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.loadingActivity = true;

    this.timeout = setTimeout(() => {

      this.myActivity = [];

      this.activity$ = this.userService.getActivityList(this.getTimeFromDays(this.selectedPeriod)).subscribe(

        (purchases: Payment[]) => {

          if (!purchases) {

            this.loadingActivity = false;
            return;
          }

          // this.myActivity = purchases.filter(s => s.channelId);

          purchases = purchases.filter(s => s.accounted === 1);

          purchases = purchases.filter(s => s.state === +this.selectedState);

          if (+this.selectedType !== -1) {

            this.myActivity = purchases.filter(s => s.payType === +this.selectedType);

          } else {

            this.myActivity = purchases;

          }

          this.loadingActivity = false;

          this.myActivity.map(item => {


            if (item.channelId) {

              this.streamService.getStreamInfoObservable(item.channelId, true).subscribe(
                streamInfo => {
                  item._streamName = streamInfo.name;
                  this.setOwnedStream(item);
                  if (!item.postId) {
                    item._noteName = streamInfo.name;
                  }
                },
                err => {
                  if (err.status === 404) {
                    item._streamNotFound = true;
                  } else {
                    console.log('Error', err);
                  }
                }
              );
            } else {
              item._streamName = '?';
              if (!item.postId) {
                item._noteName = '?';
              }
            }

            if (item.postId) {
              this.noteService.getNoteInfo(item.postId, false).then(
                postInfo => {
                  item._noteName = postInfo.name;
                },
                err => {
                  console.log('Error', err);
                }
              );
            }
          });
        },
        err => {

          console.log('Error', err);
          this.loadingActivity = false;

        }
      );

    }, 100);

  }

  private getTimeFromDays(days = 7): number {
    const d = new Date();
    d.setDate(d.getDate() - this.selectedPeriod);
    return d.getTime();
  }
}
