import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user_service';

@Component({
  selector: 'user-earnings-counter',
  templateUrl: './user-earnings-counter.component.html',
  styleUrls: ['./user-earnings-counter.scss']
})
export class UserEarningsCounterComponent implements OnInit {
  @Input() isLoggedIn: boolean;

  toggle = false;
  show = false;
  userData: any;
  userFunds = 0;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    if (!this.userService.isLoggedIn()) {
      return;
    } else {
      this.userService.getProfile().then(user => {
        this.userData = user;
        this.userFunds = (this.userData.funds && this.userData.funds['PROD']) ? this.userData.funds.PROD / 10000.0 : 0;
        this.show = this.userFunds > 0 ? true : false;
      });
    }
  }

  toggleCounter() {
    this.toggle = !this.toggle;
  }
}
