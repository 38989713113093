<div 
  class="alert-wrapper"
  *ngIf="alert && alert.message && alert.type"
  [ngClass]="{'position--floating': alert.floating ,'message--success': alert.type === 'success', 'message--danger': alert.type === 'danger', 'message--error': alert.type === 'error'}">
  <div class="alert-wrapper__icon">
    <svg *ngIf="alert.type === 'success'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <g>
        <g>
          <path d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341
            c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659c119.295,0,216.341,97.046,216.341,216.341
            S375.275,472.341,256,472.341z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M373.451,166.965c-8.071-7.337-20.623-6.762-27.999,1.348L224.491,301.509l-58.438-59.409
            c-7.714-7.813-20.246-7.932-28.039-0.238c-7.813,7.674-7.932,20.226-0.238,28.039l73.151,74.361
            c3.748,3.807,8.824,5.929,14.138,5.929c0.119,0,0.258,0,0.377,0.02c5.473-0.119,10.629-2.459,14.297-6.504l135.059-148.722
            C382.156,186.854,381.561,174.322,373.451,166.965z"/>
        </g>
      </g>
   </svg>
   <svg *ngIf="alert.type === 'error'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 511.995 511.995" style="enable-background:new 0 0 511.995 511.995;" xml:space="preserve">
      <g>
        <g>
          <path d="M437.126,74.939c-99.826-99.826-262.307-99.826-362.133,0C26.637,123.314,0,187.617,0,256.005
            s26.637,132.691,74.993,181.047c49.923,49.923,115.495,74.874,181.066,74.874s131.144-24.951,181.066-74.874
            C536.951,337.226,536.951,174.784,437.126,74.939z M409.08,409.006c-84.375,84.375-221.667,84.375-306.042,0
            c-40.858-40.858-63.37-95.204-63.37-153.001s22.512-112.143,63.37-153.021c84.375-84.375,221.667-84.355,306.042,0
            C493.435,187.359,493.435,324.651,409.08,409.006z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M341.525,310.827l-56.151-56.071l56.151-56.071c7.735-7.735,7.735-20.29,0.02-28.046
            c-7.755-7.775-20.31-7.755-28.065-0.02l-56.19,56.111l-56.19-56.111c-7.755-7.735-20.31-7.755-28.065,0.02
            c-7.735,7.755-7.735,20.31,0.02,28.046l56.151,56.071l-56.151,56.071c-7.755,7.735-7.755,20.29-0.02,28.046
            c3.868,3.887,8.965,5.811,14.043,5.811s10.155-1.944,14.023-5.792l56.19-56.111l56.19,56.111
            c3.868,3.868,8.945,5.792,14.023,5.792c5.078,0,10.175-1.944,14.043-5.811C349.28,331.117,349.28,318.562,341.525,310.827z"/>
        </g>
      </g>
    </svg>
  </div>
  <div class="alert-wrapper__message-container">
    <h2 class="alert-wrapper__message-container__title" [ngSwitch]="alert.type">
      <span *ngSwitchCase="'success'">Success</span>
      <span *ngSwitchCase="'error'">Error</span>
      <span *ngSwitchCase="'danger'">Issue</span>
    </h2>
    <span class="alert-wrapper__message-container__text">
      {{alert.message}}
    </span>
  </div>
  <a class="alert-wrapper__close-button" (click)="closeMessage()">Close</a>
</div>