import {Directive, HostBinding, HostListener, OnInit, Input, ViewChild} from '@angular/core';
import {UserService} from '../services/user_service';
import {CacheService} from '../services/cache_service';
import { NotdPartnersModalComponent } from '../notd-partners-modal/notd-partners-modal.component';
@Directive({
  selector: `[transact-auth]`
})
export class LoginDirective {
  @Input() register: boolean;
  @Input() partnersModal?: NotdPartnersModalComponent;
  @Input() customReturnUrl: string;

  protected _elementClass: string[] = [];

  constructor(
    private userService: UserService,
    private cacheService: CacheService) {

    // In google tag manager we can track click events on this CSS class
    this._elementClass.push('gtm_transact_login');
  }

  @Input('class')
  @HostBinding('class')
  get elementClass(): string {
      return this._elementClass.join(' ');
  }
  set elementClass(val: string) {
    this._elementClass = val.split(' ');
  }


  @HostListener('click', ['$event'])
  logInEventHandler(event: Event) {
    this.login();
  }

  login() {
    if (this.partnersModal) {
      this.partnersModal.showModal();
    } else {
      this.userService.login(this.register, this.customReturnUrl ? this.customReturnUrl : '').then(
        response => {
          this.cacheService.clearCache();
          console.log('transact-auth logged in');
        }
      );
    }
  }
}
