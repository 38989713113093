/* eslint-disable */
import {Pipe, PipeTransform} from '@angular/core';

// Based on https://stackoverflow.com/questions/35534959/access-key-and-value-of-object-using-ngfor

@Pipe({name: 'objectToArray'})
export class ObjectToArrayPipe implements PipeTransform {

  transform(keys, args: string[]): any {

    const myArray = [];

    for (const key in keys) {
      myArray.push({'key': key, 'text': keys[key]});
    }

    return myArray;
  }
}
