import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../services/user_service';
import {StreamService} from '../services/stream_service';
import {Observable} from 'rxjs';
import { forkJoin } from 'rxjs';

@Injectable()
export class UserStreamsRedirectGuard  {

  constructor(
    private userService: UserService,
    private streamService: StreamService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getProfile();
  }

  getProfile(): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        forkJoin([
          this.userService.listCommunities(),
          this.userService.getProfile()
        ]).subscribe(resp => {
          const communityMember = resp[0];
          const user = resp[1];

          if (user && user.id !== '') {
            Promise.all([
              this.streamService.getUserStreams(true),
              this.streamService.getUserTeamStreams()
            ]).then((results: any) => {
              const userStreams = results[0];
              const userTeamStreams = results[1].channels ? results[1].channels : [];

              if (!userStreams.length && !userTeamStreams.length && !communityMember.length) {
                this.router.navigate(['/new-note-guide']);
                resolve(false);
              }

              if (userStreams.length > 0 || userTeamStreams.length > 0 || communityMember.length > 0) {
                resolve(true);
              } else {
                resolve(false);
              }
            }).catch(() => {
              resolve(false);
            });
          } else {
            this.userService.login().then(() => {
              resolve(false);
            });
          }
        }, () => {
          resolve(false);
        });
      });
  }

}
