import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StreamService} from '../../shared/services/stream_service';
import {UserService} from '../../shared/services/user_service';

@Component({
  selector: 'user-navigation-menu',
  templateUrl: './stream-nav.component.html',
  styleUrls: ['./stream-nav.component.scss']
})
export class StreamNavComponent implements OnInit, OnDestroy {
  public myUser: any;
  public streamId: string;
  public streamName: string;
  public userId: string;
  public activeStreamTab = false;

  private routerEventsSubscription;

  constructor(
    private streamService: StreamService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService) {

  }

  public ngOnInit(): void {
    this.userService.getProfile().then(user => {
        this.myUser = user;
      }
    );

    this.routerEventsSubscription = this.route.url.subscribe(() => {
        this.activeStreamTab = (this.router.url.indexOf('streams') > 0) ? true : false;
    });

  }

  public ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }
}
