import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';

import {BehaviorSubject} from 'rxjs';
import {UserService} from './user_service';
import {CacheService} from './cache_service';

@Injectable()
export class NotificationsService {
    public notificationListSubject: Subject<any[] | null> = new Subject();
    private notification$;
    private notificationSub = new BehaviorSubject<any>(null);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    notificationList$ = this.notificationSub.asObservable();

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private cacheService: CacheService
    ) { }

    public getNotifications(nocache?: boolean): Promise<any> {
      const cacheData = this.cacheService.getNotifications();

      if (cacheData && !nocache) {
        return Promise.resolve(cacheData);
      } else {
        return this.http.get<any>(`/api/user/notifications`)
          .toPromise()
          .then(notifications => {
            this.cacheService.setNotifications(notifications);
            this.notificationListSubject.next(notifications);
            return notifications;
          })
          .catch(err => {
            console.log('getNotifications error: ', err);
            throw(err);
          });
      }
    }

    public deleteNotification(notifyId: string): Promise<any> {
        return this.http.delete<any>(`/api/user/notification/${notifyId}/remove` , {})
            .toPromise()
            .then(res => {
              console.log('deleteNotification', res);
              this.cacheService.clearNotifications();
            })
            .catch(err => {
              console.log('delete notification error: ', err);
              throw(err);
            });
    }

    public deleteAllNotifications(): Promise<any> {
      return this.http.delete(`/api/user/notifications/remove-all`, {})
        .toPromise()
        .then(() => {
          this.cacheService.clearNotifications();
          this.notificationSub.next([]);
        })
        .catch(
          err => {
            throw err;
          }
        );
    }
}
