import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NotificationService } from './notification_service';
import { Util } from '../util/util';
import { CacheService } from './cache_service';

@Injectable({
  providedIn: 'root'
})

export class A2hsService {
  promptIntercepted = false;
  isStandalone = false;
  deferredPrompt: any;
  userInstalled = false;

  // user agent
  isChrome = false;
  isExplorer = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  isExplorer_11 = false;
  isFirefox = false;
  isSafari = false;
  isOpera = false;
  isEdgeDesktop = false;
  isEdgeiOS = false;
  isEdgeAndroid = false;
  userAgent = '';

  isIOS = false;
  isMobile = false;
  hideBeforeInstallPrompt = false;

  promptSaved = false;
  deferredPromptShown = false;
  deferredPromptRejected = false;

  constructor(
    private swUpdate: SwUpdate,
    private notificationService: NotificationService,
    private cacheService: CacheService,
    private util: Util
  ) {
    swUpdate.versionUpdates.subscribe(() => {
        this.notificationService.notify({
            message: 'There is new update available for Notd',
            type: 'success',
            button: {
            label: 'Update',
            action: 'makePwaAppUpdate'
            }
        });
    });

    this.hideBeforeInstallPrompt = this.cacheService.cache.get('dismissedA2HS') || false;
  }

  checkUserAgent() {
    this.userAgent = navigator.userAgent.toLowerCase();
    const uaString = this.userAgent;

    this.isChrome = /chrome/.test(uaString);
    this.isExplorer = /msie/.test(uaString);
    this.isExplorer_11 = /rv:11/.test(uaString);
    this.isFirefox  = /firefox/.test(uaString);
    this.isSafari = /safari/.test(uaString);
    this.isOpera = /opr/.test(uaString);
    this.isEdgeDesktop = /edge/.test(uaString);
    this.isEdgeiOS = /edgios/.test(uaString);
    this.isEdgeAndroid = /edga/.test(uaString);

    this.isIOS = this.isIosDevice();
    this.isMobile = this.util.isTouchDevice(); /*/mobile/.test(uaString);*/
    if ((this.isChrome) && (this.isSafari)) {
      this.isSafari = false;
    }
    if ((this.isChrome) && (  (this.isEdgeDesktop) ||
                              (this.isEdgeiOS) ||
                              (this.isEdgeAndroid) )  ) {
                                this.isChrome = false;
                              }
    if ((this.isSafari) && (  (this.isEdgeDesktop) ||
                              (this.isEdgeiOS) ||
                              (this.isEdgeAndroid) )  ) {
                                this.isSafari = false;
                              }
    if ((this.isChrome) && (this.isOpera)) {
      this.isChrome = false;
    }
  }


  trackStandalone() {
    this.isStandalone = this.isInStandaloneMode();
  }

  trackInstalled() {
    this.userInstalled = true;
  }

  addToHomeScreen() {
    if (!this.deferredPrompt) {
      return;
    }

    this.deferredPrompt.prompt();
    this.deferredPromptShown = true;

    this.deferredPrompt.userChoice
      .then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
        } else {
            this.cacheService.setCache('dismissedA2HS', true);
            this.deferredPromptRejected = true;
        }
      });

    this.deferredPrompt = null;
  }

  public showAddToHomeScreen(): boolean {
    return this.isMobile && this.promptIntercepted && !this.userInstalled && !this.hideBeforeInstallPrompt;
  }

  public showIosInstall(): boolean {
    return this.isSafari && this.isIOS && !this.isStandalone && !this.hideBeforeInstallPrompt;
  }

  public closeA2hsPopup() {
    this.cacheService.setCache('dismissedA2HS', true);
    this.hideBeforeInstallPrompt = true;
  }

  private isIosDevice(): boolean {
    const userAgent = (window as any).navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }

  private isInStandaloneMode(): boolean {
    return ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);
  }
}
