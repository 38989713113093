import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../shared/services/user_service';
import { StreamService } from '../../shared/services/stream_service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SubscriptionStatus } from '../../shared/types/subscription_status';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { TeamService } from '../../shared/services/team_service';
import { StreamSubscriptionPlan } from '../../shared/types/stream-subscription-plan';
import { Team } from '../../shared/types/team';
import { Stream } from '../../shared/types/stream';
import { SubPlanPeriod } from '../../shared/types/subscription_period';


@Component({
  selector: 'team-subscriptions-table',
  templateUrl: './subscriptions-table.component.html',
  styleUrls: ['./subscriptions-table.component.scss']
})
export class TeamSubscriptionsTableComponent implements OnInit {
  @ViewChild('subscribeToTeamModal', {static: false}) public subscribeToTeamModal: ElementRef;
  @ViewChild('subscribeTeamPlansModal', {static: false}) public subscribeTeamPlansModal: ElementRef;
  @ViewChild('teamDetailsModal', {static: false}) public teamDetailsModal: ElementRef;

  public teamSubscriptions: StreamSubscriptionPlan[] = [];
  public loadingSubscriptions = true;
  public loadingRedirection = {
    status: false,
    type: '',
    planType: ''
  };

  public teamModalDetails: any;

  private subscribeModalRef: any;
  private teasDetailsModalRef: any;
  private teamSubscriptionPlanslRef: any;
  private isLoggedIn = false;
  private modalTeamPlansObject = {
    plans: null,
    selectedPlan: ''
  }

  constructor(
    private streamService: StreamService,
    private teamService: TeamService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private location: Location,
    private userService: UserService) {
  }

  public ngOnInit(): void {
    this.getTeamSubscriptions();
    this.isLoggedIn = this.userService.isLoggedIn();

    if (this.isLoggedIn && this.route.snapshot.queryParams['t']) {
      const urlToken = this.route.snapshot.queryParams['t'];
      const jwtHelper: JwtHelperService = new JwtHelperService();

      try {
        if (jwtHelper.decodeToken(urlToken)) {
          const decodedToken = jwtHelper.decodeToken(urlToken);
          if (decodedToken.meta.teamId) {
            this.teamService.validateSubscription(this.route.snapshot.queryParams['t'], decodedToken.meta.teamId).then(validate => {
              if (validate.status === SubscriptionStatus.Active ) {
                this.getTeamSubscriptions();
                this.removeUrlParam();
                this.showModalMessage(
                  'Subscription Confirmed.',
                  'Your subscription was successful!');
              } else {
                this.showModalMessage(
                  'Error Confirming Payment.',
                  // eslint-disable-next-line max-len
                  'We encountered an issue while confirming your payment. Please try again or contact our customer service for assistance.');
              }
            }, err => {
              console.error('Error while subscription validate', err.error || err);
            })
            .catch(err => {
              console.error('Error while subscription validate', err.error || err);
            });
          }
        }
      } catch (err) {
        console.error('Looks like url doesn`t have proper token or it is a part of application url param.', err);
      }
    }

  }

  public subscribeToTeamPlan(plan: any): void {
    this.loadingRedirection.status = true;
    this.loadingRedirection.type = '';
    this.loadingRedirection.planType = 'team';

    const transactRedirectUrl = environment.transactBaseUrl + '/purchase';
    const returnUrl = environment.urlBasePrefix + this.location.path();

    this.teamService.subscriptionRedirectPayment(
      plan.teamId,
      plan.periodStr === 'YEAR' ? 'YEARLY' : plan.periodStr,
      returnUrl).then(result => {
      if (result.token) {
        document.location.href = `${transactRedirectUrl}?t=${result.token}`;
      }
    }, err => {
        this.loadingRedirection = {
            status: true,
            type: 'error',
            planType: 'team'
        };
        console.error('Error while redirection payment process', err);
    });
  }

  public closeSubscribeModal(): void {
    this.subscribeModalRef.close();
  }

  public showTeamDetailsModal(team: any): void {
    this.teamModalDetails = team;

    this.teasDetailsModalRef = this.modalService.open(this.teamDetailsModal, {
      windowClass: 'team-details-modal',
      size: 'lg'
    });

    this.teasDetailsModalRef.result.then(result => {
      console.log(`Closed with:`, result);
      this.teamModalDetails = null;
    }, reason => {
      console.log(`Dismissed`, reason);
      this.teamModalDetails = null;
    });
  }

  public closeTeamDetailsModa(): void {
    this.teasDetailsModalRef.close();
  }

  public showTeamSubscriptionsModal(team: any): void {
    this.modalTeamPlansObject.plans = team.subPlans;
    this.teamSubscriptionPlanslRef = this.modalService.open(this.subscribeTeamPlansModal, {
      windowClass: 'subscription-plans-modal',
      size: 'lg'
    });

    this.teamSubscriptionPlanslRef.result.then(result => {
      console.log(`Closed with:`, result);
    }, reason => {
      console.log(`Dismissed`, reason);
    });
  }

  private showSubscribeModal(): void {
    const subscribeModalRef = this.modalService.open(this.subscribeToTeamModal, {
      windowClass: 'subscribe-to-team-modal',
      size: 'lg'
    });

    subscribeModalRef.result.then(result => {
      console.log(`Closed with:`, result);
      this.loadingRedirection = {
        status: false,
        type: '',
        planType: ''
      };
      this.getTeamSubscriptions();
    }, reason => {
      console.log(`Dismissed`, reason);
      this.loadingRedirection = {
        status: false,
        type: '',
        planType: ''
      };
      this.getTeamSubscriptions();
    });
  }

  private getTeamSubscriptions(): void {
    let teamSubs = [];

    this.userService.getUserSubscriptionList().then(subs => {
      teamSubs = subs.filter(s => s.teamId);

      for (const ts of teamSubs) {
        ts.plan = SubPlanPeriod[ts.period];
        this.teamService.getTeamChannels(ts.teamId).then((streams: Stream[]) => {
          ts.streams = streams;
        });

        this.teamService.getTeamSubscriptionPlans(ts.teamId).then(plans => {
          ts.subPlans = plans;
        })
      }

      this.teamSubscriptions = teamSubs;
      this.loadingSubscriptions = false;
    }, err => {
      this.loadingSubscriptions = false;
      console.error('There was an issue while fetching team subscriptions', err.error || err);
    });
  }

  private showModalMessage(title: string, message: string): void {
    const modalOptions: NgbModalOptions = {};
    const refModalConf = this.modalService.open(ConfirmationModalComponent, modalOptions);

    refModalConf.componentInstance.onlyMessage = true;
    refModalConf.componentInstance.confirmationModalTitle = title;
    refModalConf.componentInstance.confirmationModalMsg = message;

    refModalConf.result.then(resp => {
      if (resp) {
        console.log(resp);
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  private removeUrlParam(): void {
    const newUrl = window.location.pathname;
    this.location.replaceState(newUrl);
  }
}
