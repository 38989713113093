export class Location {
  public address: string;
  public latitude: number;
  public longitude: number;
  public city?: string;
  public countryName?: string;
  public region?: string;

  constructor(data) {
    this.address = data.address || '';
    this.latitude = data.latitude || null;
    this.longitude = data.longitude || null;
  }

  public isNotEmpty(): boolean {
    return this.address !== '' || this.latitude !== null || this.longitude !== null;
  }
  public getDisplayName(): string {
    if (this.city && this.countryName) {

      const name = this.city + ', ' +
        (this.region ? this.region + ', ' : '') +
        this.countryName;

      return name;
    }

    return;
  }
}
