import { Component } from '@angular/core';
import { A2hsService } from '../services/a2hs.service';

@Component({
  selector: 'app-ios-prompt-screen',
  templateUrl: './a2hs-ios-prompt-screen.component.html',
  styleUrls: ['./a2hs-ios-prompt-screen.component.scss']
})
export class A2hsIosPromptScreenComponent {
  constructor(public a2hs: A2hsService) { }
}
