import {Injectable, Inject, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class GtmService {
    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) protected platformId: any
    ) {}

    public pushTag(tagsObject: { [key: string]: string }): void {
        if (isPlatformBrowser(this.platformId)) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (<any>window).dataLayer.push(tagsObject);
        }
    }
}
