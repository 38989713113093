import {Pipe, PipeTransform} from '@angular/core';

@Pipe ({
  name : 'sortBy'
})

export class SortByPipe implements PipeTransform {

    sorter(array, fields) {

      const myFields = [];

      fields.forEach(function(item) {
        let name = item;
        const s = item.substr(0, 1);
        let c = 1;

        if (s === '-') {
          c = -c;
          name = item.substr(1);
        }

        myFields.push({
          name,
          c
        });
      });

      function comparaMe(a: any, b: any, i?: number) {
        const x = i || 0;
        const fObj = myFields[x];
        if (a[fObj.name] < b[fObj.name]) {
          return -fObj.c;
        }
        if (a[fObj.name] > b[fObj.name]) {
          return fObj.c;
        }

        if (x >= myFields.length - 1) {
          return 0;
        }
        return comparaMe(a, b, x + 1);
      }

      array.sort((a, b) => comparaMe(a, b));
      return array;
    }

  transform(myarray: any[], fields: string[]): any[] {
    if (!myarray || myarray.length === 0) {
      return null;
    }

    return this.sorter(myarray, fields);
  }
}
