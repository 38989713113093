import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ColumnSorterComponent} from './column-sorter.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';

@NgModule({
    declarations: [
        ColumnSorterComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        RouterModule,
        FontAwesomeModule
    ],
    exports: [ ColumnSorterComponent ]
})

export class ColumnSorterModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCaretUp, faCaretDown);
  }
}
