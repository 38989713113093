<section 
  class="create-note-wrapper"
  [ngClass]="{
    'only--button' : onlyButton,
    'mini--button' : miniButton,
  }"
  *ngIf="(showBlock && isLoggedIn) || (onlyButton && isLoggedIn)|| (miniButton && isLoggedIn)">
  <a (click)="hideBlock()" class="hide-block" title="Hide this message box.">Close</a>
  <span class="info">
    Start creating your content now.
  </span>
  <button
    *ngIf="onlyButton && !miniButton"
    class="btn new-note-btn btn-block"
    data-cy="new-note"
    title="Create new note"
    (click)="createNewNote()"
    [disabled]="disableNewNoteButton"
    [ngClass]="{'button-spinner' : loadingNoteCreator}"
    i18n="@@GLOBAL-NEW-NOTE">New Note</button>
  <button
    class="create-note-wrapper__mini--button"
    (click)="createNewNote()"
    [disabled]="disableNewNoteButton"
    [ngClass]="{'button-spinner' : loadingNoteCreator}"
    *ngIf="miniButton">
    <svg *ngIf="!loadingNoteCreator" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M16,24c-0.5527344,0-1-0.4472656-1-1V9c0-0.5527344,0.4472656-1,1-1s1,0.4472656,1,1v14    C17,23.5527344,16.5527344,24,16,24z"/></g><g><path d="M23,17H9c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h14c0.5527344,0,1,0.4472656,1,1S23.5527344,17,23,17z"/></g></g></svg>
  </button>
  <svg *ngIf="!onlyButton" class="create-note-wrapper__icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="Line"><path d="M8,23H6a5,5,0,0,1-5-5V6A5,5,0,0,1,6,1h8a5,5,0,0,1,5,5V8a1,1,0,0,1-2,0V6a3,3,0,0,0-3-3H6A3,3,0,0,0,3,6V18a3,3,0,0,0,3,3H8a1,1,0,0,1,0,2Z"/><path d="M12,13H6a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Z"/><path d="M8,17H6a1,1,0,0,1,0-2H8a1,1,0,0,1,0,2Z"/><path d="M14,9H6A1,1,0,0,1,6,7h8a1,1,0,0,1,0,2Z"/><path d="M12,23a1,1,0,0,1-1-1.13l.33-2.56a2.93,2.93,0,0,1,.85-1.74l5.69-5.69a3.08,3.08,0,0,1,4.24,0,3,3,0,0,1,0,4.24l-5.69,5.69a2.93,2.93,0,0,1-1.74.85L12.13,23Zm8-10a1,1,0,0,0-.71.29L13.61,19a1,1,0,0,0-.29.58l-.16,1.28,1.28-.16A1.06,1.06,0,0,0,15,20.4l5.69-5.69a1,1,0,0,0,0-1.42A1,1,0,0,0,20,13Z"/></g></svg>
</section>