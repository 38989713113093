import {Injectable, Injector}                                              from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class GuardQueue  {
    constructor(private injector: Injector) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        const guards = route.data.guards || [];

        for (const guard of guards) {
            const instance: {
    canActivate: CanActivateFn;
} = this.injector.get(guard);
            let result  = instance.canActivate(route, state);

            if (result instanceof Promise) {
                result = await result;
            }
            if (result instanceof Observable) {
                result = await result.toPromise();
            }
            if (result === false || result instanceof UrlTree) {
                return result;
            }
        }

        return true;
    }
}
