import * as Debug from 'debug';
import { NoteBoxComponent } from './../../search/note-box/note-box.component';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {NoteService} from '../services/note_service';
import {UserService} from '../services/user_service';
import {StreamService} from '../services/stream_service';
import { Stream } from '../types/stream';
import { Note } from '../types/note';
import { User } from '../types/user';

const debug = Debug('notd:NoteResolve');

@Injectable()
export class NoteResolve  {

  constructor(
      private noteService: NoteService,
      private userService: UserService,
      private streamService: StreamService,
      private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    if (route.params['custom-url']) {
      const customUrl = route.params['custom-url'];
      debug('resolve customUrl', customUrl);
      return this.noteService.getNoteIdFromCustomUrl(customUrl).then(
        result => this.getNoteInfo(result.post.id),
        err => {
          console.error('No custom url found, redirect to 404', err);
          this.router.navigate(['/404']);
        }
      )
      .catch(err => {
        console.error('No custom url found, redirect to 404', err);
        this.router.navigate(['/404']);
      });
    } else {
        debug('resolve id', route.params['id']);
        return this.getNoteInfo(route.params['id']);
    }
  }

  private getNoteInfo(noteId: string): Promise<Note | null> {
    return this.noteService.getNoteInfo(noteId, true).then(
      (note: Note) => {
        const promisesArray = [];
        const promisesMap = [];

        debug('getNoteInfo id:', noteId);

        if (!note.user && note.userId) {
          promisesArray.push(this.userService.getUserProfile(note.userId));
          promisesMap.push('user');
        }

        if (!note.stream && note.channelId) {
          promisesArray.push(this.streamService.getStreamInfo(note.channelId));
          promisesMap.push('stream');
        }

        return Promise.all(promisesArray).then((res) => {
            note.comments = [];
            res.forEach((result, i) => {
              note[promisesMap[i]] = result;
            });
            return note;
        }, () => note)
        .catch(err => {
          console.error('Error while fetching additional note data', err);
          this.router.navigate(['/404']);
        });
      },
      err => {
        if (err && err.status === 404) {
            this.router.navigate(['/404']);
        }
        this.router.navigate(['/']);
        return null;
      }
    ).catch(err => {
      console.log(err);
    });
  }
}
