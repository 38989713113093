import { Routes } from '@angular/router';

import {LoginRedirectGuard} from './../shared/route-guards/login-redirect.guard';
import {AccountRedirectGuard} from './../shared/route-guards/account-redirect.guard';
import {StreamDetailsResolve} from './../shared/resolvers/stream-resolve.service';
import {UserResolve} from './../shared/resolvers/user-resolve.service';

import {UserComponent} from './user.component';
import { ExistUserGuard } from '../shared/route-guards/exist-user.guard';
/*
 Route Configuration, Please Set new Route by component on routes directory.
 */
export const routes: Routes = [
    {
        path: 'user/:userId/streams/:id/:myStreamName',
        loadChildren: () => import('./../streams/stream-details/stream-details.module').then(m => m.StreamDetailsModule),
        resolve: {
          requestedStream: StreamDetailsResolve,
          user: UserResolve
        },
        canActivate: [ExistUserGuard]
    },
    {
        path: 'user/:userId/streams/:id',
        loadChildren: () => import('./../streams/stream-details/stream-details.module').then(m => m.StreamDetailsModule),
        resolve: {
          requestedStream: StreamDetailsResolve,
          user: UserResolve
        },
        canActivate: [ExistUserGuard]
    },
    {
        path: 'user/:userId/saved-notes',
        loadChildren: () => import('./saved-notes/saved-notes.module').then(m => m.SavedNotesModule),
        canActivate: [LoginRedirectGuard, AccountRedirectGuard]
    },
    {
        path: 'user/:userId/my-account',
        component: UserComponent,
        canActivate: [LoginRedirectGuard, AccountRedirectGuard],
        resolve: {
          user: UserResolve
        }
    },
    // {
    //     path: 'user/:userId/profile',
    //     loadChildren: () => import('./public-profile/public-profile.module').then(m => m.PublicProfileModule),
    //     resolve: {
    //       user: UserResolve
    //     },
    //     canActivate: [ExistUserGuard]
    // },
    {
      path: 'user/:userIdent/profile',
      resolve: {
        user: UserResolve
      },
      canActivate: [ExistUserGuard],
      children: [
        {
          path: 'notes',
          loadChildren: () => import('./public-profile/public-profile.module').then(m => m.PublicProfileModule),
          data: {
            mode: 'notes'
          }
        },
        {
          path: 'streams',
          loadChildren: () => import('./public-profile/public-profile.module').then(m => m.PublicProfileModule),
          data: {
            mode: 'streams'
          }
        },
        {
          path: 'comments',
          loadChildren: () => import('./public-profile/public-profile.module').then(m => m.PublicProfileModule),
          data: {
            mode: 'comments'
          }
        },
        {
          path: 'ratings',
          loadChildren: () => import('./public-profile/public-profile.module').then(m => m.PublicProfileModule),
          data: {
            mode: 'ratings'
          }
        },
        {
          path: 'delete/:token',
          loadChildren: () => import('./../profile-delete/profile-delete.module').then(m => m.ProfileDeleteModule),
        },
        {
          path: '**',
          redirectTo: 'notes'
        }
      ]
    },
    {
        path: 'user/:userId/earnings',
        loadChildren: () => import('./earnings/earnings.module').then(m => m.EarningsModule),
        canActivate: [LoginRedirectGuard, AccountRedirectGuard]
    },
    {
        path: 'user/notifications/:id',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [LoginRedirectGuard]
    },
    {
        path: 'user/notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [LoginRedirectGuard]
    },
    {
        path: 'user/my-notes',
        loadChildren: () => import('./my-notes/my-notes.module').then(m => m.MyNotesModule),
        canActivate: [LoginRedirectGuard]
    },
    {
        path: 'user/trash',
        loadChildren: () => import('./trash/trash.module').then(m => m.TrashModule),
        canActivate: [LoginRedirectGuard]
    },
    {
      path: 'user/my-communities',
      loadChildren: () => import('./my-communities/my-communities.module').then(m => m.MyCommunitiesModule),
      canActivate: [LoginRedirectGuard]
    },
    {
      path: 'user/my-subscriptions',
      loadChildren: () => import('./my-subscriptions/my-subscriptions.module').then(m => m.MySubscriptionsModule),
      canActivate: [LoginRedirectGuard]
    },
    {
        path: 'user/followed-streams',
        loadChildren: () => import('./followed-streams/followed-streams.module').then(m => m.FollowedStreamsModule),
        canActivate: [LoginRedirectGuard]
    },
];
