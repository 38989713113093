/* eslint-disable @typescript-eslint/naming-convention */
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserService } from '../services/user_service';

export function UsernameExistValidation(userService: UserService): AsyncValidatorFn {
    let interval: number | NodeJS.Timeout;
    const timeout = 2000;

    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => new Promise(resolve => {
            if (interval) {
                clearTimeout(interval);
            }

            const currentUser = userService.curUser;

            if (control.value && control.value !== currentUser.userName) {
                interval = setTimeout(() => {
                    userService.getUserProfileByUsername(control.value)
                        .then(result => {
                            resolve(result && result.id ? {duplicatedUsername: true} : null);
                        })
                        .catch(() => {
                            resolve(null);
                        });
                }, timeout > 0 ? timeout : 3000);
            } else {
                resolve(null);
            }
        });
}
