import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {SearchService} from '../services/search_service';
import { CategoriesService } from '../services/categories_service';
import { NoteService } from '../services/note_service';
import { Note } from '../types/note';

@Component({
    selector: 'search-content',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchContentComponent implements OnInit, OnChanges {
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Input() props: {};
    @Input() type: string;
    @Input() isPreviewMode: boolean;
    @Input() previewDefaultPhase: string | null;
    @Input() previewPosition: string; // standalone / inline
    @Output() searchTriggered: EventEmitter<string | null> = new EventEmitter();

    properties: any = {
        customClass: '',
        showIcon: true,
        iconSide: 'left',
        showButton: true,
        inputPlaceholderText: '',
        buttonText: 'Search'
    };

    public searchForm: UntypedFormGroup;
    public previewForm: UntypedFormGroup;
    public showMobileSubmitBtn = false;
    public activePreviewContainer = false;
    public searchResults = [];
    public loadingResults = false;
    public firstPreviewLoad = true;
    public categories: {
        cat: string;
        label: string;
      }[] = [];
    public previewDefaultResults: any;
    public userTypingEvent = false;
    private getPreviewResultsSub: any;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private searchService: SearchService,
        private noteService: NoteService,
        private categoryService: CategoriesService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (this.isPreviewMode) {
            this.closePreviewEvent();
        }
    }

    ngOnInit() {
        this.properties = Object.assign({}, {
            customClass: (this.props['customClass']) ? this.props['customClass'] : false,
            showIcon: (this.props['showIcon']) ? true : false,
            iconSide: (this.props['iconSide']) ? this.props['iconSide'] : 'left',
            showButton: (this.props['showButton']) ? true : false,
            inputPlaceholderText: (this.props['inputPlaceholder']) ? this.props['inputPlaceholder'] : '',
            buttonText: (this.props['buttonText']) ? this.props['buttonText'] : ''
        });

        this.setSearchForm();

      this.route.params.subscribe(() => {
        this.checkRouteParams();
      });

      if (this.isPreviewMode) {
        this.handlePreviewInput();
        this.getCategories();
        this.getDefaultResults();
      }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['type']) {
            this.type = changes['type'].currentValue;
        }
    }

    setSearchForm() {
        this.searchForm = this.formBuilder.group({
            query: ['', Validators.required]
        });
    }

    checkRouteParams() {
        const queryString = !!this.route.params['value']['query'] ? this.route.params['value']['query'] : '' ;

        if (queryString) {
        this.searchForm.patchValue({
            query: queryString
        });
        }
    }

    onSubmit(event) {
        if (this.searchForm.valid) {
            if (!this.route.params['value']['form'] && !this.route.params['value']['category']) {
                this.router.navigate(['/search/query', this.type, this.searchForm.get('query').value]);
            }
            if (this.route.params['value']['form'] && this.route.params['value']['category']) {
                this.router.navigate(['/search',
                    this.route.params['value']['form'],
                    this.route.params['value']['category'],
                    this.type, this.searchForm.get('query').value]);
            }
        } else {
            this.router.navigate(['/search', 'all', 'all']);
        }

        this.searchTriggered.emit('clicked');
    }

    public togglePreviewModal(value?: string): void {
        this.activePreviewContainer = value === 'show' || value === 'hide' ? value === 'show' ? true : false : true;
    }

    public clearPreviewInput(): void {
        this.previewForm.get('previewInput').patchValue('');
        this.firstPreviewLoad = true;
    }

    public keyPressEvent(ev: any): void {
        this.userTypingEvent = true;
    }

    public closePreviewEvent(): void {
        const inputValue = this.searchForm.get('query').value;
        if (inputValue) {
            this.searchForm.get('query').patchValue('');
            this.searchResults = [];
        }

        if (!inputValue) {
            this.togglePreviewModal('hide');
        }
    }

    mobileSubmitBtn(status: boolean) {
        if (!status && (this.searchForm.get('query') && !this.searchForm.get('query').value)) {
            this.showMobileSubmitBtn = status;
        } else if (status) {
            this.showMobileSubmitBtn = status;
        }
    }

    private handlePreviewInput(): void {
        this.searchForm.get('query').valueChanges
        .pipe(
            debounceTime(1000)
        )
        .subscribe(value => {
            const query = {
                getDetails: '0',
                size: '5',
                q: value
            };

            this.userTypingEvent = false;

            if (!!value && value.length > 3) {
                this.loadingResults = true;
                query.q = !value.endsWith(' ') || !value.endsWith('*') ? value + '*' : value;

                this.getPreviewResultsSub = this.searchService.getPosts(query).subscribe(res => {
                    this.loadingResults = false;
                    const results = res.posts ? res.posts : [];
                    for (const n of results) {
                        this.noteService.getNoteInfo(n.postId).then((note: Note) => {
                            n.note = note;
                        });
                    }

                    this.searchResults = results;
                });
            }
        });
    }

    private getDefaultResults(): void {
        const query = {
            getDetails: '0',
            size: '5',
            q: this.previewDefaultPhase || 'notd'
        };

        this.previewDefaultResults = this.searchService.getPosts(query).subscribe(res => {
            this.previewDefaultResults = res.posts ? res.posts : [];
        });
    }

    private getCategories(): void {
        const categories = this.categoryService.getCategories();
        const cateoriesByLabel = [];

        for (const c of categories) {
          cateoriesByLabel.push({
            cat: c,
            label: this.categoryService.getCategoryLabel(c)
          });
        }

        this.categories = cateoriesByLabel;
    }
}

