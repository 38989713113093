<svg aria-label="Close" aria-label="Close" data-cy="contact-cross-icon" (click)="activeModal.dismiss('Cross click')" version="1.1" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" class="ng-element" data-id="f73bc3d8d2012b201577d314d79da62c" style="fill: rgb(85, 123, 131); width: 32px; height: 32px; filter: none;" xmlns="http://www.w3.org/2000/svg"><g>
  <path id="window-close"
        d="M14.9,16.42c-0.13,0.13-0.33,0.14-0.47,0.01c0,0-0.01-0.01-0.01-0.01L12,14l-2.43,2.42  c-0.13,0.13-0.33,0.14-0.47,0.01c0,0-0.01-0.01-0.01-0.01L7.58,14.9c-0.13-0.13-0.14-0.33-0.01-0.47c0,0,0.01-0.01,0.01-0.01L10,12  L7.58,9.57C7.45,9.45,7.44,9.24,7.57,9.1c0,0,0.01-0.01,0.01-0.01L9.1,7.58c0.13-0.13,0.33-0.14,0.47-0.01c0,0,0.01,0.01,0.01,0.01  L12,10l2.43-2.43c0.13-0.13,0.33-0.14,0.47-0.01c0,0,0.01,0.01,0.01,0.01l1.51,1.53c0.13,0.13,0.14,0.33,0.01,0.47  c0,0-0.01,0.01-0.01,0.01L14,12l2.43,2.43c0.13,0.13,0.14,0.33,0.01,0.47c0,0-0.01,0.01-0.01,0.01L14.9,16.42L14.9,16.42z   M20.84,4.49C20.53,4.17,20.1,3.99,19.66,4H4.34C3.42,4,2.67,4.75,2.67,5.67l0,0v12.66c0,0.92,0.75,1.67,1.67,1.67l0,0h15.32  c0.92,0,1.67-0.75,1.67-1.67l0,0V5.67C21.34,5.23,21.16,4.8,20.84,4.49z"
        style="fill: rgb(85, 123, 131);"/></g>
</svg>
<h2 *ngIf="!contactMessageSent">say hello!</h2>
<p *ngIf="!contactMessageSent">
Use this form or email directly <a href="mailto:support@notd.io?subject=Notd"> support&#64;notd.io</a>
</p>
<form
  *ngIf="!contactMessageSent"
  novalidate
  (ngSubmit)="onContactSubmit($event)"
  [formGroup]="contactForm">
  <div class="form-group">
    <input type="text" data-cy="contact-name" class="form-control" placeholder="Name" formControlName="name">
    <span class="form-error" *ngIf="contactForm.get('name').invalid && contactForm.get('name').touched">
      Please enter your name.
    </span>
  </div>
  <div class="form-group">
    <input type="text" data-cy="contact-email" class="form-control" placeholder="Email" formControlName="email">
    <span class="form-error" *ngIf="contactForm.get('email').invalid && contactForm.get('email').touched">
      Please enter valid e-mail address.
    </span>
  </div>
  <div class="form-group">
    <input type="text" data-cy="contact-subject" class="form-control" placeholder="Subject" formControlName="subject">
    <span class="form-error" *ngIf="contactForm.get('subject').invalid && contactForm.get('subject').touched">
      Please enter the subject.
    </span>
  </div>
  <div class="form-group">
    <textarea class="form-control" data-cy="contact-message" rows="3" placeholder="Your Message" formControlName="message"></textarea>
    <span class="form-error" *ngIf="contactForm.get('message').invalid && contactForm.get('message').touched">
      Please enter message.
    </span>
  </div>
  <button type="submit" data-cy="contact-send-btn" class="btn btn-primary">Send</button>
</form>
<div class="success" *ngIf="contactMessageSent">
  <h4>
    Your message has been sent!
  </h4>
  <button type="submit" data-cy="contact-close-btn" class="btn btn-primary" aria-label="Close" (click)="activeModal.dismiss('Cross click')">Close</button>
</div>
