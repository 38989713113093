/* eslint-disable */
import * as Debug from 'debug';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {
  Observable,
  Subject,
  map
} from 'rxjs';

import {StreamService} from './stream_service';
import {CacheService} from './cache_service';
import {Note} from '../types/note';
import {Stream} from '../types/stream';
import {Comment} from '../types/comment';
import {NoteFlag} from '../types/note_flag';
import {NoteCommentFlag} from '../types/note_comment_flag';

import {Location} from '../types/location';
import {environment} from '../../../environments/environment';

import { catchError, publishReplay } from 'rxjs/operators';
import { refCount } from 'rxjs/operators';
import { of } from 'rxjs';

declare var transactApi: any;
const debug = Debug('notd:NoteService');

@Injectable()
export class NoteService {
  globalQueryString: string;
  filterSortStatus: string;
  updatedQuery: Subject<any>;
  postCategories: string[];
  noteStreamInfo$ = new Subject<any>();
  featuredStreams$ = new Subject<any>();

  constructor(protected _sanitizer: DomSanitizer,
      @Inject(PLATFORM_ID) private platformId: Object,
      private router: Router,
      private streamService: StreamService,
      private cacheService: CacheService,
      private http: HttpClient) {
    // debug('NoteService constructor origin', this.originUrl);
    this.globalQueryString = '';
    this.postCategories = [];
    this.updatedQuery = new Subject<any>();
  }

  createNewStreamNote(id: string): Promise<Note> {
    return this.http.get( '/api/channel/' + id + '/post/new' , {})
        .toPromise().then((resp:any) => {
          this.addToCategories(resp.tags);
          const newP: Note = this.createPostObject(resp);
          this.cacheService.setPostInfo(newP.id, newP);
          return newP;
        }, (err) => {
          console.error('Error creating new note', err);
          throw err;
        });
  }

  deleteNote(noteId: string): Observable<object> {
    return this.http.delete<any>(`/api/post/${noteId}`, {});
  }

  bookmarkNote(noteId: string): Promise<object> {
    return this.http.put(`/api/user/saved-post/add/${noteId}`, {})
      .toPromise()
      .catch(
        err => {
          throw err;
        }
      );
  }

  removeBookmark(noteId: string): Promise<object> {
    return this.http.delete(`/api/user/saved-post/remove/${noteId}`, {})
      .toPromise()
      .catch(
        err => {
          throw err;
        }
      );
  }

  updateNoteName(noteId: string, name: string) {
    const url = `/api/post/${noteId}/name`;
    const params = new HttpParams().set('name', name);
    const options: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post(url, params, options).toPromise();
  }

  getNoteState(noteId: string): Promise<object> {
    const url = '/api/post/' + noteId + '/view/state';

    return this.http.get(url, {})
        .toPromise();
  }

  updateNoteStream(noteId: string, streamId: string) {
    const url = `/api/post/${noteId}/channel/${streamId}`;

    // const params = new HttpParams().set('name', name);
    const options: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post(url, options).toPromise();
  }

  updateNoteTags(noteId: string, tags: Array<string>) {
    const url = `/api/post/${noteId}/tags`;

    const params: HttpParams = new HttpParams()
        .set('tags', tags.join(' '));

    return this.http.put<any>(url, params).toPromise();
  }

  updateCommentsStatus(noteId: string, status: boolean) {
    const type = (status) ? 'enabled' : 'disabled';
    const url = `/api/post/${noteId}/comments/${type}`;

    return this.http.put<any>(url, {}).toPromise();
  }

  setParentNoteId(noteId: string, parentNoteId: string) {
    const url = `/api/post/${noteId}/parent/${parentNoteId}`;

    return this.http.put<any>(url, {}).toPromise();
  }

  updateNoteLeadContent(noteId: string, content: string) {
    const url = `/api/post/${noteId}/lead/content`;
    const options: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/octet-stream')
    };

    return this.http.post(url, content, options).toPromise();
  }

  updateNotePremiumContent(noteId: string, content: string) {
    const url = `/api/post/${noteId}/premium/content`;
    const options: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/octet-stream')
    };

    return this.http.post<any>(url, content, options)
      .toPromise();
  }

  updateNoteLocation (noteId: string, location: Location) {
    let url;
    let params;

    const options: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    if (location.longitude && location.latitude) {
      url = `/api/post/${noteId}/location/lat_lng`;
      params = new HttpParams()
        .set('lat', location.latitude.toString())
        .set('lng', location.longitude.toString())
    } else {
      url = `/api/post/${noteId}/location/address/${location.address}`;
    }
    return this.http.post(url, params, options).toPromise();
  }

  updateNoteState(noteId: string, state: string): Promise<object> {
    const url = `/api/post/${noteId}/state/${state}`;

    return this.http.post(url, {})
        .toPromise();
  }

  uploadFile(file: File, type?: string): Observable<any> {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const options = {
      reportProgress: true
    };

    const apiUrl: string = (type === 'identityDoc') ? 'legal/identity_document' : 'legal/tax_document';

    return this.http.post<any>(`/api/user/profile/${apiUrl}`, formData, options);
  }

  uploadThumbnail(fileToUpload: any, noteId: string): Promise<any> {
    const formData: FormData = new FormData();

    formData.append('name', fileToUpload.name);
    formData.append('file', fileToUpload);

    return this.http.post<any>(`/api/post/${noteId}/thumbnail/image`, formData).toPromise();
  }

  uploadMediaFile(fileToUpload: any, uploadUrl: string): Promise<any> {
    return this.http.put<any>(uploadUrl, fileToUpload).toPromise();
  }

  uploadAmazonFile(fileToUpload: File, uploadUrl: string): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', fileToUpload.type)
      .set('ngsw-bypass', 'true');

    const options = {
      headers: headers,
      observe: 'events' as 'body',
      reportProgress: true
    };

    //const req = new HttpRequest('PUT', uploadUrl, fileToUpload, options);

    // return Observable.create(observer => {
    //   const xhr: XMLHttpRequest = new XMLHttpRequest();
    //
    //   xhr.onreadystatechange = () => {
    //     if (xhr.readyState === 4) {
    //       if (xhr.status === 200) {
    //         observer.next({
    //           status: 'response',
    //           message: 'upload done'
    //         });
    //         observer.complete();
    //       } else {
    //         // observer.error(xhr.response);
    //         observer.next({
    //           status: 'error',
    //           message: xhr.response
    //         })
    //       }
    //     }
    //   };
    //
    //   xhr.upload.onprogress = (event: any) => {
    //     const progress = Math.round(100 * event.loaded / event.total);
    //     console.log(`Uploading in progress! ${ progress }% uploaded`);
    //     observer.next({
    //       status: 'progress',
    //       message: progress
    //     });
    //   };
    //
    //   xhr.open('PUT', uploadUrl, true);
    //   xhr.send(fileToUpload);
    // });

    return Observable.create(observer => {
      this.http.put(uploadUrl, fileToUpload, options).subscribe((event: HttpEvent<any>) => {
        console.log('event: ', event);
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request sent!');
            observer.next({
              status: 'sent',
              message: 'request sent!'
            });
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header received!');
            break;
          case HttpEventType.DownloadProgress:
            const kbLoaded = Math.round(event.loaded / 1024);
            console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
            break;
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            console.log(`Uploading in progress! ${ progress }% uploaded`);
            observer.next({
              status: 'progress',
              message: progress
            });
            break;
          case HttpEventType.Response:
            console.log('Done!', event.body);
            observer.next({
              status: 'response',
              message: 'upload done ' + event.body
            })
        }
      }, err => {
        console.error(err);
        observer.next({
          status: 'error',
          message: err
        })
      });
    });
  }

  getMediaFile(fileName: string, type: string, noteId: string): Promise<any> {
    // do NOT set ngsw-bypass to backend server will cause it to fail without cookie
    return this.http.get(`/api/post/${noteId}/premium/media/upload_url?filename=${fileName}&ctype=${type}`)
      .toPromise();
  }

  deleteMediaFile(key: string, noteId: string): Promise<any> {
    return this.http.delete(`/api/post/${noteId}/media?key=${key}`)
      .toPromise();
  }

  // TBD RPH - this method needs to update the cached note looks like we need to do a
  // noCached getNoteInfo to get the updated Note after the POST /post/id/rating is executed
  rateNote(noteId: string, review: any) {
    const token = this.cacheService.getPostPurchaseToken(noteId);

    if (token) {
      Object.assign(review, {
        token: token
      });
    }

    return this.http.post('/api/post/' + noteId + '/rating', review)
        .toPromise();
  }

  commentOnNote(postId: string, commentBody: string, commentToReplyId?: string) {
    const payload = {
      comments: commentBody,
      parent: null
    };
    if (commentToReplyId !== undefined) {
      payload.parent = commentToReplyId;
    }

    return this.http.post('/api/post/' + postId + '/comment', payload)
        .toPromise();
  }

  updateComment(noteId: string, commentId: string, comment: string): Observable<Comment> {
    const payload = {
      comments: comment
    };

    return this.http.post<any>(`/api/post/${noteId}/comment/${commentId}/update`, payload );
  }

  deleteComment(noteId: string, commentId: string): Observable<object> {
    return this.http.delete<any>(`/api/post/${noteId}/comment/${commentId}/remove`);
  }


  likeCommentOnNote(postId: string, commentId: string) {
    return this.http.post(`/api/post/${postId}/comment/${commentId}/like`, {})
      .toPromise().catch((err) => {
        throw err;
      });
  }

  dislikeCommentOnNote(postId: string, commentId: string) {
    return this.http.post(`/api/post/${postId}/comment/${commentId}/dislike`, {})
      .toPromise().catch((err) => {
        throw err;
      });
  }

  getNoteInfo(postId: string, noCache?: boolean): Promise<Note> {
    let promise;
    const cachedPost = this.cacheService.getPostInfo(postId);
    noCache = noCache || false;

    debug('getNoteInfo cachedPost', cachedPost);
    if (cachedPost && !noCache) {
      promise = Promise.resolve(cachedPost);
    } else {
      const url = environment.apiServerBase + '/api/post/' + postId + '/info';
      debug('getNoteInfo fetch note from', url)
      promise = this.http.get(url)
        .toPromise()
        .catch(err => {
          console.error('Error while fetching note id', postId, err);
          throw err;
        });
    }

    return promise.then( (resp: Note)=> {
        debug('getNoteInfo promise resolved note:', resp.id)
        this.addToCategories(resp.tags);
        this.cacheService.setPostInfo(resp.id, resp);
        return this.createPostObject(resp, noCache);
      }, (err) => {
        debug('getNoteInfo err', err);
        throw err;
      })
      .catch(err => {
        console.error('Error while fetching note id', postId, err);
        throw err;
      });
  }

  getNoteInfoObservable(noteId: string, noCache?: boolean): Observable<Note | { isError:boolean } > {
    const cachedData = this.cacheService.getPostInfo(noteId);

    if (cachedData && !noCache) {
      return of(this.cacheService.getPostInfo(noteId));
    } else {
      return this.http.get(`${environment.apiServerBase}/api/post/${noteId}/info`)
        .pipe(
          publishReplay(1),
          refCount(),
          map((note: Note) => {
            this.cacheService.setPostInfo(note.id, note);
            return note;
          }),
          catchError(err => {
            console.error('Error fetching note ID: ', noteId);
            return of({isError: true});
          })
        );
    }
  }

  getNotePurchaseStatus(noteId: string): Promise<any> {
    return this.http.get(`/api/post/${noteId}/purchase/status`)
        .toPromise().then(resp => {
          return resp;
        }, (err) => {
          return err;
        });
  }

  getAnnotations(noteId: string): Promise<any> {
    const url = `/api/post/${noteId}/premium/content/annotations`;

    return this.http.get(url, {}).toPromise().catch(err => {
      throw err;
    });
  }

  putAnnotations(noteId: string, annData: any): Promise<any> {
    const params: HttpParams = new HttpParams()
        .set('text', annData.text ? annData.text : '')
        .set('startWord', annData.startWord ? annData.startWord : '')
        .set('endWord', annData.endWord ? annData.endWord : '')
        .set('startPos', annData.startPos ? annData.startPos : '');
    const url = `/api/post/${noteId}/premium/content/annotation`;

    return this.http.put(url, params).toPromise();
  }

  updateAnnotations(noteId: string, annData: any): Promise<any> {
    const params: HttpParams = new HttpParams()
        .set('id', annData.id ? annData.id : '')
        .set('text', annData.text)
        .set('startWord', annData.startWord ? annData.startWord : '')
        .set('endWord', annData.endWord ? annData.endWord : '');

    const url = `/api/post/${noteId}/premium/content/annotation`;

    return this.http.put(url, params).toPromise();
  }

  deleteAnnotations(noteId: string, annId: string): Promise<object> {
    const url = `/api/post/${noteId}/premium/content/annotation/${annId}`;
    return this.http.delete(url, {}).toPromise();
  }

  canAnnotate(noteId: string): Promise<any> {
    const url = `/api/post/${noteId}/premium/content/can-annotate`;

    return this.http.get(url, {}).toPromise();
  }

  getNoteRatings(noteId: string): Promise<any> {
    return this.http.get( '/api/post/' + noteId + '/ratings')
      .toPromise();
  }

  getNoteComments(noteId: string): Promise<Comment[]> {
    return this.http.get( '/api/post/' + noteId + '/comments' , {})
      .pipe(
        map(this.formatComments)
      )
      .toPromise();
  }

  loadNoteLead(note: Note, noCache?: boolean) {
    let promises;
    const cachedPostLead = noCache ? false : this.cacheService.getPostLead(note.id);

    const options: any = {
      responseType: 'text'
    };

    if (cachedPostLead) {
      promises = [Promise.resolve(cachedPostLead)];
    } else {
      promises = [
        this.http.get( '/api/post/' + note.id + '/lead/content' , options)
          // .map(this.extractHtml)
          .toPromise()
          .catch(err => {
            console.log(err);
          })
      ];
    }

    return new Promise<Note>((resolve, reject) => {
      Promise.all(promises).then( results => {
        debug('promise all results', results);
        note.leadContent = this._sanitizer.bypassSecurityTrustHtml(this.processBase64(<string> results[0]));
        this.cacheService.setPostLead(note.id, results[0]);
        resolve(note);
      })
      .catch( err => {
        reject(err);
      });
    });
  }

  loadNotePremiumContent(post: Note, token?: string): Promise<Note> {
    const options: any = {
      params: (token) ? new HttpParams().set('t', token) : '',
      responseType: 'text'
    };

    return this.http.get( '/api/post/' + post.id + '/premium/content', options)
      .toPromise()
      .then((res) => {
        post.premiumContent = res;
        return post;
      })
      .catch( err => {
        if (err.status !== 402) {
          console.log('error in note promise all', err);
          throw(err);
        }
        post.status = err.status;
        throw(post);
      });
  }

  preparePurchaseNote(note: Note) {
    if (isPlatformServer(this.platformId)) {
      //purchase will happen client side
      return;
    }

    const interval = 4000;
    const limit = 3;
    let counts = 0;

    const url = document.location.href;

    return new Promise((resolve, reject) => {
      const tokenPromise = this.http.get( '/api/post/' + note.id + '/purchase/token?url=' + url , {})
        .toPromise()
        .then(purchaseService => {
          resolve(purchaseService);
        })
        .catch(err => {
          const promiseInterval = setInterval(() => {
            if (counts < limit) {
              tokenPromise;
              counts++;
            } else {
              clearInterval(promiseInterval);
              reject(err);
            }
          }, interval);
        })
    })
  }

  getNote(noteId: string, noCache?: boolean): Promise<Note> {
    return this.getNoteInfo(noteId, noCache).then(
      post => {
        return Promise.all([
          this.loadNoteLead(post, noCache),
          this.loadNotePremiumContent(post),
          this.getNoteRatings(noteId),
        ]).then(
          results => {
            debug('promise all results', post);
            post.status = 1;
            post.ratings = results[2];
            this.addToCategories(post.tags);
            this.cacheService.setPostInfo(post.id, post);
            return post;
          }
        ).catch(err => {
          console.error('Error getting note. ', err);
          throw err;
        });
      }
    ).catch(
      err => {
        console.error('Error getting note. ', err);
        throw err;
      }
    );
  }

  getMediaItem(post: Note): any {
    let foundItem = null;
    let name: string;
    if (post && post.premiumMedia && post.premiumMedia.length > 0) {
      for (let i = post.premiumMedia.length - 1; i >= 0; i--) {
        if (post.premiumMedia[i].duration) {
          foundItem = post.premiumMedia[i];
          if (name = this.pullFileName(foundItem)) {  // this is an assignement to name not a check for equality!!!
            foundItem.fileName = name;
          }
          break;
        }
      }
      if (!foundItem && post.premiumMedia.length >= 1) {
        foundItem = post.premiumMedia[post.premiumMedia.length - 1];
        if (name = this.pullFileName(foundItem)) {  // this is an assignement to name not a check for equality!!!
          foundItem.fileName = name;
        }
      }
    }
    if (foundItem) {
      foundItem.aspect = (foundItem.height || 720) / (foundItem.width || 1280);
      return foundItem;
    }
    return null;
  }

  getFeaturedChannels(offset: number, size: number): Observable<Stream[]> {
    const params: HttpParams = new HttpParams()
      .set( 'fromOffset', offset)
      .set( 'size', size);

    return this.http.get<any>(`/api/search/channels/featured`, { params });
  }

  getFeaturedNotes(offset: number, size: number): Observable<Note[]> {
    const params: HttpParams = new HttpParams()
      .set( 'offset', offset )
      .set( 'size', size);
    return this.http.get<any>(`/api/search/posts/featured`,  { params });
  }

  getSubscriptionsLatestsPosts(): Promise<Note> {
    return this.http.get<any>(`/api/user/subscriptions/latest-posts`, {})
      .toPromise()
  }

  getNewestChannels(offset: number, size: number): Observable<Stream[]> {
    const params: HttpParams = new HttpParams()
      .set( 'fromOffset', offset)
      .set( 'size', size || '' );

    return this.http.get<any>(`/api/search/channels/latest-posts`, { params });
  }

  getChannelPosts(chanId: string, pageOptions?: any): Promise<any> {
    let myParams: any = {};

    if (pageOptions) {
      myParams.params = pageOptions;
    }

    return this.http.get( environment.apiServerBase + '/api/channel/' + chanId + '/posts/list' , myParams)
      .pipe(
        map((resp: any) => {
        if (pageOptions &&
          resp.took &&
          resp.total &&
          resp.posts) {
            return {
              'took': resp.took,
              'total': resp.total,
              'posts': resp.posts.map((post: Note) => {
                this.addToCategories(post['tags']);
                this.cacheService.setPostInfo(post['id'], post);
                return this.createPostObject(post);
              })};
        }

        if (resp.posts) {
          return resp.posts.map((post: Note) => {
            this.addToCategories(post['tags']);
              this.cacheService.setPostInfo(post['id'], post);
              return this.createPostObject(post);
            });
        }
      }))
      .toPromise()

  }

  getMyNotes(size: string | number, offset: string | number, state: string): Promise<Object> {
    const params = new HttpParams()
      .set('size', size ? size.toString() : '')
      .set('state', state)
      .set('offset', offset ? offset.toString() : '0');

    return this.http.get('/api/user/posts', { params: params })
      .toPromise()
      .catch(err => {
        throw err;
      });
  }

  putPrice(postId: string, price: number) {
    const url: string = '/api/post/' + postId + '/price/' + price.toString();
    return this.http.put(url, {})
    .toPromise();
    // Cache updated by putPrice caller by invoking this mergeAndUpdateCache after processing the response
  }

  getPostMediaList(postId: string) { // TBD - this needs to be expanded to give both lead and premium media
    return this.http.get( '/api/post/' + postId + '/lead/media/list' , {})
        .toPromise();
  }

  processBase64(content: string): string {
    let res = '';
    let imgURL = '';
    if (content) {
      // res = content;
      // res = content.replace(/src="data:/g,'[src]="data:');

      const rExpr = /([\s\S]*)(<img )([\s\S]*)(src="data:)([\s\S]*)(base64,)([\s\S]*?)"([\s\S]*)/;
      const matchRes = content.match(rExpr);
      if (matchRes) {
        imgURL = 'data:' + matchRes[5] + matchRes[6] + (matchRes[7].replace(/ /g, '+'));
        res += matchRes[1] + matchRes[2] + matchRes[3] + 'src="' + imgURL + '"';
        res += matchRes[8];
      } else {
        res = content;
      }
    }
    return res;
  }

  replaceTag(tag: string): string {
    const updated = this.globalQueryString.replace(/&tag=.*&/, '&tag=' + encodeURIComponent(tag) + '&');
    return updated;
  }

  flagPost(postId: string, reason: string, explanation: string): Promise<NoteFlag | ArrayBuffer> {
    const url: string = '/api/post/' + postId + '/flag';
    const params = new HttpParams()
      .set('reason', reason)
      .set('comments', explanation);

    const options: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post(url, params, options)
        .toPromise();
  }

  flagComment (postId: string, commentId: string, reason: string, explanation: string): Promise<NoteCommentFlag | ArrayBuffer> {
    const url: string = '/api/post/' + postId + '/comment/' + commentId + '/flag';
    const params = new HttpParams()
      .set('reason', reason)
      .set('comments', explanation);

    const options: any = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post(url, params, options)
        .toPromise();
  }


  private createPostObject(options, noCache?: boolean) {
    const newPost = new Note(options);
    const self = this;

    if (newPost.channelId) {
      this.streamService.getStreamInfoObservable(newPost.channelId, noCache).subscribe(channelInfo => {
        newPost.streamInfo = channelInfo as Stream;
        debug('createPostObject got channel');
        self.noteStreamInfo$.next(channelInfo);
      }, err => {
        console.error('createPostObject err', err);
        console.error('channel ' + newPost.channelId + ' not found!');
        newPost.streamInfo = {
          name: newPost.channelId
        } as Stream;
      });
    }
    return newPost;
  }

  private pullFileName(item: any): string {
    let name = null;
    if (!item.fileName) {
      const keyParts = item.key.split('/');
      name = keyParts[2];
    }
    return name;
  }

  private addToCategories(tags: string[]) {
    if (tags && tags.length > 0) {
      tags.forEach( t => {
        if (this.postCategories.indexOf(t) < 0) {
          this.postCategories.push(t);
          this.postCategories.sort();
        }
      });
    }
  }

  private formatComments(flatArray) {
    if (flatArray) {
      flatArray.forEach(f=> {
        f.replies = flatArray.filter(g=>g.parentId==f.id);
      });
      return flatArray.filter(f=>f.parentId==null);
    } else {
      return null;
    }
  }

  urlifyName(name: string) {
    return NoteService.urlifyName(name);
  }

  inviteDebater(noteId: string, debaterId: any, type: string): Promise<Note> {
    return this.http.put<any>(`/api/post/${noteId}/debater/invite/${type}/${debaterId}`, {}).toPromise();
  }

  removeDebater(noteId: string, debaterId: any): Promise<Note> {
      return this.http.delete<any>(`/api/post/${noteId}/debater/remove/${debaterId}`, {}).toPromise();
  }

  setDelayPublish(noteId: string | number, timestamp: string): Promise<Note> {
    return this.http.put<any>(`/api/post/${noteId}/delayed-publish/${timestamp}`, {}).toPromise();
  }

  public setNoteFreeStatus(noteId: number, noteFree: boolean): Promise<Note> {
    const price = noteFree ? 0 : 1;
    return this.http.put<any>(`/api/post/${noteId}/price/${price}`, {}).toPromise();
  }

  public setPinNoteToTheTop(noteId: number, status: number): Promise<Note> {
    return this.http.put<any>(`/api/post/${noteId}/pinned/${status}`, {}).toPromise();
  }

  public validateCustomUrlDuplication(url: string): Promise<any> {
    return this.http.get(`/api/search/short-url/post/${url}`).toPromise();
  }

  public saveCustomUrl(noteId: number | string, url: string): Promise<any> {
    return this.http.post(`/api/post/${noteId}/short-url/${url}`, {}).toPromise();
  }

  public removeCustomUrl(streamId: number | string): Promise<any> {
    return this.http.delete(`/api/post/${streamId}/short-url`, {}).toPromise();
  }

  public getNoteIdFromCustomUrl(url: string): Promise<any> {
    return this.http.get(`/api/search/short-url/post/${url}`).toPromise();
  }

  public getNotesTrash(userId: string | number, size: number, offset: number): Promise<any> {
    const params: HttpParams = new HttpParams()
      .set( 'fromOffset', offset)
      .set( 'state', 'TRASH')
      .set( 'size', size || '' );

    return this.http.get(`/api/user/${userId}/posts/list`, {params}).toPromise();
  }

  public restoreNote(noteId: number): Promise<any> {
    return this.http.post(`/api/post/${noteId}/state/HIDDEN`, {}).toPromise();
  }

  public deleteNoteForever(noteId: number): Promise<any> {
    return this.http.delete(`/api/post/${noteId}`, {}).toPromise();
  }

  static urlifyName(name: string): string {
    let str = name.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to   = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length ; i < l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return str;
  }

  static getBaseUrl(noteId: string, name: string): string {
    return '/notes/' + noteId + '/' + name;
  }
  static getFullURL(noteId: string, name: string): string {
    return environment.urlBasePrefix + NoteService.getBaseUrl(noteId, name);
  }
}

export class PurchaseMicroService {
  noteService: NoteService;
  cacheService: CacheService;
  post: Note;

  constructor(noteService: NoteService, cacheService: CacheService, post: Note) {
    this.noteService = noteService;
    this.cacheService = cacheService;
    this.post = post;

    if (typeof transactApi !== 'undefined') {
      transactApi.setFrontendUrl(`${environment.transactBaseUrl}/purchase`);
    }
  }

  purchase() {
    const self = this;
    let completed = false;

    return new Promise<Note> (
      (resolve, reject) => {
        // Call authorize() which will load the popup,
        // passing in callback function (PurchasePopUpClosed)
        transactApi.authorize((popup, event) => {
          if (completed) {
            return;
          }

          if (event && event.data) {
            const validation_data = event.data;
            validation_data.action = 'getPurchasedContent';

            // var url = '/api/note/'+ post_id +'/purchase/validate';
            if (validation_data.t) {
              self.cacheService.setPostPurchaseToken(this.post.id, validation_data.t);
              completed = true;
              resolve(self.noteService.loadNotePremiumContent(self.post, validation_data.t));
            } else {
              reject('Purchase Failed');
            }
          } else {
            reject('Purchase Failed');
          }
        });
      });
  }

}
