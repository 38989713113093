import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ColumnSorterModule} from '../../shared/column-sorter/column-sorter.module';

import {ActivityTableComponent} from './activity-table.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faSync} from '@fortawesome/free-solid-svg-icons';

@NgModule({
    declarations: [
        ActivityTableComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ColumnSorterModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule
    ],
    exports: [ ActivityTableComponent ]
})

export class ActivityTableModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync);
  }
}
