import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faSync} from '@fortawesome/free-solid-svg-icons';

import {ConfirmationModalComponent} from './confirmation-modal.component';

@NgModule({
    declarations: [
        ConfirmationModalComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FontAwesomeModule
    ],
    exports: [ ConfirmationModalComponent ]
})

export class ConfirmationModalModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync);
  }
}
