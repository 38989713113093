import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SortByPipe} from './sortby.pipe';
import {ObjectToArrayPipe} from './object-to-array.pipe';
import {TimeAgoPipe} from './time-ago-pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import {MedialFilterPipe} from './media-file.pipe';
import {StreamsKindFilterPipe} from './streams-kind-filter.pipe';

@NgModule({
    declarations: [
        SortByPipe,
        ObjectToArrayPipe,
        TimeAgoPipe,
        SafeHtmlPipe,
        MedialFilterPipe,
        StreamsKindFilterPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SortByPipe,
        ObjectToArrayPipe,
        TimeAgoPipe,
        SafeHtmlPipe,
        MedialFilterPipe,
        StreamsKindFilterPipe
    ]
})

export class PipesModule {}
