<section class="add-to-home-screen__wrapper">
    <button
        class="add-to-home-screen__wrapper__button"
        type="button"
        (click)="a2hs.addToHomeScreen()">Install Notd on your device home screen</button>
    <p class="add-to-home-screen__wrapper__info">
        You can add the shortcut of the Notd website to your mobile home screen. 
    </p>
    <p class="add-to-home-screen__wrapper__close-popup">
        <a (click)="a2hs.closeA2hsPopup()">close popup</a>
    </p>
</section>