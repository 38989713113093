import * as Debug from 'debug';
import {isPlatformBrowser} from '@angular/common';
import {Injectable, Inject, PLATFORM_ID} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { CacheService } from '../shared/services/cache_service';

const debug = Debug('notd:ApiInterceptor');
let cacheService = null;
let auth = ''; // token gets stored in memory

export function setAuthToken(token: string, stayLoggedIn?: boolean) {
  auth = token;
  console.log('setAuthToken', token);

  try {
    sessionStorage.setItem('authorization', token);
    if (!cacheService.cache.get('successLogin') && cacheService) {
      // localStorage.setItem('successLogin', 'true');
      cacheService.setCache('successLogin', 'true');
    }

    sessionStorage.setItem('authorization', token);

    if (stayLoggedIn && cacheService) {
      // localStorage.setItem('authorization', token);
      cacheService.setCache('authorization', token);
    }
  } catch (e) {
    console.warn('Localstorage not available. User will be logged out on window close.');
  }
}

// Get auth token, called here and media component for HLS
export function getAuthToken(): string {
  if (!auth) {
    try {
      if (sessionStorage.getItem('authorization')) {
        // Restore the contents of the text field
        auth = sessionStorage.getItem('authorization');
      } else if (cacheService && cacheService.cache.get('authorization')) {
        // Restore the contents of the text field
        auth = cacheService.cache.get('authorization');
      }
    } catch (e) {
      console.error('Error getting AuthToken', e);
      return '';
    }
  }

  return auth;
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    debug('ApiInterceptor constructor', environment.apiServerBase);
    if (!cacheService) {
      cacheService = new CacheService(this.platformId);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.startsWith('/api/') && !req.url.startsWith(environment.apiServerBase)) {
      debug('NOT intercept', req.url);
      return next.handle(req);
    }
    debug('intercept', req.url);

    let serverReq = req.clone({
      url: this.apiUrl(req.url),
      withCredentials: false
    });

    let authToken: string = null;

    // node universal backend server side rendering wont have token or session storage
    if (isPlatformBrowser(this.platformId)) {
      authToken = getAuthToken();
    }

    if (authToken && authToken.length > 1) {
      // authToken is set, so add it to headers
      serverReq = serverReq.clone({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        setHeaders: { 'Notd-Auth': authToken },
      });
    }

    return next.handle(serverReq);
  }

  private apiUrl(url: string): string {
    debug('apiUrl src', url);
    if (url.startsWith('/api')) {
      url = environment.apiServerBase + url;
    }

    debug('apiUrl result', url);
    return url;
  }
}
