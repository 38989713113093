import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {StreamSubscribeComponent} from './stream-subscribe.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faSync} from '@fortawesome/free-solid-svg-icons';
import { NoDbClickModule } from '../../shared/directives/no-db-click.module';

@NgModule({
    declarations: [ StreamSubscribeComponent ],
    imports: [
        RouterModule,
        CommonModule,
        FontAwesomeModule,
        NoDbClickModule
    ],
    exports: [ StreamSubscribeComponent ]
})

export class StreamSubscribeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync);
  }
}
