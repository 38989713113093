export const tagNames = [
    { tag: 'art', color: '#2bc8fd', text: '#ffffff', level: 1 },
    { tag: 'business', color: '#2dfd8e', text: '#666666', level: 1 },
    { tag: 'comedy', color: '#feb52e', text: '#666666', level: 1 },
    { tag: 'cooking', color: '#89ff20', text: '#666666', level: 1 },
    { tag: 'entertainment', color: '#ea29fc', text: '#ffffff', level: 1 },
    { tag: 'men', color: '#ec26ff', text: '#ffffff', level: 1 },
    { tag: 'politics', color: '#8afd38', text: '#666666', level: 1 },
    { tag: 'sports', color: '#ff0037', text: '#ffffff', level: 1 },
    { tag: 'baseball', color: '#00ff8a', text: '#666666', level: 2 },
    { tag: 'basketball', color: '#ff0038', text: '#ffffff', level: 2 },
    { tag: 'football', color: '#bce8ff', text: '#666666', level: 2 },
    { tag: 'hockey', color: '#00c2ff', text: '#ffffff', level: 2 },
    { tag: 'soccer', color: '#b9ffd4', text: '#666666', level: 2 },
    { tag: 'tech', color: '#dedfff', text: '#666666', level: 1 },
    { tag: 'women', color: '#fff0de', text: '#ffffff', level: 1 }
];

/*
http://www.cssdrive.com/imagepalette/index.php

.color1 { color: #dedfff}
.color2 { color: #ffffff} // don't use
.color3 { color: #fff0de}
.color4 { color: #e9ffdf}
.color5 { color: #89ff20}
.color6 { color: #ec26ff}
.color7 { color: #ff0037}
.color8 { color: #00ff8a}
.color9 { color: #ec19ff}
.color10 { color: #ff0038}
.color11 { color: #00ff8b}
.color12 { color: #00c2ff}
.color13 { color: #ff0000}
.color14 { color: #7eff00}
.color15 { color: #b9ffd4}
.color16 { color: #bce8ff}
*/
