import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../pipes/pipes.module';

import {AddressLocComponent} from './address-loc/address-loc.component';
import {CountrySelectorComponent} from '../country-selector/country-selector.component';
import {StateSelectorModule} from '../state-selector/state-selector.module';
import {AddressComponent} from './address.component';
import {TrimTextDirective} from '../validators/trim-text.directive';
import {CityValidator} from '../validators/valid-city.directive';
import {RegexpDirective} from '../validators/regexp.directive';
import {NgValidPropertyDirective} from '../validators/property-parser.directive';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faCircleNotch, faSync} from '@fortawesome/free-solid-svg-icons';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations: [
        AddressComponent,
        AddressLocComponent,
        CountrySelectorComponent,
        TrimTextDirective,
        CityValidator,
        RegexpDirective,
        NgValidPropertyDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        StateSelectorModule,
        FontAwesomeModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
    ],
    exports: [ AddressComponent, TrimTextDirective, CityValidator, RegexpDirective, NgValidPropertyDirective]
})

export class AddressModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faSync, faCircleNotch);
  }
}
