<div *ngIf="mySubscriptions.length > 0;then subscription_content else nosubscription_content"></div>

<ng-template #subscription_content>
  <table class="notd__table">
    <thead>
      <tr>
        <th>
          <column-sorter [text]="'Start Date'" [list]="mySubscriptions" [field]="'created'"></column-sorter>
        </th>
        <th>
          <column-sorter [text]="'Expires'" [list]="mySubscriptions" [field]="'expires'"></column-sorter>
        </th>
        <th>
          <column-sorter [text]="'Stream'" [list]="mySubscriptions" [field]="'_streamName'"></column-sorter>
        </th>
        <th class="row-costs">
          <column-sorter [text]="'Cost'" [list]="mySubscriptions" [field]="'price'"></column-sorter>
        </th>
        <th class="row-extend label">
          Extend
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let subs of mySubscriptions">
        <td data-cy="sub-start-date">
          {{subs.created | date: 'MMM dd, yyyy'}}
        </td>
        <td data-cy="sub-exp-date" [ngClass]="{'expired': subs._expired}">
          {{subs.expires | date: 'MMM dd, yyyy HH:mm'}}
        </td>
        <td>
          <div *ngIf="!subs._streamName">
            <fa-icon
              *ngIf="!subs.error"
              [icon]="['fas', 'sync']"></fa-icon>
            <div
              *ngIf="subs.error === 'DOES_NOT_EXIST'">Stream doesn't exist</div>
          </div>
          <div *ngIf="subs._streamName">
            <a 
              data-cy="sub-stream-link"
              class="stream-link"
              [routerLink]="subs._owned?['/user', subs.userId, 'streams', subs.channelId, subs._streamName]:['/streams', subs.channelId, 'view', subs._streamName]">
              {{subs._streamName}} ({{subs._periodStr}})
            </a>
          </div>
        </td>
        <td>
          <span data-cy="sub-cost">{{subs.price/100 | currency:'USD':'symbol'}}</span>
          <span *ngIf="subs._expired && subs._currentPrice !== subs.price"> {{subs._currentPrice/100 | currency:'USD':'symbol'}}</span>
        </td>
        <td data-cy="sub-extend-btn">
          <stream-subscribe
            *ngIf="!subs.error"
            (statusUpdated)="statusUpdatedEvent($event)"
            (subscribeToStreamEvent)="subscribeToStream($event)"
            [streamId]="subs.channelId"
            [periodId]="subs._periodStr"
            [subscribeLabel] ="'Extend'"
            i18n-streamSubscribeTitle="@@STREAM-SUBSCRIBE-001" 
            streamSubscribeTitle = "Thank you!" 
            i18n-streamSubscribeContent="@@STREAM-SUBSCRIBE-002" 
            streamSubscribeContent="Thank you for your subscription to %s1, your current subscription expires on %s2">
          </stream-subscribe>
          <button
            *ngIf="subs.error === 'DOES_NOT_EXIST'"
            class="notd__button--v2 button--black button--disabled" disabled>Disabled</button>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
<ng-template #nosubscription_content>
  <section *ngIf="loadingSubscriptions" class="loading">
    <span class="loading__spinner"></span>
    <p class="loading__label">Loading your subscriptions...</p>
  </section>
  <div class="col-12 text-center" *ngIf="!loadingSubscriptions" i18n="@@STREAM-SUBSCRIBE-003">
    You don't have any subscriptions yet.
  </div>
</ng-template>

<ng-template #subscribeToStreamModal let-c="close" let-d="dismiss">
  <a (click)="d('Cross click')" data-cy="close-modal" class="subscribe-to-stream-modal__close-btn" title="Close Modal">
      <svg enable-background="new 0 0 413.348 413.348" height="512" viewBox="0 0 413.348 413.348" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"/></svg>
  </a>
  <section
      *ngIf="loadingRedirection.status && loadingRedirection.planType === 'stream'"
      [ngClass]="{'error': loadingRedirection.type === 'error'}"
      class="subscribe-to-stream-modal__content__progress">
      <div class="subscribe-to-stream-modal__content__progress__content" *ngIf="loadingRedirection.type !== 'error'">
          <span class="subscribe-to-stream-modal__content__progress__content__spinner"></span>
          <p class="subscribe-to-stream-modal__content__progress__content__label">Redirecting to the transact payment website...</p>
      </div>
      <div class="subscribe-to-stream-modal__content__progress__content" *ngIf="loadingRedirection.type === 'error'">
          <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><linearGradient id="lg1"><stop offset="0" stop-color="#ffc2cc"/><stop offset="1" stop-color="#fff2f4"/></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="301" y2="91"/><linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256" x2="256" xlink:href="#lg1" y1="421" y2="331"/><g><g><path d="m355.826 512h-199.651c-3.979 0-7.794-1.58-10.606-4.394l-141.175-141.174c-2.814-2.813-4.394-6.628-4.394-10.606v-199.652c0-3.978 1.58-7.793 4.394-10.606l141.174-141.174c2.813-2.814 6.628-4.394 10.607-4.394h199.651c3.979 0 7.794 1.581 10.606 4.394l141.174 141.174c2.813 2.813 4.394 6.628 4.394 10.606v199.651c0 3.978-1.58 7.793-4.394 10.606l-141.173 141.175c-2.813 2.813-6.628 4.394-10.607 4.394z" fill="url(#SVGID_1_)"/></g><g id="Error"><g><path d="m256 301c-24.813 0-45-20.187-45-45v-120c0-24.813 20.187-45 45-45s45 20.187 45 45v120c0 24.813-20.187 45-45 45z" fill="url(#SVGID_2_)"/></g><g><path d="m256 421c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="url(#SVGID_3_)"/></g></g></g></svg>
          <p class="subscribe-to-stream-modal__content__progress__content__label">An error occurred while redirecting to the payment page.</p>
      </div>
  </section>
  <section
    *ngIf="loadingRedirection.type === 'error'"
    class="subscribe-to-stream-modal">
    <div class="subscribe-to-stream-modal__content__buttons">
      <button
          type="button"
          class="notd__button--v2 button--black" (click)="d('Cross click');">Close</button>
    </div>
  </section>
</ng-template>
