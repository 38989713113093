import * as Debug from 'debug';
import {Injectable} from '@angular/core';
import {tagNames} from '../types/tagNames';

const debug = Debug('notd:shared:TagService');

@Injectable()
export class TagService {

    getTags(): any[] {
        return tagNames.map(a => Object.assign({}, a));
    }

    getTagByProperty(tag: string, prop: string): any {

        const respArray: any = this.getTags().filter((t: any) => t.tag === tag);

        if (respArray.length === 1) {
            return respArray[0][prop];
        }

        return;
    }

    getTagColor(tag: string): any {

        return this.getTagByProperty(tag, 'color');

    }

    getTextColor(tag: string): any {

        return this.getTagByProperty(tag, 'text');

    }
}
