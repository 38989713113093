import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function NonNumericValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (typeof value === 'string' && value.length > 0) {
      const nonNumericPattern = /^[^\d]*$/;
      const valid = nonNumericPattern.test(value);

      return valid ? null : { nonNumeric: true };
    }
    return null;
  };
}