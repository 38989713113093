<form #addressForm novalidate  (keydown.enter)="$event.preventDefault()">
  <address-loc
    *ngIf="allowLocator"
    [autocompleteDisplay]="autocompleteDisplay"
    [defaultLocation]="defaultLocation"
    [simple]="simple"
    (locationUpdatedEvent)="locationUpdated($event)"></address-loc>

  <div class="form-group row notd-manual-location" *ngIf="showManualFields">
    <div class="col-12" *ngIf = "isEnabledGeoLocator && allowLocator">Or set your stream default location</div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
      <label for="streamCountryId">Country</label>
      <notd-country-selector id="streamCountryId" [required] = "countryRequired" [defaultCountryCode] = "countryCodeSelected" (countryEmitter) = "countryEvent($event)"></notd-country-selector>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
      <label for="streamCityId">City</label>
      <input *ngIf="address" id="streamCityId" maxlength="100" type="text" class="form-control" placeholder="City" name="streamCity" [(ngModel)]="address.city" trimText regExpValidator="^[\u00C0-\u0290a-z -]*$"/>
      <div class="error" *ngIf="addressForm.controls?.streamCity?.errors?.regExpValidator">
          <div class="notd-input-error">
            <span *ngIf="addressForm.controls?.streamCity?.errors?.regExpValidator.format">Wrong format. Only letters and spaces are allowed.</span>
        </div>
      </div>
    </div>
  </div>
</form>

