import { Component } from '@angular/core';
import { A2hsService } from '../services/a2hs.service';

@Component({
  selector: 'app-a2hs-prompt-button',
  templateUrl: './a2hs-prompt-button.component.html',
  styleUrls: ['./a2hs-prompt-button.component.scss']
})
export class A2hsBrowserPromptComponent {
  constructor(public a2hs: A2hsService) { }
}
