/* eslint-disable @typescript-eslint/naming-convention */

import { ITransactJWT } from './transact_token';

export enum PaymentType {
  NONE = 0,
  SINGLE_POST = 1,
  CHANNEL_SUBSCRIPTION = 2,
  POST_PLAYLIST = 3,
}

export enum PaymentState {
  // constant members
  NONE = 0,
  STARTED = 1, // token passed to transact but not paid for
  DONE = 2, // PAID
  CANCELED = 3,
  REFUNDED = 4,
}

export enum PaymentProcessor {
  // constant members
  NONE = 0,
  TRANSACT_IO = 1,
  MANUAL = 1,
}

export const SELLER_ID_SELF = 10; // special ID

export class Payment {

  public id: string; // PostId_buyerID_timeStamp
  public processor: PaymentProcessor = PaymentProcessor.TRANSACT_IO;
  public state: PaymentState;
  public payType: PaymentType;
  public postId: string; // single post
  public posts: string[]; // array of posts with POST_PLAYLIST
  public channelId: string;
  public teamId: string;
  public subPlanId: string; // if  a subscription plan save here, otherwise null
  public playlistId: string; // if playlist save here otherwise null
  public subId: string; // subscription ID
  public buyerId: number; // user ID of buyer
  public pIdBuyerIdx: string;  // column to be indexed combine postID and buyerId. strange name due to 13 char limit
  public sellerId: number; // ID of user selling content
  public publisherId: number; // publisherId on transact, should be constant but saving just incase
  public parentPayId: string; // if this is a component that is nested into other payment list the parent;
  public commission_cc: number;  // how much we earn if this is a single purchase
  public usrEarns_cc: number;  // how much we earn if this is a single purchase
  public price: number;  // price the user paid in cents
  public token: ITransactJWT; // token saved
  public currency = 'PROD';
  public accounted = 0; // set to 1 when include in a PaymentSummary
  public created: number; // timestamp
  public updated: number;
}
