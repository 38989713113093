import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';

import * as Debug from 'debug';
// types
import {Stream} from '../types/stream';
import {Note} from '../types/note';
import {User} from '../types/user';
import * as Sentry from '@sentry/browser';

import * as Cache from 'in-memoriam';

const debug = Debug('notd:shared:cache_service');

@Injectable()
export class CacheService {
  public cache: any;
  private cacheKeys = {
    channel: 'channel:',
    myStreams: 'myStreams',
    stream: 'stream',
    streamPlans: 'streamPlans',
    streamsFollowed: 'profile:following',
    userStreams: 'userStreams',
    userProfile: 'uProfile',
    userProfileByUsername: 'userNameProfile',
    purchaseToken: 'purchased:',
    post: 'note:',
    postLead: 'postLead:',
    countryList: 'countryList',
    userCachesList: 'userCachesList',
    streamPurchaseToken: 'streamPurchaseToken',
    team: 'team',
    teamPlans: 'teamPlans',
    countryStates: 'countryStates',
    userLocation: 'userLocation',
    noteTags: 'defaultNoteTags',
    noteStream: 'defaultNoteStream',
    followedStreams: 'followedStreams',
    userTeamStreams: 'userTeamStreams',
    notifications: 'notifications'
  };

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
  @Inject(PLATFORM_ID) private platformId: Object) {

    const capacity = 1000; // Least recently accessed/oldest items are removed when capacity is exceeded
    const ttl = 100000; // TTL is in milliseconds. Items not accessed by end of TTL are evicted.
    this.cache = new Cache(capacity, ttl);
  }

  clearUserCache() {
    const userKeys = Object.keys(this.cache.get(this.cacheKeys.userCachesList) || {});
    for (const key of userKeys) {
      this.cache.remove(key);
    }
    this.setCache(this.cacheKeys.userCachesList, {});
  }

  clearCachedPost(noteId: string) {
    this.cache.remove(this.cacheKeys.post + noteId);
  }

  public setCache(key: string, value: any) {

    if (isPlatformServer(this.platformId)) {
      // not supported on sever side
      return;
    }

    try {
      this.cache.set(key, value);
    } catch (ex) {
      Sentry.captureException(ex, {
        tags: {
          section: 'CacheService',
          key
       },
      });
      this.clearCache();
    }
  }
  setUserProfileCache(profileData: User) {
    if (profileData && profileData.id) {
      this.setCache(`uProfile:${profileData.id}`, profileData);
    }
  }

  setUserProfileByUsernameCache(profileData: User) {
    if (profileData && profileData.id) {
      this.setCache(`${this.cacheKeys.userProfileByUsername}:${profileData.id}`, profileData);
    }
  }

  getUserProfileCache(userId: any) {
    return this.cache.get(`${this.cacheKeys.userProfile}:${userId}`);
  }

  getUserProfileByUsernameCache(username: string) {
    return this.cache.get(`${this.cacheKeys.userProfileByUsername}:${username}`);
  }

  removeUserProfileCache(profileData: User) {
    this.cache.remove(`uProfile:${profileData.id}`);
  }

  removeUserProfileByUsernameCache(profileData: User) {
    this.cache.remove(`userNameProfile:${profileData.id}`);
  }

  getChannelInfo(channelId: string): Stream {
    console.log('get channel info', this.cache.get(this.cacheKeys.channel + channelId));
    return this.cache.get(this.cacheKeys.channel + channelId);
  }

  setChannelInfo(channelId: string, channelData: Stream) {
    this.setCache(this.cacheKeys.channel + channelId, this.cloneObj(channelData));
  }

  getStreamsFollowed() {
    return this.cache.get(this.cacheKeys.streamsFollowed);
  }

  setStreamsFollowed(channelsFollowedData) {
    console.log('setStreamsFollowed', channelsFollowedData);
    this.setCache(this.cacheKeys.streamsFollowed, this.cloneObj(channelsFollowedData));
  }

  getPostPurchaseToken(postId: string): string {
    return this.cache.get(this.cacheKeys.purchaseToken + postId);
  }
  setPostPurchaseToken(postId: string, token: string) {
    const key  = this.cacheKeys.purchaseToken + postId;
    const userKeys = this.cache.get(this.cacheKeys.userCachesList) || {};
    userKeys[key] = true;
    this.setCache(this.cacheKeys.userCachesList, userKeys);
    this.setCache(key, token);
  }

  getPostInfo(postId: number | string): Note {
    debug('getNoteInfo: ' + postId, this.cache.get(this.cacheKeys.post + postId));
    return this.cache.get(`${this.cacheKeys.post}:${postId}`);
  }

  setPostInfo(postId, postData: Note) {
    debug('setPostInfo: ' + postId, postData);
    this.setCache(`${this.cacheKeys.post}:${postId}`, postData);
  }

  getPostLead(postId) {
    return this.cache.get(this.cacheKeys.postLead + postId);
  }

  setPostLead(postId, postData) {
    if (postId && postData) {
      this.setCache(this.cacheKeys.postLead + postId, this.cloneObj(postData));
    }
  }


  getStreamPurchaseToken(streamId: string): string {
    return this.cache.get(this.cacheKeys.streamPurchaseToken + streamId);
  }

  setStreamPurchaseToken(streamId: string, token: string) {
    const key  = this.cacheKeys.streamPurchaseToken + streamId;
    this.setCache(key, token);
  }

  getCountryList() {
    return this.cache.get(this.cacheKeys.countryList);
  }

  setCountryList(countryList) {
    this.setCache(this.cacheKeys.countryList, this.cloneObj(countryList));
  }

  setUserLocation(location) {
      this.setCache(this.cacheKeys.userLocation, location);
  }

  getUserStreams() {
    return this.cache.get(this.cacheKeys.userStreams);
  }

  setUserStreams(userStreams) {
    this.setCache(this.cacheKeys.userStreams, this.cloneObj(userStreams));
  }

  clearUserStreams() {
    this.cache.remove(this.cacheKeys.userStreams);
  }


  getStreamInfo(streamId: string): Stream {
    return this.cache.get(`${this.cacheKeys.stream}:${streamId}`);
  }

  setStreamInfo(stream: Stream) {
    if (this.getStreamInfo(stream.id)) {
      this.cache.remove(`${this.cacheKeys.stream}:${stream.id}`);
    }
    this.setCache(`${this.cacheKeys.stream}:${stream.id}`, stream);
  }

  getFollowedStreams(): Stream {
    return this.cache.get(this.cacheKeys.followedStreams);
  }

  setFollowedStreams(streams: Stream[] | any) {
    this.setCache(this.cacheKeys.followedStreams, streams);
  }

  getSubscriptionPlans(streamId: string) {
    const plans: any = this.cache.get(this.cacheKeys.streamPlans);

    if (!plans) {
      return;
    }

    if (!plans[streamId]) {
      return;
    }

    return plans[streamId];
  }

  setSubscriptionPlans(streamId: string, plans: [any]) {
    let cachedPlans = this.cache.get(this.cacheKeys.streamPlans);

    if (!cachedPlans) {
      cachedPlans = {};
    }

    if (!cachedPlans[streamId]) {
      cachedPlans[streamId] = [];
    }

    cachedPlans[streamId] = this.cloneObj(plans);

    this.setCache(this.cacheKeys.streamPlans, cachedPlans);
  }

  getMyStreams() {
    return this.cache.get(this.cacheKeys.myStreams);
  }

  setMyStreams(myStreams) {
    this.setCache(this.cacheKeys.myStreams, this.cloneObj(myStreams));
  }

  clearMyStreams() {
    this.cache.remove(this.cacheKeys.myStreams);
  }


  getTeam() {
    return this.cache.get(this.cacheKeys.team);
  }

  setTeam(team) {
    this.setCache(this.cacheKeys.team, this.cloneObj(team));
  }

  clearTeam() {
    this.cache.remove(this.cacheKeys.team);
  }

  getTeamSubscriptionPlans(teamId: string) {
    const plans: any = this.cache.get(this.cacheKeys.teamPlans);

    if (!plans) {
      return;
    }

    if (!plans[teamId]) {
      return;
    }

    return plans[teamId];
  }

  setTeamSubscriptionPlans(teamId: string, plans: [any]) {
    let cachedPlans = this.cache.get(this.cacheKeys.teamPlans);

    if (!cachedPlans) {
      cachedPlans = {};
    }

    if (!cachedPlans[teamId]) {
      cachedPlans[teamId] = [];
    }

    cachedPlans[teamId] = this.cloneObj(plans);

    this.setCache(this.cacheKeys.teamPlans, cachedPlans);
  }

  getCountryStates(countryId: string) {
    const states: any = this.cache.get(this.cacheKeys.countryStates);

    if (!states) {
      return;
    }

    if (!states[countryId]) {
      return;
    }

    return states[countryId];
  }

  setCountryStates(countryId: string, states: any) {
    let cachedPlans = this.cache.get(this.cacheKeys.countryStates);

    if (!cachedPlans) {
      cachedPlans = {};
    }

    if (!cachedPlans[countryId]) {
      cachedPlans[countryId] = [];
    }

    cachedPlans[countryId] = this.cloneObj(states);

    this.setCache(this.cacheKeys.countryStates, cachedPlans);
  }

  setDefaultNoteStream(streamId: any) {
    this.cache.remove(this.cacheKeys.noteStream);
    this.setCache(this.cacheKeys.noteStream, streamId);
  }

  getDefaultNoteStream() {
    return this.cache.get(this.cacheKeys.noteStream);
  }

  setDefaultNoteTags(tagsObject: any) {
    this.cache.remove(this.cacheKeys.noteTags);
    this.setCache(this.cacheKeys.noteTags, tagsObject);
  }

  getNoteTags() {
    return this.cache.get(this.cacheKeys.noteTags);
  }

  getUserTeamStreams() {
    return this.cache.get(this.cacheKeys.userTeamStreams);
  }

  setUserTeamStreams(cachedData: {users: User[]; channels: Stream[]}) {
    this.setCache(this.cacheKeys.userTeamStreams, cachedData);
  }

  getNotifications() {
    return this.cache.get(this.cacheKeys.notifications);
  }

  public setNotifications(notifications) {
    this.clearNotifications();
    this.setCache(this.cacheKeys.notifications, notifications);
  }

  public clearNotifications(): void {
    this.cache.remove(this.cacheKeys.notifications);
  }

  clearCache() {
    Object.keys(this.cacheKeys).forEach(k => {
      this.cache.remove(k);
    });
  }

  private cloneObj(object) {
    return JSON.parse(JSON.stringify(object));
  }
}
