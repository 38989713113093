import {UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {Directive, Input} from '@angular/core';


@Directive({
  selector: '[regExpValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RegexpDirective,
      multi: true
    }
  ]
})

export class RegexpDirective implements Validator {

  @Input() regExpValidator;

  validator: ValidatorFn;


  constructor() {

    this.validator = this.regexpValidatorFn();

  }

  validate(c: UntypedFormControl) {
    const resp = this.validator(c);
    return resp;
  }

  regexpValidatorFn(): ValidatorFn {

    return (c: UntypedFormControl) => {

      let modelValue = c.value || '';

      modelValue = modelValue.trim();

      const re = new RegExp(this.regExpValidator, 'gi');

      if (modelValue !== '' && !modelValue.match(re)) {

        return {
          regExpValidator: {
            format: true
          }
        };

      }


      return null; // IS VALID

    };

  }
}

